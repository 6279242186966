import { gql } from '@apollo/client';
import { useContext } from 'react';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import type { CartPriceTotals } from '../types';
import useCachedQuery from '../../core/hooks/useCachedQuery';
import useCartItems from '../hooks/useCartItems';
import { CartContext } from '../../core/cartContext';
import type { PriceResult } from '../../pricing/type';
import handlePriceError from '../../pricing/util/handlePriceError';

export const localCartPriceSummaryQuery = gql`
    query customerPriceSummary(
        $store: ID!
        $cartType: CartType!
        $items: [CartItemInput!]!
    ) {
        customerPriceSummary(
            store: $store
            cartType: $cartType
            items: $items
        ) {
            result {
                store
                retailPrice
                generalDealerPrice
                finalPrice
                dealerDiscountPercentage
            }
            errors {
                code
                sku
            }
        }
    }
`;

const useLocalCartPriceSummary = (): CartPriceTotals => {
    const { store } = useStoreAndLanguageParams();
    const cartItems = useCartItems();

    const { cartType } = useContext(CartContext);

    const { data, error } = useCachedQuery<{
        customerPriceSummary: PriceResult<CartPriceTotals>;
    }>(localCartPriceSummaryQuery, {
        variables: {
            store,
            items: cartItems.map(item => ({
                sku: item.sku,
                quantity: item.quantity,
            })),
            cartType: 'user',
        },
        skip: cartItems.length === 0 || cartType !== 'local',
    });

    if (error) {
        throw error;
    }

    handlePriceError(data?.customerPriceSummary);

    return (
        data?.customerPriceSummary.result || {
            retailPrice: 0,
        }
    );
};

export default useLocalCartPriceSummary;
