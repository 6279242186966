import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading, Text, VStack } from '@library';
import Button from '../../../core/components/Button';
import type { PostComponent } from '../../../content/types/types';
import CurrentStoreLink from '../../../core/components/router/CurrentStoreLink';
import StoryblokResponsiveImage from '../../../content/components/PageComponents/StoryblokResponsiveImage';
import PostSubInfo from '../PostsPage/PostItem/PostSubInfo';
import styles from './NewsFeedTile.module.scss';

const NewsFeedTile = ({
    content,
    slug,
    date,
    hidePreviewText,
}: {
    content: PostComponent;
    date: string;
    slug: string;
    hidePreviewText: 'true' | '';
}) => {
    const image = content.preview_image?.filename
        ? content.preview_image
        : content.featured_image;
    return (
        <div className={styles.container}>
            <CurrentStoreLink className={styles.imageLink} to={`news/${slug}`}>
                <StoryblokResponsiveImage
                    loading="lazy"
                    className={styles.image}
                    image={image}
                    srcSets={[
                        {
                            width: 450,
                            height: 330,
                            screenWidth: '450w',
                        },
                        {
                            width: 600,
                            height: 330,
                            screenWidth: '600w',
                        },
                        {
                            width: 900,
                            height: 660,
                            screenWidth: '900w',
                        },
                        {
                            width: 1200,
                            height: 660,
                            screenWidth: '1200w',
                        },
                    ]}
                    sizes="(max-width: 900px) 100vw,
                           (max-width: 1300px) 50vw,
                           (max-width: 1700px) 33vw,
                           25vw"
                    onlyFocusCrop
                />
            </CurrentStoreLink>

            <div className={styles.infoContainer}>
                <VStack gap="micro">
                    <PostSubInfo
                        date={date}
                        categoryUUID={content.category?.uuid}
                        categoryName={content.category?.content?.name}
                    />
                    <CurrentStoreLink
                        className={styles.title}
                        to={`news/${slug}`}
                    >
                        <Heading h3 hyphenated>
                            {content.title}
                        </Heading>
                    </CurrentStoreLink>
                </VStack>

                {hidePreviewText !== 'true' && (
                    <div className={styles.description}>
                        <Text primary light>
                            {content.summary}
                        </Text>
                    </div>
                )}

                <CurrentStoreLink className={styles.link} to={`news/${slug}`}>
                    <Button primary>
                        {content.preview_button_label ? (
                            content.preview_button_label
                        ) : (
                            <FormattedMessage id="learn more" />
                        )}
                    </Button>
                </CurrentStoreLink>
            </div>
        </div>
    );
};

export default memo(NewsFeedTile);
