import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Text } from '@library';
import SidePane from '../SidePane';
import { AccountMenuLinks } from '../../../user/components/AccountDropdownMenu';
import useLogout from '../../hooks/useLogout';
import RestrictedContent from '../router/RestrictedContent';
import useStoreInformation from '../../../content/queries/useStoreInformation';
import SidePaneMenuItem from './SidePaneMenuItem';
import styles from './SidePaneAccountMenu.module.scss';
import SidePaneMenuLink from './SidePaneMenuLink';
import SidePanePreorderItems from './SidePanePreorderItems';

const SidePaneAccountMenuContent = memo(
    ({ onClose }: { onClose: () => void }) => {
        const logout = useLogout();
        const { data: storeInformation } = useStoreInformation();

        const onLogoutPress = async () => {
            onClose();
            logout();
        };

        return (
            <div className={styles.content}>
                {AccountMenuLinks.map(link => (
                    <SidePaneMenuLink
                        key={link.to}
                        to={link.to}
                        onClick={onClose}
                        messageId={link.labelId}
                        medium={link.medium}
                    />
                ))}

                <SidePanePreorderItems onClose={onClose} />

                <RestrictedContent featureFlag="rma">
                    <SidePaneMenuLink to={'service'} onClick={onClose}>
                        <FormattedMessage id="support" />
                    </SidePaneMenuLink>
                </RestrictedContent>
                {storeInformation && (
                    <SidePaneMenuLink
                        to={storeInformation.info_link}
                        onClick={onClose}
                    >
                        {storeInformation.info_label}
                    </SidePaneMenuLink>
                )}
                <SidePaneMenuItem
                    onClick={async () => {
                        await onLogoutPress();
                    }}
                    medium
                    messageId="sign out"
                />
            </div>
        );
    }
);

const SidePaneAccountMenu = ({ onClose }: { onClose: () => void }) => {
    return (
        <SidePane
            side="right"
            onClose={onClose}
            header={
                <Text secondary bold>
                    <FormattedMessage id="your account" />
                </Text>
            }
        >
            <SidePaneAccountMenuContent onClose={onClose} />
        </SidePane>
    );
};

export default memo(SidePaneAccountMenu);
