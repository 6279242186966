import { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, HStack, ProfileIcon, Text } from '@library';
import UserMenuModals from '../../../../../user/components/UserMenu/UserMenuModals';
import styles from './LoginMenu.module.scss';

const LoginMenu = memo(() => {
    const [showModals, setShowModals] = useState(false);
    const intl = useIntl();

    return (
        <>
            <Button
                white
                text
                inline
                theme={styles.theme}
                onClick={() => setShowModals(true)}
            >
                <HStack gap="mini" align="center">
                    <ProfileIcon size={20} />
                    <Text
                        hoverBold
                        primary
                        data-text={intl.formatMessage({ id: 'sign in' })}
                    >
                        {intl.formatMessage({ id: 'sign in' })}
                    </Text>
                </HStack>
            </Button>
            {showModals && (
                <UserMenuModals
                    onClose={() => {
                        setShowModals(false);
                    }}
                />
            )}
        </>
    );
});

export default LoginMenu;
