import { gql } from '@apollo/client';
import { useContext, useMemo } from 'react';
import { CartContext } from '../../core/cartContext';
import useCurrentStore from '../../core/hooks/useCurrentStore';
import useLoginState from '../../core/hooks/useLoginState';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';
import type { PriceResult } from '../../pricing/type';
import handlePriceError from '../../pricing/util/handlePriceError';

export const preorderCartBrandBreakdownQuery = gql`
    query preorderCartBrandBreakdown($store: ID!, $cartId: Int) {
        preorderCartBrandBreakdown(store: $store, cartId: $cartId) {
            brandName
            generalDealerPrice
        }
    }
`;

export const purchaseBreakdownQuery = gql`
    query purchaseBreakdown(
        $language: Language!
        $store: ID!
        $cartId: Int!
        $semiFinalPriceForCartBrandTotals: Boolean!
    ) {
        checkout(language: $language, store: $store, cartId: $cartId) {
            checkoutPriceSummary {
                result {
                    purchaseBreakdown {
                        type
                        items {
                            id
                            totalCartGeneralDealerPrice
                                @skip(if: $semiFinalPriceForCartBrandTotals)
                            totalSeasonGeneralDealerPrice
                                @skip(if: $semiFinalPriceForCartBrandTotals)
                            totalCartSemiFinalPrice
                                @include(if: $semiFinalPriceForCartBrandTotals)
                            totalSeasonSemiFinalPrice
                                @include(if: $semiFinalPriceForCartBrandTotals)
                        }
                    }
                }
                errors {
                    code
                    sku
                }
            }
        }
    }
`;

export interface PurchaseBreakdownItem {
    id: string;
    totalCartGeneralDealerPrice?: string;
    totalSeasonGeneralDealerPrice?: string;
    totalCartSemiFinalPrice?: string;
    totalSeasonSemiFinalPrice?: string;
}

interface PurchaseBreakdown {
    type: 'brand';
    items: PurchaseBreakdownItem[];
}

export type CartBrandBreakdown = Map<
    string,
    { cartTotal: number; seasonTotal: number }
>;

const useCartBrandTotal = (skip: boolean) => {
    const isLoggedIn = useLoginState();
    const { cart } = useContext(CartContext);
    const store = useCurrentStore();
    const { language } = useStoreAndLanguageParams();
    const { semiFinalPriceForCartBrandTotals } = store;

    const { data, loading, error } = usePolledQuery<{
        checkout: {
            checkoutPriceSummary: PriceResult<{
                purchaseBreakdown: PurchaseBreakdown;
            }>;
        };
    }>(purchaseBreakdownQuery, {
        variables: {
            store: store.id,
            language,
            cartId: cart.id,
            semiFinalPriceForCartBrandTotals,
        },
        skip: !isLoggedIn || !cart.id || skip,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        pollInterval: 180_000,
    });

    if (error) {
        throw error;
    }

    handlePriceError(data?.checkout.checkoutPriceSummary);

    const breakdownByBrandMap = useMemo(() => {
        const map = new Map<
            string,
            { cartTotal: number; seasonTotal: number }
        >();

        if (!data || !data.checkout.checkoutPriceSummary.result) {
            return map;
        }

        const { purchaseBreakdown } = data.checkout.checkoutPriceSummary.result;

        for (const item of purchaseBreakdown.items) {
            if (semiFinalPriceForCartBrandTotals) {
                map.set(item.id, {
                    cartTotal: +(item.totalCartSemiFinalPrice || '0'),
                    seasonTotal: +(item.totalSeasonSemiFinalPrice || '0'),
                });
            } else {
                map.set(item.id, {
                    cartTotal: +(item.totalCartGeneralDealerPrice || '0'),
                    seasonTotal: +(item.totalSeasonGeneralDealerPrice || '0'),
                });
            }
        }

        return map;
    }, [data, semiFinalPriceForCartBrandTotals]);

    return {
        loading,
        cartBrandBreakdown: breakdownByBrandMap,
    };
};

export default useCartBrandTotal;
