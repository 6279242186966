import { FormattedMessage } from 'react-intl';
import type { ReactNode } from 'react';
import { memo, useState } from 'react';
import { Heading, VStack, Text, Button, CheckmarkCircleIcon } from '@library';
import XCircleIcon from '../../icons/XCircleIcon';
import ContactFormModalWithSuccess from '../../../../user/components/ContactFormModal/ContactFormModalWithSuccess';
import styles from './ConfirmationMessage.module.scss';

const ConfirmationMessage = memo(
    ({
        onClose,
        title,
        message,
        type,
    }: {
        /**
         * The on close callback
         */
        onClose: () => void;
        /**
         * The title
         */
        title?: ReactNode;
        /**
         * The message
         */
        message?: ReactNode;
        /**
         * The type of message
         */
        type: 'success' | 'error';
    }) => {
        const [showContactForm, setShowContactForm] = useState(false);
        return (
            <div className={styles.content}>
                <VStack
                    align="center"
                    justify="center"
                    gap="smallMedium"
                    expanded
                >
                    <VStack gap="small" align="center">
                        {type === 'error' ? (
                            <XCircleIcon color="var(--color-danger)" />
                        ) : (
                            <CheckmarkCircleIcon color="var(--color-success)" />
                        )}
                        <Heading h3 center>
                            {title}
                        </Heading>
                        {message && (
                            <Text primary center>
                                {message}
                            </Text>
                        )}
                    </VStack>
                    {type === 'error' ? (
                        <VStack gap="small" align="center">
                            <Button onClick={onClose}>
                                <FormattedMessage id="Common TryAgain" />
                            </Button>
                            <Button
                                text
                                inline
                                onClick={() => {
                                    setShowContactForm(true);
                                }}
                            >
                                <FormattedMessage id="Common ContactSupport" />
                            </Button>
                        </VStack>
                    ) : (
                        <Button onClick={onClose}>
                            <FormattedMessage id="Common Close" />
                        </Button>
                    )}
                </VStack>
                {showContactForm && (
                    <ContactFormModalWithSuccess
                        handleClose={() => {
                            setShowContactForm(false);
                        }}
                    />
                )}
            </div>
        );
    }
);

export default memo(ConfirmationMessage);
