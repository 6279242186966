import { memo } from 'react';
import { clsx } from 'clsx';
import styles from './Typography.module.css';
import type { TypographyProps } from './types';
import { computeTypographyClasses } from './types';
import HyphenatedText from './HyphenatedText';

/**
 * Props for TextRender component
 */
export type TextRenderProps = TypographyProps & {
    /**
     * Primary text styling.
     */
    primary?: boolean;
    /**
     * Secondary text styling.
     */
    secondary?: boolean;
    /**
     * Tertiary text styling.
     */
    tertiary?: boolean;
    /**
     * Caption text styling.
     */
    caption?: boolean;
    /**
     * Truncate overflowed text.
     */
    truncate?: boolean;
    /**
     * HTML elements that this component should render as.
     */
    component?: 'p' | 'div' | 'span' | 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6';
    /**
     * Should use line height that's same as font size.
     */
    lineHeightAsFontSize?: boolean;
    /**
     * The title html attribute
     */
    title?: string;
};

const TextRender = ({
    children,
    uppercase,
    center,
    light,
    regular,
    medium,
    semiBold,
    bold,
    component,
    strikeThrough,
    truncate,
    hyphenated,
    lineHeightAsFontSize,
    primary,
    secondary,
    tertiary,
    caption,
    letterSpacing,
    error,
    italic,
    multiline,
    wordBreakAll,
    color,
    menuButton,
    hoverBold,
    className,
    ...ariaProps
}: TextRenderProps) => {
    const typographyClasses = computeTypographyClasses(
        {
            uppercase,
            center,
            light,
            regular,
            medium,
            semiBold,
            bold,
            hoverBold,
            strikeThrough,
            truncate,
            hyphenated,
            lineHeightAsFontSize,
            letterSpacing,
            error,
            italic,
            multiline,
            wordBreakAll,
            color,
            menuButton,
        },
        styles
    );

    const isDefault = [primary, secondary, tertiary, caption].every(
        item => !item
    );
    const variants = isDefault
        ? { primary: true }
        : {
              primary,
              secondary,
              tertiary,
              caption,
          };

    const Component = component || 'p';
    return (
        <Component
            className={clsx(
                typographyClasses,
                {
                    ...Object.fromEntries(
                        Object.entries(variants).map(([name, value]) => [
                            styles[name],
                            value,
                        ])
                    ),
                },
                className
            )}
            {...ariaProps}
        >
            {hyphenated ? (
                <HyphenatedText>{children}</HyphenatedText>
            ) : (
                children
            )}
        </Component>
    );
};

export default memo(TextRender);
