import type { ReactNode } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CloseIcon } from '@library';
import useImmutableCallback from '../../hooks/useImmutableCallback';
import Button from '../Button';
import styles from './InfoWindowComponent.module.css';

import './InfoWindowComponent.global.scss';

export interface InfoWindowComponentProps {
    context: google.maps.Map | null;
    position?: {
        address: {
            latitude: string;
            longitude: string;
        };
    };
    onClose: () => void;
    children: ReactNode;
    anchor?: google.maps.MVCObject;
    zoomToMarker?: boolean;
}

const InfoWindowComponent = ({
    context,
    position,
    onClose,
    children,
    anchor,
    zoomToMarker,
}: InfoWindowComponentProps) => {
    const containerElementRef = useRef(document.createElement('div'));
    const options = {
        position: position && {
            lat: parseFloat(position.address.latitude),
            lng: parseFloat(position.address.longitude),
        },
    };

    const infoWindowRef = useRef<google.maps.InfoWindow>(
        new google.maps.InfoWindow(options)
    );

    const open = useCallback(
        (
            infoWindowParam: google.maps.InfoWindow,
            anchorParam?: google.maps.MVCObject
        ): void => {
            if (anchorParam) {
                infoWindowParam.open(context, anchorParam);
                if (zoomToMarker) {
                    context?.setZoom(12);
                }
            } else {
                infoWindowParam.open(context);
            }
        },
        [context, zoomToMarker]
    );
    const onCloseImmutable = useImmutableCallback(onClose);

    useEffect(() => {
        infoWindowRef.current.setContent(containerElementRef.current);
        const closeListener = infoWindowRef.current.addListener(
            'closeclick',
            () => {
                onCloseImmutable();
            }
        );
        const mapClickListener = context?.addListener('click', () => {
            onCloseImmutable();
        });

        open(infoWindowRef.current, anchor);

        const window = infoWindowRef.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
        return () => {
            window.close();
            closeListener.remove();
            mapClickListener?.remove();
        };
    }, [anchor, context, open, onCloseImmutable]);

    const container = (
        <>
            <div className={styles.closeIcon}>
                <Button size="small" iconButton onPress={onClose}>
                    <CloseIcon small color="var(--base-color-2)" />
                </Button>
            </div>
            <div className={styles.content}>{children}</div>
        </>
    );

    return createPortal(container, containerElementRef.current);
};
export default InfoWindowComponent;
