import { memo } from 'react';
import BaseIcon from './BaseIcon';
import type { IconProps } from './types';

export default memo(
    ({
        dark,
        ...props
    }: IconProps & {
        /**
         * Dark cell mode
         */
        dark?: boolean;
    }) => {
        return (
            <BaseIcon viewBox="0 0 19 20" {...props}>
                <path
                    d="M2.7 9.1H6.3C7.56833 9.1 8.6 8.06833 8.6 6.8V3.2C8.6 1.93167 7.56833 0.9 6.3 0.9H2.7C1.43167 0.9 0.4 1.93167 0.4 3.2V6.8C0.4 8.06833 1.43167 9.1 2.7 9.1ZM1.66667 3.2C1.66667 2.6303 2.1303 2.16667 2.7 2.16667H6.3C6.86971 2.16667 7.33333 2.6303 7.33333 3.2V6.8C7.33333 7.3697 6.8697 7.83333 6.3 7.83333H2.7C2.1303 7.83333 1.66667 7.36971 1.66667 6.8V3.2Z"
                    fill={'currentColor'}
                    stroke={'currentColor'}
                    strokeWidth="0.2"
                />
                <path
                    d="M2.7 19.1H6.3C7.56833 19.1 8.6 18.0683 8.6 16.8V13.2C8.6 11.9317 7.56833 10.9 6.3 10.9H2.7C1.43167 10.9 0.4 11.9317 0.4 13.2V16.8C0.4 18.0683 1.43167 19.1 2.7 19.1ZM1.66667 13.2C1.66667 12.6303 2.1303 12.1667 2.7 12.1667H6.3C6.86971 12.1667 7.33333 12.6303 7.33333 13.2V16.8C7.33333 17.3697 6.8697 17.8333 6.3 17.8333H2.7C2.1303 17.8333 1.66667 17.3697 1.66667 16.8V13.2Z"
                    fill="white"
                    stroke="currentColor"
                    strokeWidth="0.2"
                />
                <path
                    d="M12.7 9.1H16.3C17.5683 9.1 18.6 8.06833 18.6 6.8V3.2C18.6 1.93167 17.5683 0.9 16.3 0.9H12.7C11.4317 0.9 10.4 1.93167 10.4 3.2V6.8C10.4 8.06833 11.4317 9.1 12.7 9.1ZM11.6667 3.2C11.6667 2.6303 12.1303 2.16667 12.7 2.16667H16.3C16.8697 2.16667 17.3333 2.6303 17.3333 3.2V6.8C17.3333 7.3697 16.8697 7.83333 16.3 7.83333H12.7C12.1303 7.83333 11.6667 7.36971 11.6667 6.8V3.2Z"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0.2"
                />
                <path
                    d="M12.7 19.1H16.3C17.5683 19.1 18.6 18.0683 18.6 16.8V13.2C18.6 11.9317 17.5683 10.9 16.3 10.9H12.7C11.4317 10.9 10.4 11.9317 10.4 13.2V16.8C10.4 18.0683 11.4317 19.1 12.7 19.1ZM11.6667 13.2C11.6667 12.6303 12.1303 12.1667 12.7 12.1667H16.3C16.8697 12.1667 17.3333 12.6303 17.3333 13.2V16.8C17.3333 17.3697 16.8697 17.8333 16.3 17.8333H12.7C12.1303 17.8333 11.6667 17.3697 11.6667 16.8V13.2Z"
                    fill="currentColor"
                    stroke="currentColor"
                    strokeWidth="0.2"
                />
            </BaseIcon>
        );
    }
);
