import { memo, createContext, useMemo, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ErrorBoundary from '../../core/components/ErrorBoundary';
import type { Components, ComponentsHandler, Styles } from '../types/types';

import ComponentsMapper from './ComponentsMapper';
import RenderComponents from './RenderComponents';

export const ComponentMapperContext = createContext<{
    componentMapper: ComponentsHandler;
    styles?: Styles;
}>({
    componentMapper: ComponentsMapper,
});

const useScrollToHash = (scrollToElement?: boolean) => {
    const location = useLocation();
    const scrolled = useRef(false);
    useEffect(() => {
        if (!scrollToElement) {
            return;
        }
        const scrollToHash = () => {
            if (!scrolled.current) {
                const element = document.getElementById(location.hash.slice(1));
                if (element) {
                    element.scrollIntoView();
                    scrolled.current = true;
                }
            }
        };

        scrollToHash();
    }, [location.hash, scrollToElement]);
};

const StoryblokRender = ({
    components,
    componentMapper = ComponentsMapper,
    scrollToElement,
    styles,
}: {
    components?: Components[] | Components;
    componentMapper?: ComponentsHandler;
    styles?: Styles;
    scrollToElement?: boolean;
}) => {
    const context = useMemo(() => {
        return {
            componentMapper,
            styles,
        };
    }, [componentMapper, styles]);

    useScrollToHash(scrollToElement);

    return (
        <ComponentMapperContext.Provider value={context}>
            {components && (
                <ErrorBoundary customMessage="failed to render CMS content">
                    <RenderComponents components={components} />
                </ErrorBoundary>
            )}
        </ComponentMapperContext.Provider>
    );
};

export default memo(StoryblokRender);
