/**
 * Filters out all values that evaluate to false.
 *
 * @see https://developer.mozilla.org/en-US/docs/Glossary/Falsy
 *
 * @returns
 */
function notFalsy<TValue>(
    value: TValue | null | undefined
): value is Exclude<TValue, false | undefined | null | 0 | -0 | 0n | ''> {
    return !!value;
}

export default notFalsy;
