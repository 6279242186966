import { memo, useState } from 'react';
import ContactFormModalWithSuccess from '../../../../user/components/ContactFormModal/ContactFormModalWithSuccess';
import type { ContactButtonComponent } from '../../../types/customStoryblokComponents';
import StoryblokButton from './StoryblokButton';

const ContactButton = (props: ContactButtonComponent) => {
    const [showContactForm, setShowContactForm] = useState(false);
    return (
        <>
            <StoryblokButton
                {...props}
                onPress={() => {
                    setShowContactForm(true);
                }}
            />
            {showContactForm && (
                <ContactFormModalWithSuccess
                    handleClose={() => {
                        setShowContactForm(false);
                    }}
                />
            )}
        </>
    );
};

export default memo(ContactButton);
