import { memo, useContext, useState } from 'react';
import { HStack } from '@library';
import SearchField from '../SearchField';
import RestrictedContent from '../../../../../core/components/router/RestrictedContent';
import LazyComponent from '../../../../../core/components/router/LazyComponent';
import CategorySelector from '../CategorySelector';
import MenuSidebar from '../MenuSidebar';
import Cart from '../Cart';
import { AppContext } from '../../../../../core/context';
import styles from './BottomNav.module.css';

const NotificationCenter = LazyComponent(
    async () => import('../../../NotificationCenter')
);

const BottomNav = () => {
    const { isInTabletView, isInMobileView } = useContext(AppContext);
    const isDesktop = !isInTabletView && !isInMobileView;

    const [isOpen, set] = useState(false);

    return (
        <nav className={styles.container}>
            <HStack align="center" justify="spaceBetween" gap="medium">
                <HStack align="center" gap="gap18" expanded>
                    <MenuSidebar openCategories={() => set(true)} />
                    {isDesktop && (
                        <CategorySelector
                            isOpen={isOpen}
                            setIsOpen={val => set(val)}
                        />
                    )}
                    <SearchField />
                </HStack>
                <RestrictedContent loggedInOnly>
                    <NotificationCenter />
                </RestrictedContent>
                <Cart />
            </HStack>
        </nav>
    );
};

export default memo(BottomNav);
