import { memo } from 'react';
import type { GridColumnComponent } from '../../../types/customStoryblokComponents';
import RenderComponents from '../../RenderComponents';
import { VStack } from '../../../../library';

const StoryblokGridColumn = ({
    width,
    body,
    space,
}: Omit<GridColumnComponent, 'component' | 'width' | '_uid'> &
    Partial<Pick<GridColumnComponent, 'width'>>) => {
    return (
        <div style={{ flex: width }}>
            <VStack gap={space || 'small'}>
                <RenderComponents components={body} />
            </VStack>
        </div>
    );
};

export default memo(StoryblokGridColumn);
