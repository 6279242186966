import type { PriceResult } from '../type';

// This is temporary logic that simply replaces what the behaviour already is.
// Once proper error handling is place, this should be removed.
const handlePriceError = (
    priceResult?: Pick<PriceResult<unknown>, 'errors'>
) => {
    if (priceResult && priceResult.errors && priceResult.errors.length > 0) {
        throw new Error(priceResult.errors[0].code);
    }
};

export default handlePriceError;
