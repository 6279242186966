import { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Button, Text } from '@library';
import ContactFormModalWithSuccess from '../../../../user/components/ContactFormModal/ContactFormModalWithSuccess';

const ContactMenu = memo(() => {
    const [showContactForm, setShowContactFrom] = useState(false);
    const intl = useIntl();

    return (
        <>
            <Button white inline text onClick={() => setShowContactFrom(true)}>
                <Text
                    primary
                    lineHeightAsFontSize
                    hoverBold
                    data-text={intl.formatMessage({ id: 'contact' })}
                >
                    {intl.formatMessage({ id: 'contact' })}
                </Text>
            </Button>
            {showContactForm && (
                <ContactFormModalWithSuccess
                    handleClose={() => {
                        setShowContactFrom(false);
                    }}
                />
            )}
        </>
    );
});

export default ContactMenu;
