import { memo, forwardRef } from 'react';
import type { Ref } from 'react';
import type { BaseInputProps, BaseInputReference } from './BaseInput';
import BaseInput from './BaseInput';
import styles from './TextField.module.css';

/**
 *
 */
export type TextFieldProps = Omit<BaseInputProps, 'theme' | 'children'>;
/**
 * The text field component for single line input.
 */
const TextField = memo(
    forwardRef((props: TextFieldProps, ref: Ref<BaseInputReference>) => {
        return <BaseInput theme={styles.theme} {...props} ref={ref} />;
    })
);

export default TextField;
