import { useEffect } from 'react';
import { gql, useQuery } from '@apollo/client';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import useForm from '../../core/hooks/form/useForm';
import useSendContactMessageMutation from '../queries/useSendContactMessage';
import useLoginState from '../../core/hooks/useLoginState';
import type { ValidationSchema } from '../../core/hooks/form/types';

const currentUserContactInfoQuery = gql`
    query currentUserContactDetails {
        currentUser {
            username
            emailAddress: email
            customerNumber
        }
        currentCustomer {
            id
            name
            phone
        }
    }
`;

interface ContactFormValues {
    name: string;
    subject: string;
    message: string;
    emailAddress: string;
    phone: string;
}

const validationSchema: ValidationSchema<ContactFormValues> = {
    name: 'required',
    subject: 'required',
    message: 'required',
    emailAddress: 'email',
    phone: 'required',
};

const useContactForm = (onSuccess: () => void) => {
    const isLoggedIn = useLoginState();
    const [sendContactForm] = useSendContactMessageMutation();

    const { data: userContactInfo, error } = useQuery<{
        currentUser: {
            emailAddress: string;
            username: string;
            customerNumber: string;
        };
        currentCustomer: {
            phone: string;
            name: string;
        };
    }>(currentUserContactInfoQuery, {
        skip: !isLoggedIn,
    });

    const {
        generateFormFieldProps,
        values,
        setValues,
        handleChange,
        handleBlur,
        isSubmitting: loading,
        handleSubmit,
        errors,
    } = useForm<ContactFormValues>({
        initialState: {
            name: '',
            subject: '',
            message: '',
            emailAddress: '',
            phone: '',
        },
        onSubmit: async submitValues => {
            const { data } = await sendContactForm({
                variables: {
                    ...submitValues,
                    store,
                    language,
                    customerNumber:
                        userContactInfo?.currentUser.customerNumber.toString(),
                    username: userContactInfo?.currentUser.username,
                    url: window.location.href,
                },
            });
            return data?.sendContactMessage.success;
        },
        onSuccess,
        validationSchema,
    });

    const { store, language } = useStoreAndLanguageParams();

    useEffect(() => {
        /* istanbul ignore next -- @preserve */
        if (userContactInfo) {
            setValues(state => ({
                ...state,
                emailAddress: userContactInfo.currentUser.emailAddress,
                phone: userContactInfo.currentCustomer.phone,
                name: userContactInfo.currentCustomer.name,
            }));
        }
    }, [userContactInfo, setValues]);

    return {
        generateFormFieldProps,
        values,
        setValues,
        handleChange,
        handleBlur,
        isSubmitting: loading,
        handleSubmit,
        errors,
        serverError: error,
    };
};

export default useContactForm;
