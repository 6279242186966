import { memo } from 'react';
import type { ReactNode } from 'react';
import { ClickableArea } from '@library';
import { useCookieConsentContext } from '../../../../cookies/context/CookieConsentContext';

const ConsentSettings = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) => {
    const { setSettingsOpened } = useCookieConsentContext();
    return (
        <ClickableArea
            className={className}
            onPress={() => {
                setSettingsOpened(true);
            }}
        >
            {children}
        </ClickableArea>
    );
};

export default memo(ConsentSettings);
