/* eslint-disable max-lines */
import type { DocComponent } from './storyblokComponents';
import type { Components } from './types';
import type {
    Appearance,
    Asset,
    BaseComponent,
    HideForProps,
    LinkField,
} from './base';

export type SpaceSizes = 'micro' | 'small' | 'medium' | 'large';

interface ExternalVideoComponent extends BaseComponent {
    component: 'external_video';
    url?: string;
    storyblok_asset?: Asset;
    width: '100%' | '50%' | '25%';
}

interface RichComponent extends BaseComponent, HideForProps {
    component: 'richtext';
    content: DocComponent;
}

interface TextGridComponent extends BaseComponent, HideForProps {
    component: 'text_grid';
    columns: Components[];
}

interface SliderComponent extends BaseComponent, HideForProps {
    component: 'slider';
    slides: FeatureComponent[];
    height: '1' | '1.2';
}

interface FeatureGridComponent extends BaseComponent, HideForProps {
    component: 'feature_grid';
    title: string;
    features: FeatureComponent[];
}

interface FeatureComponent extends BaseComponent, HideForProps {
    _uid: string;
    component: 'feature';
    button_label?: string;
    description: string;
    image?: Asset;
    link?: LinkField;
    title: string;
    badge_image?: Asset;
    badge_position: 'top-left' | 'bottom-left';
    badge_target?: LinkField;
    images?: Asset[];
    images_timeout?: number;
    sideBarBackground?: string;

    // @deprecated
    isNew: boolean;
}

interface ProductListComponent extends BaseComponent, HideForProps {
    component: 'product_list';
    title: string;
    products: ProductComponent[];
}

interface ProductComponent extends BaseComponent {
    component: 'product';
    node_id: string;
}

interface NewsFeedComponent extends BaseComponent, HideForProps {
    component: 'news_feed';
    limit: string;
    title: string;
    hide_preview_text: 'true' | '';
}

interface LinkTileComponent extends Appearance, BaseComponent {
    component: 'link_tile';
    title: string;
    link: LinkField;
    sub_title: string;
}

type AssetWithExtension = Asset & {
    ext?: string;
};
interface InfoTileComponent extends BaseComponent {
    component: 'info_tile';
    title: string;
    link?: LinkField;
    file?: AssetWithExtension;
    files?: AssetWithExtension[];
    images?: Asset[];
    height?: 'auto' | '100%';
    extension?: 'pdf' | 'csv';
}

interface InfoGridComponent extends BaseComponent {
    component: 'info_grid';
    size: 'small' | 'medium';
    body: Components[];
}

interface TileGridComponent extends BaseComponent {
    component: 'tile_grid';
    body: LinkTileComponent[];
}

interface SectionComponent extends BaseComponent {
    component: 'section';
    title: string;
    body: LinkTileComponent[];
    space: SpaceSizes;
}

interface DownloadSectionComponent extends BaseComponent {
    component: 'download_section';
    title: string;
    image?: Asset;
    body: Components[];
    space?: SpaceSizes;
}

interface SubSectionComponent extends BaseComponent {
    component: 'sub_section';
    title: string;
    body: Components[];
    space: SpaceSizes;
}

interface AlertBlockComponent extends BaseComponent {
    component: 'alert_block';
    text: DocComponent;
}

interface ButtonComponent extends BaseComponent {
    component: 'button';
    label: string;
    target: LinkField;
    button_type: 'primary' | 'secondary' | 'tertiary';
}

interface BaseStoryblokButton extends BaseComponent {
    label: string;
    button_type: 'primary' | 'secondary' | 'tertiary';
}

interface ContactButtonComponent extends BaseStoryblokButton {
    component: 'contact_button';
}

interface IntercycleHelpdeskButtonComponent extends BaseStoryblokButton {
    component: 'intercycle_helpdesk_button';
}

interface ButtonBlockComponent extends BaseComponent {
    component: 'button_block';
    body: ButtonComponent[];
    alignment: 'start' | 'end' | 'center';
}

interface GridComponent extends BaseComponent {
    component: 'grid';
    columns: (GridColumnComponent | Components)[];

    space: SpaceSizes;
    justify: 'start' | 'center' | 'end' | 'spaceBetween' | 'normal';
    align: 'start' | 'end' | 'center' | 'normal';
    wrap: 'wrap' | 'nowrap';

    columns_template: string;
    custom_columns_template: string;
}

interface GridColumnComponent extends BaseComponent {
    component: 'grid_column';
    width: 1 | 2 | 3 | 'auto';
    space?: SpaceSizes;
    body: Components[];
}

interface ContainerComponent extends BaseComponent {
    component: 'container';
    body: Components[];
    space: SpaceSizes;
    max_width: string;
}

interface SpaceComponent extends BaseComponent {
    component: 'space';
    space?: SpaceSizes;
}

interface Link extends BaseComponent, HideForProps {
    component: 'link';
    link: LinkField;
    label: string;
}

interface JotForm extends BaseComponent {
    component: 'jotform';
    url: string;
}

interface GoogleMapComponent extends BaseComponent {
    component: 'google_map';
    latitude?: string;
    longitude?: string;
    address?: string;
    height: 'small' | 'medium' | string;
    boundsPadding?: string;
    markers?: {
        content: DocComponent;
        latitude: string;
        longitude: string;
        title: string;
        _uid: string;
    }[];
    zoomToMarker: boolean;
}

export const googleMapHeight = {
    small: 'var(--google-map-small-height)',
    medium: 'var(--google-map-medium-height)',
};

//cspell: words vimeo
export type SocialLinkType =
    | 'instagram'
    | 'facebook'
    | 'linkedin'
    | 'youtube'
    | 'vimeo';
interface SocialLinkComponent extends BaseComponent {
    component: 'social_link';
    socialType: SocialLinkType;
    url: string;
}

type CustomStoryblokComponents =
    | ExternalVideoComponent
    | RichComponent
    | TextGridComponent
    | SliderComponent
    | FeatureGridComponent
    | FeatureComponent
    | ProductListComponent
    | ProductComponent
    | NewsFeedComponent
    | LinkTileComponent
    | TileGridComponent
    | SectionComponent
    | InfoTileComponent
    | InfoGridComponent
    | DownloadSectionComponent
    | SubSectionComponent
    | AlertBlockComponent
    | ButtonComponent
    | ButtonBlockComponent
    | ContactButtonComponent
    | GridComponent
    | GridColumnComponent
    | ContainerComponent
    | SpaceComponent
    | Link
    | GoogleMapComponent
    | SocialLinkComponent
    | JotForm;

export type {
    GridComponent,
    GridColumnComponent,
    ContainerComponent,
    SpaceComponent,
    FeatureComponent,
    ExternalVideoComponent,
    RichComponent,
    TextGridComponent,
    SliderComponent,
    FeatureGridComponent,
    ProductListComponent,
    ProductComponent,
    NewsFeedComponent,
    LinkTileComponent,
    TileGridComponent,
    InfoTileComponent,
    CustomStoryblokComponents,
    InfoGridComponent,
    DownloadSectionComponent,
    SubSectionComponent,
    AlertBlockComponent,
    ContactButtonComponent,
    BaseStoryblokButton,
    IntercycleHelpdeskButtonComponent,
    ButtonComponent,
    ButtonBlockComponent,
    Link,
    GoogleMapComponent,
    SocialLinkComponent,
};
