import { gql } from '@apollo/client';
import { useContext } from 'react';
import useStoreAndLanguageParams from '../hooks/useStoreAndLanguageParams';
import type { SaleComponent } from '../../content/types/types';
import Storyblok from '../../content/storyblok/storyblok';
import PreorderModeContext from '../../preorder/PreorderModeContext';
import useContentClient from '../../content/hooks/useContentClient';
import useQueryContent from '../../content/storyblok/useQueryContent';

const saleQuery = gql`
    query postQuery($slug: ID!) {
        SaleItem(id: $slug) {
            content {
                banner_image {
                    alt
                    title
                    focus
                    filename
                }
                component
                description
                filter_headline
                filter_tagline
                headline
            }
            name
            id
            lang
            slug
        }
    }
`;

const useSalePage = () => {
    const { isInPreorderMode } = useContext(PreorderModeContext);
    const { store, language } = useStoreAndLanguageParams();
    const client = useContentClient();

    const { data, loading, error } = useQueryContent<{
        SaleItem: {
            content: SaleComponent;
        } | null;
    }>(saleQuery, {
        variables: {
            slug: Storyblok.formatSlug(store, language, 'sale'),
        },
        client,
        skip: isInPreorderMode,
    });

    return {
        data: data?.SaleItem?.content,
        loadingContent: loading,
        error,
    };
};

export default useSalePage;
