import { STORYBLOK_ENVIRONMENTS } from '../types/types';

const replaceStoryblokEnvironmentsRegex = new RegExp(
    '([a-zA-Z]{2}/|/|^)(STORYBLOK_ENVIRONMENTS)(/[a-zA-Z]{2,3}/)'.replace(
        'STORYBLOK_ENVIRONMENTS',
        STORYBLOK_ENVIRONMENTS.join('|')
    ),
    'g'
);
const storyLinkToLocalLink = (storyLink: string) => {
    return storyLink.replace(replaceStoryblokEnvironmentsRegex, '');
};

export default storyLinkToLocalLink;
