import { memo } from 'react';
import type { ReactNode } from 'react';
import { clsx } from 'clsx';
import styles from './Badge.module.css';

const Badge = ({
    primary = false,
    secondary = false,
    tertiary = false,
    danger = false,
    children,
    small,
}: {
    primary?: boolean;
    secondary?: boolean;
    tertiary?: boolean;
    danger?: boolean;
    small?: boolean;
    children?: ReactNode;
}) => {
    return (
        <div
            className={clsx(styles.badge, {
                [styles.primaryBadge]: primary,
                [styles.secondaryBadge]: secondary,
                [styles.tertiaryBadge]: tertiary,
                [styles.danger]: danger,
                [styles.small]: small,
            })}
        >
            {children}
        </div>
    );
};

export default memo(Badge);
