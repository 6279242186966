import { memo } from 'react';
import FacebookIcon from '../../../core/components/icons/socialIcons/FacebookIcon';
import InstagramIcon from '../../../core/components/icons/socialIcons/InstagramIcon';
import LinkedinIcon from '../../../core/components/icons/socialIcons/LinkedinIcon';
import VimeoIcon from '../../../core/components/icons/socialIcons/VimeoIcon';
import YoutubeIcon from '../../../core/components/icons/socialIcons/Youtube';
import type {
    SocialLinkComponent,
    SocialLinkType,
} from '../../types/customStoryblokComponents';
import styles from './SocialLink.module.css';

const SocialIcon = memo(({ type }: { type: SocialLinkType }) => {
    switch (type) {
        case 'instagram': {
            return <InstagramIcon />;
        }
        case 'facebook': {
            return <FacebookIcon />;
        }
        case 'linkedin': {
            return <LinkedinIcon />;
        }
        case 'vimeo': {
            return <VimeoIcon />;
        }
        case 'youtube': {
            return <YoutubeIcon />;
        }
    }
});
const SocialLink = ({ socialType, url }: SocialLinkComponent) => {
    return (
        <a
            className={styles.socialLink}
            href={url}
            target="_blank"
            rel="noreferrer"
            data-test-class="socialLinkIcon"
            data-test-state={socialType}
        >
            <SocialIcon type={socialType} />
        </a>
    );
};

export default memo(SocialLink);
