const toYoutubeEmbedLink = (value: string) => {
    const url = new URL(value);
    if (url.host === 'www.youtube.com' && url.pathname.startsWith('/embed')) {
        return value;
    }
    if (url.host === 'www.youtube.com' && url.pathname === '/watch') {
        const id = url.searchParams.get('v');
        url.pathname = `/embed/${id}`;
        url.search = '';
        return url.toString();
    }
    if (url.host === 'youtu.be') {
        url.search = '';
        url.pathname = `/embed${url.pathname}`;
        url.host = 'www.youtube.com';
        return url.toString();
    }
    return undefined;
};

export default toYoutubeEmbedLink;
