import type { ReactNode } from 'react';
import { memo } from 'react';
import type { LinkField, LinkType } from '../../../types/base';
import CurrentStoreLink from '../../../../core/components/router/CurrentStoreLink';
import storyLinkToLocalLink from '../../../util/storyLinkToLocalLink';
import ContactUsLink from './ContactUsLink';
import ConsentSettings from './ConsentSettings';

const defaultTarget: Record<LinkType, '_blank' | '_self'> = {
    story: '_self',
    url: '_blank',
    asset: '_blank',
    email: '_blank',
};

const defaultLinkType = 'url';

interface StoryblokLinkProps {
    link?: LinkField;
    showEmpty?: boolean;
    children: ReactNode;
    className?: string;
}

const StoryblokUrlLink = memo(
    ({
        url,
        target,
        currentTarget,
        ...rest
    }: {
        children: ReactNode;
        className?: string;
        url: string;
        target?: '_blank' | '_self';
    } & {
        currentTarget: '_blank' | '_self';
    }) => {
        if (url === 'contact-us') {
            return <ContactUsLink {...rest}>{rest.children}</ContactUsLink>;
        }
        if (url === 'cookie-settings') {
            return <ConsentSettings {...rest}>{rest.children}</ConsentSettings>;
        }
        if (url.startsWith('https://') || url.startsWith('http://')) {
            const parsedUrl = new URL(url);
            const isLocal = window.location.hostname.includes(
                parsedUrl.hostname
            );
            return (
                <a
                    href={url}
                    target={isLocal ? '_self' : '_blank'}
                    rel="noreferrer"
                    {...rest}
                >
                    {rest.children}
                </a>
            );
        }
        return (
            <CurrentStoreLink
                to={url}
                target={target ?? defaultTarget.story}
                {...rest}
            />
        );
    }
);

const linkValidMap: Record<LinkType, (link: LinkField) => boolean> = {
    story: link => !!(link.id && link.cached_url),
    url: link => !!link.url,
    email: link => !!link.email,
    asset: link => !!link.url,
};

export const isLinkValid = (link: LinkField) => {
    const linkType = link.linktype || defaultLinkType;
    return linkValidMap[linkType](link);
};

const StoryblokLink = ({ link, showEmpty, ...rest }: StoryblokLinkProps) => {
    if (!link || !isLinkValid(link)) {
        return showEmpty ? <>{rest.children}</> : null;
    }
    const {
        cached_url: cachedUrl,
        linktype,
        url,
        target,
        email,
        anchor: anchorProp,
    } = link;
    const currentTarget = target || defaultTarget[linktype || defaultLinkType];
    const linkProps = {
        target: currentTarget,
        rel: currentTarget === '_blank' ? 'noreferrer' : '',
    };

    const anchor = anchorProp ? `#${anchorProp}` : '';

    switch (linktype) {
        case 'url': {
            return (
                <StoryblokUrlLink
                    url={url + anchor}
                    target={target}
                    currentTarget={currentTarget}
                    {...rest}
                >
                    {rest.children}
                </StoryblokUrlLink>
            );
        }
        case 'story': {
            const str = storyLinkToLocalLink(cachedUrl + anchor);
            return (
                <CurrentStoreLink to={str} target={currentTarget} {...rest} />
            );
        }
        case 'asset': {
            return (
                <a href={url} {...linkProps} {...rest}>
                    {rest.children}
                </a>
            );
        }
        case 'email': {
            return (
                <a href={`mailto:${email}`} {...linkProps} {...rest}>
                    {rest.children}
                </a>
            );
        }
        default: {
            return (
                <a href={url + anchor} {...rest} {...linkProps}>
                    {rest.children}
                </a>
            );
        }
    }
};
export default memo(StoryblokLink);
