/* eslint-disable no-console */
import { ApolloError, gql, useMutation } from '@apollo/client';
import { useCallback, useContext } from 'react';
import { AppContext } from '../context';
import formatErrorMessage from '../util/formatErrorMessage';

const useLogin = (): [
    (
        username: string,
        password: string,
        stayLoggedIn: boolean
    ) => Promise<void>,
    { error?: ApolloError; loading?: boolean },
] => {
    const context = useContext(AppContext);
    const [performLogin, { loading, error }] = useMutation<{
        login: { accessToken?: string };
    }>(gql`
        mutation login($username: String!, $password: String!) {
            login(username: $username, password: $password) {
                accessToken
            }
        }
    `);

    /* istanbul ignore next -- @preserve */
    if (error) {
        // TODO: consolidate this code with ErrorBox
        console.error('Login error', error.name);
        console.error(formatErrorMessage(error));

        if (error instanceof ApolloError) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            if (error.networkError && (error.networkError as any).result) {
                console.error(
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-explicit-any
                    (error.networkError as any).result.errors
                        .map((item: { message: string }) => item.message)
                        .join('\n')
                );
            }
        }
    }

    const login = useCallback(
        async (username: string, password: string, stayLoggedIn: boolean) => {
            const result = await performLogin({
                variables: {
                    username,
                    password,
                },
            });

            const newAccessToken = result.data?.login.accessToken;

            if (!newAccessToken) {
                throw new Error('Login failed');
            }

            context.login(newAccessToken, stayLoggedIn);
        },
        [performLogin, context]
    );

    return [
        login,
        {
            loading,
            error,
        },
    ];
};

export default useLogin;
