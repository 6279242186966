import { memo } from 'react';
const VimeoIcon = () => {
    return (
        <svg
            id="Group_3204"
            data-name="Group 3204"
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="22"
            viewBox="0 0 29.119 25.237"
        >
            <defs>
                <clipPath id="clip-path-vimeo">
                    <rect
                        id="Rectangle_3870"
                        data-name="Rectangle 3870"
                        width="29.119"
                        height="25.237"
                        transform="translate(0 0)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g
                id="Group_3203"
                data-name="Group 3203"
                transform="translate(0 0)"
                clipPath="url(#clip-path-vimeo)"
            >
                <path
                    id="Path_1869"
                    data-name="Path 1869"
                    d="M29.105,5.838Q28.911,10.1,23.166,17.5q-5.938,7.734-10.049,7.734-2.545,0-4.307-4.71Q7.635,16.21,6.46,11.892q-1.307-4.708-2.8-4.71A9,9,0,0,0,1.37,8.556L0,6.788q2.156-1.9,4.25-3.8Q7.125.5,8.564.371q3.4-.327,4.185,4.656.848,5.377,1.177,6.687.98,4.461,2.157,4.459.914,0,2.746-2.893A11.413,11.413,0,0,0,20.79,8.874q.261-2.5-1.961-2.5a5.445,5.445,0,0,0-2.155.478Q18.82-.192,24.875.005q4.488.132,4.23,5.833"
                    transform="translate(0 0)"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
};

export default memo(VimeoIcon);
