import { memo } from 'react';
import { HStack, VStack } from '@library';
import CompanyLogo from '../../../core/components/CompanyLogo';
import MenuSidebar from './components/MenuSidebar';
import SearchField from './components/SearchField';
import Cart from './components/Cart';
import UserProfile from './components/UserProfile';
import styles from './Header.module.css';

export default memo(() => {
    return (
        <VStack gap="mini">
            <HStack justify="spaceBetween" gap="micro">
                <MenuSidebar />
                <div className={styles.logo}>
                    <CompanyLogo dark />
                </div>
                <UserProfile />
            </HStack>
            <SearchField />
            <Cart />
        </VStack>
    );
});
