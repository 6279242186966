import { memo } from 'react';

export default memo(() => {
    return (
        <svg
            width="28"
            height="22"
            viewBox="0 0 28 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M27 4V18C27 18.7956 26.6839 19.5587 26.1213 20.1213C25.5587 20.6839 24.7957 21 24 21H4C3.20435 21 2.44129 20.6839 1.87868 20.1213C1.31607 19.5587 1 18.7956 1 18V4M27 4C27 3.20435 26.6839 2.44129 26.1213 1.87868C25.5587 1.31607 24.7957 1 24 1H4C3.20435 1 2.44129 1.31607 1.87868 1.87868C1.31607 2.44129 1 3.20435 1 4M27 4V4.324C27.0001 4.83619 26.869 5.33987 26.6192 5.78706C26.3695 6.23425 26.0094 6.61005 25.5733 6.87867L15.5733 13.032C15.1003 13.3234 14.5556 13.4777 14 13.4777C13.4444 13.4777 12.8997 13.3234 12.4267 13.032L2.42667 6.88C1.99056 6.61138 1.63049 6.23558 1.38076 5.7884C1.13102 5.34121 0.999943 4.83753 1 4.32533V4"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});
