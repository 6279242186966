import { memo } from 'react';
import { HStack } from '@library';
import CompanyLogo from '../../../../../core/components/CompanyLogo';
import RestrictedContent from '../../../../../core/components/router/RestrictedContent';
import LanguageDropdown from '../LanguageDropdown';
import ContactMenu from '../ContactMenu';
import UserProfile from '../UserProfile';
import DealerPriceDropdown from '../DealerPriceDropdown/DealerPriceDropdown';
import styles from './TopNav.module.scss';

const TopNav = memo(() => {
    return (
        <nav className={styles.container}>
            <HStack justify="spaceBetween" align="center">
                <div className={styles.logo}>
                    <CompanyLogo dark />
                </div>

                <HStack gap="gap38" align="center">
                    <RestrictedContent loggedInOnly>
                        <DealerPriceDropdown />
                    </RestrictedContent>
                    <LanguageDropdown />

                    <ContactMenu />

                    <UserProfile />
                </HStack>
            </HStack>
        </nav>
    );
});

export default TopNav;
