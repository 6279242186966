import { memo, useEffect, useMemo, useState } from 'react';
import type { StoryblokResponsiveImageProps } from '../../../content/components/PageComponents/StoryblokResponsiveImage';
import StoryblokResponsiveImage from '../../../content/components/PageComponents/StoryblokResponsiveImage';
import type { Asset } from '../../../content/types/base';

const StoryblokImageSlideShow = ({
    image,
    images,
    images_timeout,
    ...props
}: Omit<StoryblokResponsiveImageProps, 'image'> & {
    image?: Asset;
    images?: Asset[];
    images_timeout?: number;
}) => {
    const [activeIndex, setActiveIndex] = useState(0);

    const imagesSlideShow = useMemo(() => {
        return images && images.length > 0 ? images : [image];
    }, [images, image]);

    useEffect(() => {
        if (imagesSlideShow.length > 1) {
            const timer = setTimeout(() => {
                setActiveIndex(
                    activeIndex < imagesSlideShow.length - 1
                        ? activeIndex + 1
                        : 0
                );
            }, images_timeout || 4000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [setActiveIndex, activeIndex, imagesSlideShow.length, images_timeout]);

    return (
        <StoryblokResponsiveImage
            image={imagesSlideShow[activeIndex]}
            {...props}
        />
    );
};

export default memo(StoryblokImageSlideShow);
