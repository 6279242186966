import { memo } from 'react';
import RenderComponent from '../RenderComponent';
import type { TileGridComponent } from '../../types/customStoryblokComponents';
import styles from './TileGride.module.scss';

const TileGrid = ({ body }: TileGridComponent) => {
    return (
        <div className={styles.grid}>
            {body.map(item => {
                return (
                    <div
                        key={item.title}
                        style={{
                            gridColumnEnd: `span ${item.width}`,
                            gridRowEnd: `span ${item.height}`,
                        }}
                    >
                        <RenderComponent component={item} />
                    </div>
                );
            })}
        </div>
    );
};

export default memo(TileGrid);
