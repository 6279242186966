import { memo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import VerticalLayout from '../../../core/components/layout/VerticalLayout';
import TextInput from '../../../core/components/input/TextInput';
import useContactForm from '../../hooks/useContactForm';
import Button from '../../../core/components/Button';
import TextBox from '../../../core/components/input/TextBox';
import HorizontalLayout from '../../../core/components/layout/HorizontalLayout';
import UnhandledError from '../../../core/components/UnhandledError';
import styles from './ContactForm.module.scss';

const ContactForm = ({
    onSuccess,
    onCancel,
}: {
    onSuccess: () => void;
    onCancel: () => void;
}) => {
    const {
        generateFormFieldProps,
        isSubmitting,
        handleSubmit,
        errors,
        serverError,
    } = useContactForm(onSuccess);

    const intl = useIntl();

    return (
        <VerticalLayout stretchChildren gap="medium">
            <VerticalLayout gap="small" stretchChildren>
                {serverError && <UnhandledError>{serverError}</UnhandledError>}
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <FormattedMessage id="name" />*
                    </div>
                    <TextInput
                        {...generateFormFieldProps('name')}
                        placeholder={intl.formatMessage({
                            id: 'please enter your name',
                        })}
                        error={Boolean(errors.name)}
                    />
                    <div className={styles.error}>
                        {errors.name && <FormattedMessage id={errors.name} />}
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <FormattedMessage id="subject" />*
                    </div>
                    <TextInput
                        {...generateFormFieldProps('subject')}
                        placeholder={intl.formatMessage({
                            id: 'please enter a subject',
                        })}
                        error={Boolean(errors.subject)}
                    />
                    <div className={styles.error}>
                        {errors.subject && (
                            <FormattedMessage id={errors.subject} />
                        )}
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <FormattedMessage id="message" />*
                    </div>
                    <TextBox
                        {...generateFormFieldProps('message')}
                        rows={3}
                        placeholder={intl.formatMessage({
                            id: 'please enter a message',
                        })}
                        error={Boolean(errors.message)}
                    />
                    <div className={styles.error}>
                        {errors.message && (
                            <FormattedMessage id={errors.message} />
                        )}
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <FormattedMessage id="email address" />*
                    </div>
                    <TextInput
                        {...generateFormFieldProps('emailAddress')}
                        placeholder={intl.formatMessage({
                            id: 'please enter your email',
                        })}
                        error={Boolean(errors.emailAddress)}
                    />
                    <div className={styles.error}>
                        {errors.emailAddress && (
                            <FormattedMessage id={errors.emailAddress} />
                        )}
                    </div>
                </div>
                <div className={styles.formGroup}>
                    <div className={styles.label}>
                        <FormattedMessage id="phone" />*
                    </div>
                    <TextInput
                        {...generateFormFieldProps('phone')}
                        placeholder={intl.formatMessage({
                            id: 'please enter your phone number',
                        })}
                        error={Boolean(errors.phone)}
                    />
                    <div className={styles.error}>
                        {errors.phone && <FormattedMessage id={errors.phone} />}
                    </div>
                </div>
                {errors.submissionError && (
                    <div className={styles.serverError}>
                        <FormattedMessage id={errors.submissionError} />
                    </div>
                )}
                <div className={styles.hint}>
                    * <FormattedMessage id="these fields are required" />
                </div>
            </VerticalLayout>
            <HorizontalLayout gap="small" justify="end">
                <Button secondary onPress={onCancel} messageId="cancel" />
                <Button
                    primary
                    loading={isSubmitting}
                    onPress={handleSubmit}
                    messageId="send message"
                />
            </HorizontalLayout>
        </VerticalLayout>
    );
};

export default memo(ContactForm);
