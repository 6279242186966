import { memo } from 'react';
import type { IconProps } from './types';

const AlertSquareIcon = ({ color }: IconProps) => {
    return (
        <svg width="31" height="31" viewBox="0 0 31 31" color={color}>
            <style>
                {`.b{fill:#fff;font-size:19px;font-family:Roboto-Medium,
                Roboto;font-weight:500;letter-spacing:0.08em;}`}
            </style>
            <g transform="translate(-174 -404)">
                <rect
                    fill="currentColor"
                    width="31"
                    height="31"
                    transform="translate(174 404)"
                />
                <text className="b" transform="translate(187 427)">
                    <tspan x="0" y="0">
                        !
                    </tspan>
                </text>
            </g>
        </svg>
    );
};

export default memo(AlertSquareIcon);
