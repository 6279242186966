/* eslint-disable @typescript-eslint/ban-types */
import type { QueryHookOptions, QueryOptions } from '@apollo/client';
import { useApolloClient, useQuery } from '@apollo/client';
import { useMemo } from 'react';
import assert from '../../util/assert';
import useConsistentReference from '../useConsistentReference';
import useImmutableCallback from '../useImmutableCallback';

type GQLQuery = QueryOptions['query'];

/* eslint max-statements: ['error', 18] */
const useBatchedQuery = <
    R extends {},
    B extends {},
    V extends {} = {},
    I = unknown,
>({
    items,
    variables,
    options,
    itemParameterName,
    query,
    batchQuery,
    itemListParameterName,
    batchToResult,
}: {
    variables: V;
    options?: QueryHookOptions;
    items: I[];
    itemParameterName: string;
    query: GQLQuery;
    itemListParameterName: string;
    batchQuery: GQLQuery;
    batchToResult: (batchResult: B, ids: I[]) => R[];
}) => {
    const client = useApolloClient();

    const batchToResultImmutable = useImmutableCallback(batchToResult);

    const itemsImmutable = useConsistentReference(items);
    const variablesImmutable = useConsistentReference(variables);

    const itemsNotInCache = useMemo(() => {
        if (options?.skip) {
            return [];
        }

        const notInCache: I[] = [];
        for (const item of itemsImmutable) {
            const cachedResult = client.readQuery({
                query,
                variables: {
                    ...variablesImmutable,
                    [itemParameterName]: item,
                },
            });

            if (!cachedResult) {
                notInCache.push(item);
            }
        }
        return notInCache;
    }, [
        client,
        itemParameterName,
        itemsImmutable,
        options?.skip,
        query,
        variablesImmutable,
    ]);

    const {
        loading,
        error: requestError,
        data,
    } = useQuery<B>(batchQuery, {
        variables: {
            ...variablesImmutable,
            [itemListParameterName]: itemsNotInCache,
        },
        ...options,
        skip: options?.skip || !itemsNotInCache.length,
    });

    const { data: resultFromCache, error: cacheError } = useQuery<B>(
        batchQuery,
        {
            variables: {
                ...variablesImmutable,
                [itemListParameterName]: itemsImmutable,
            },
            ...options,
            fetchPolicy: 'cache-only',
        }
    );

    const queryResult = useMemo(() => {
        if (!resultFromCache) {
            return [];
        }
        return batchToResultImmutable(resultFromCache, itemsImmutable);
    }, [resultFromCache, batchToResultImmutable, itemsImmutable]);

    const error = requestError || cacheError;
    if (loading) {
        return {
            loading,
            error,
        };
    }

    if (error) {
        return {
            loading,
            error,
        };
    }

    if (itemsNotInCache.length && !options?.skip) {
        assert(data, 'data is required');
    }

    return {
        loading,
        error,
        data: queryResult,
    };
};

export default useBatchedQuery;
