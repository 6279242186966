import { memo, useContext } from 'react';
import { Button, CartIcon, HStack, Text } from '@library';
import Badge from '../../../../core/components/Badge';
import useCartTotalQuantity from '../../../../cart/hooks/useCartTotalQuantity';
import useCartTotalPrice from '../../../../cart/queries/useCartTotalPrice';
import useCustomNavigate from '../../../../core/hooks/router/useCustomNavigate';
import withErrorBoundary from '../../../../core/components/errors/withErrorBoundary';
import ErrorIcon from '../../../../core/components/icons/ErrorIcon';
import useCartAmountFormatter from '../../../../cart/hooks/useCartAmountFormatter';
import { AppContext } from '../../../../core/context';
import styles from './CartLoggedOut.module.scss';

const CartLoggedOut = memo(() => {
    const navigate = useCustomNavigate();
    const totalQuantity = useCartTotalQuantity();
    const totalPrice = useCartTotalPrice();
    const price = useCartAmountFormatter(totalPrice);
    const { isInMobileView } = useContext(AppContext);

    const goToCart = () => {
        navigate('cart');
    };

    return (
        <HStack reversed={isInMobileView}>
            <Button
                text
                inline
                data-test-id="cart-of-current-user"
                aria-label="cart of current user"
                onClick={goToCart}
            >
                <HStack align="center" gap="mini">
                    <CartIcon />
                    <Text color="white" hoverBold>
                        {price}
                    </Text>
                    <Badge danger>
                        <Text caption>
                            {totalQuantity <= 99 ? totalQuantity : '99+'}
                        </Text>
                    </Badge>
                </HStack>
            </Button>
        </HStack>
    );
});

export default memo(
    withErrorBoundary(CartLoggedOut, {
        errorComponent: () => (
            <div className={styles.amount} data-test-id="headerCartTotalPrice">
                <ErrorIcon />
            </div>
        ),
    })
);
