import type { ComponentType, LazyExoticComponent } from 'react';
import { lazy } from 'react';

// original typings of React.lazy uses any
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const ComponentWithGoogleMap = <T extends ComponentType<any>>(
    component: T
): LazyExoticComponent<T> => {
    return lazy(async () => {
        const loadGoogleApi = await import('./loadGoogleApi');
        await loadGoogleApi.default();
        return {
            default: component,
        };
    });
};

export default ComponentWithGoogleMap;
