import { clsx } from 'clsx';
import type { ReactElement, ReactNode } from 'react';
import { cloneElement } from 'react';
import { CSSTransition } from 'react-transition-group';
import { ProgressSpinner } from '../../../core/components/ProgressIndicators';
import { HStack } from '../layout';
import type { Justify } from '../layout/Stack';
import styles from './BaseButton.module.scss';

export interface BaseButtonProps {
    // Colors
    /**
     * The secondary color.
     */
    secondary?: boolean;
    /**
     * The tertiary color
     */
    tertiary?: boolean;
    /**
     * The danger color
     */
    danger?: boolean;
    /**
     * The sea color
     */
    sea?: boolean;

    // Variants
    /**
     * The outline variant
     */
    outline?: boolean;
    /**
     * The text variant
     */
    text?: boolean;
    /**
     * The Rounded variant
     */
    rounded?: boolean;
    /**
     * The Rounded variant where border-radius 100px
     */
    rounded100?: boolean;
    /**
     * The disabled state of button.
     */
    disabled?: boolean;

    /**
     * The button content.
     */
    children: ReactNode;

    onClick?(e: {
        /**
         *
         */
        preventDefault: () => void;
        /**
         *
         */
        stopPropagation: () => void;
    }): void;

    /**
     * The container component.
     */
    component: ReactElement;

    /**
     * The loading state.
     */
    loading?: boolean;

    /**
     * Override CSS Variables
     */
    theme?: string;

    /**
     * The inline style for adding button to text
     */
    inline?: boolean;
    /**
     * The mini button
     */
    mini?: boolean;
    /**
     * The placement of content
     */
    justify?: Justify;

    /**
     * Use small padding and big line length
     */
    iconButton?: boolean;

    /**
     * The white color of text.
     */
    white?: boolean;

    /**
     * The white color of text.
     */
    whiteBg?: boolean;

    /**
     * Full width button
     */
    fullWidth?: boolean;

    /**
     * width max-content button
     */
    maxContent?: boolean;
}

/**
 * The base button component. Use it only in library.
 */
const BaseButton = ({
    component,
    children,
    secondary,
    tertiary,
    danger,
    sea,
    outline,
    text,
    rounded,
    rounded100,
    loading,
    disabled,
    theme,
    inline,
    mini,
    justify,
    iconButton,
    white,
    whiteBg,
    fullWidth,
    maxContent,
    ...ariaProps
}: BaseButtonProps) => {
    const conditionalClassNames = Object.entries({
        sea,
        secondary,
        tertiary,
        danger,
        outline,
        text,
        rounded,
        white,
        whiteBg,
        fullWidth,
        rounded100,
        maxContent,
    }).reduce((result, [key, value]) => {
        return { ...result, [styles[key]]: value };
    }, {});

    return cloneElement(component, {
        ...ariaProps,
        className: clsx(
            styles.button,
            styles.base,
            conditionalClassNames,
            theme,
            {
                [styles.loading]: loading,
                [styles.inline]: inline,
                [styles.mini]: mini,
                [styles.iconButton]: iconButton,
            }
        ),
        disabled: disabled || loading,
        children: (
            <>
                <CSSTransition
                    in={loading}
                    unmountOnExit
                    timeout={{
                        enter: 300,
                        exit: 0,
                    }}
                    classNames={{
                        enter: `${styles.loadingIndicator} ${styles.loadingIndicatorEnter}`,
                        enterActive: styles.loadingIndicatorEnterActive,
                        enterDone: styles.loadingIndicator,
                    }}
                >
                    <div className={styles.loadingIndicator}>
                        <ProgressSpinner />
                    </div>
                </CSSTransition>
                <div className={styles.content}>
                    <HStack align="center" gap={'gap8'} justify={justify}>
                        {children}
                    </HStack>
                </div>
            </>
        ),
    });
};

export default BaseButton;
