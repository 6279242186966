import type { ForwardedRef } from 'react';
import { memo, forwardRef, useContext } from 'react';
import { AppContext } from '../../../core/context';
import styles from './Header.module.css';
import TopNav from './components/TopNav/TopNav';
import BottomNav from './components/BottomNav/BottomNav';
import MobileHeader from './MobileHeader';

const Header = (_: unknown, ref: ForwardedRef<HTMLDivElement>) => {
    const { isInMobileView } = useContext(AppContext);
    return (
        <header className={styles.header} data-test-id="header" ref={ref}>
            {isInMobileView ? (
                <MobileHeader />
            ) : (
                <>
                    <TopNav />
                    <BottomNav />
                </>
            )}
        </header>
    );
};

export default memo(forwardRef(Header));
