import useCustomerPriceVisibility from '../../core/hooks/useCustomerPriceVisibility';
import usePriceFormatter from '../../core/hooks/usePriceFormatter';

const useCartAmountFormatter = (customerPrice: {
    retailPrice: number;
    finalPrice?: number;
}) => {
    const priceFormatter = usePriceFormatter();
    const [showCustomerPrice] = useCustomerPriceVisibility();
    const { price, currency } = priceFormatter(
        showCustomerPrice ? customerPrice.finalPrice : customerPrice.retailPrice
    );

    return currency === 'EUR' ? `€ ${price}` : `${price} ${currency}`;
};

export default useCartAmountFormatter;
