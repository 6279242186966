import { useCallback, useState } from 'react';
import validate from '../../util/validate';
import type {
    ValidationSchema,
    OnSubmitResult,
    OnSubmitCallback,
} from './types';
import useFormFields from './useFormFields';

const useForm = <T extends object>({
    initialState,
    onSuccess,
    validationSchema,
    onSubmit,
    onFailure,
}: {
    initialState: T;
    validationSchema: ValidationSchema<T>;
    onSuccess(): void;
    onSubmit: OnSubmitCallback<T>;
    onFailure?(): void;
}) => {
    const [isSubmitting, setSubmitting] = useState(false);
    const [isSubmitted, setSubmitted] = useState(false);
    const [values, setValues] = useState<T>(initialState);

    const updateFields = useCallback((newValues: Partial<T>) => {
        setValues(prevValues => ({ ...prevValues, ...newValues }));
    }, []);

    const {
        generateFormFieldProps,
        handleBlur,
        errors,
        setErrors,
        touched,
        setAllTouched,
        handleChange,
        hasError,
        handleChanges,
    } = useFormFields({ initialState, validationSchema, values, updateFields });

    const handleResult = (result: OnSubmitResult<T>) => {
        if (typeof result === 'object') {
            setErrors(result);
        } else if (result) {
            setErrors({});
            onSuccess();
        } else {
            setErrors({
                submissionError: 'an error occurred. please try again later',
            });
            /* istanbul ignore next -- @preserve */
            onFailure?.();
        }
    };

    const handleSubmit = async (e?: { preventDefault: () => void }) => {
        if (e) {
            e.preventDefault();
        }
        setSubmitted(true);

        const validateResult = validate(values, validationSchema);
        setAllTouched();

        if (validateResult.valid) {
            setSubmitting(true);
            try {
                const resultAwaitable = onSubmit(values);
                const result =
                    resultAwaitable instanceof Promise
                        ? await resultAwaitable
                        : resultAwaitable;
                handleResult(result);
            } catch (error) {
                setErrors(prevValues => ({
                    ...prevValues,
                    submissionError:
                        'an error occurred. please try again later',
                }));
            }
            setSubmitting(false);
        } else {
            setErrors(validateResult.errors);
        }
    };

    const reset = () => {
        setErrors({});
        setSubmitted(false);
        setValues({
            ...initialState,
        });
    };

    return {
        generateFormFieldProps,
        values,
        setValues,
        handleChange,
        handleBlur,
        isSubmitting,
        handleSubmit,
        errors,
        setErrors,
        touched,
        hasError,
        handleChanges,
        isSubmitted,
        reset,
    };
};

export default useForm;
