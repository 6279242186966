const toStoryblokImageServiceLink = (
    src: string,
    params: {
        width?: number;
        height?: number;
        focus?: string;
        onlyFocusCrop?: boolean;
        format?: 'jpeg' | 'png' | 'webp';
    }
) => {
    const paramsWithDefault = {
        width: 0,
        height: 0,
        format: 'webp',
        ...params,
    };

    const focal = params.focus ? `:focal(${params.focus})` : '';

    paramsWithDefault.height =
        params.onlyFocusCrop && !params.focus ? 0 : paramsWithDefault.height;

    return src.replace(
        'a.storyblok.com',
        `img2.storyblok.com/${paramsWithDefault.width}x${paramsWithDefault.height}/filters:format(${paramsWithDefault.format})${focal}:quality(80)`
    );
};

export default toStoryblokImageServiceLink;
