import type { FloatingContext, ReferenceType, Side } from '@floating-ui/react';
import { useTransitionStyles } from '@floating-ui/react';

const opposite: Record<Side, Side> = {
    top: 'bottom',
    bottom: 'top',
    left: 'right',
    right: 'left',
};

const useDropdownAnimation = <RT extends ReferenceType = ReferenceType>(
    context: FloatingContext<RT>
) => {
    return useTransitionStyles(context, {
        duration: 180,
        initial: ({ side }) => ({
            opacity: 0,
            transform:
                side === 'top' || side === 'bottom'
                    ? 'scaleY(0.7)'
                    : 'scaleX(0.7)',
        }),
        common: ({ side }) => ({
            transformOrigin: opposite[side],
        }),
    });
};

export default useDropdownAnimation;
