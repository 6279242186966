import { memo, useState } from 'react';
import ContactFormModal from './ContactFormModal';
import ContactMessageFormConfirmation from './ContactMessageFormConfirmation';

const ContactFormModalWithSuccess = ({
    handleClose,
}: {
    handleClose(): void;
}) => {
    const [showContactForm, setShowContactFrom] = useState(true);
    const [showSuccessContact, setSuccessShowContact] = useState(false);

    return (
        <>
            <ContactFormModal
                open={showContactForm}
                onClose={(success = false) => {
                    if (success) {
                        setSuccessShowContact(success);
                    } else {
                        handleClose();
                    }

                    setShowContactFrom(false);
                }}
            />

            <ContactMessageFormConfirmation
                open={showSuccessContact}
                onClose={() => {
                    setSuccessShowContact(false);
                    handleClose();
                }}
            />
        </>
    );
};

export default memo(ContactFormModalWithSuccess);
