import type { Dispatch, SetStateAction } from 'react';
import { memo, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { clsx } from 'clsx';
import {
    Button,
    HStack,
    ShowCatalogIcon,
    Text,
    CloseIcon,
    TooltipStateLess,
    VStack,
    ChevronRightIcon,
} from '@library';
import type Category from '../../../../core/types/category';
import type { CategoryWithFilters } from '../../../../core/types/category';
import useOutsideClick from '../../../../core/hooks/dom/useOutsideClick';
import useCategories from '../../../../core/queries/useCategories';
import CurrentStoreLink from '../../../../core/components/router/CurrentStoreLink';
import { buildTree } from '../../../../core/util/categories';
import styles from './CategorySelector.module.scss';

interface CategorySelectorProps {
    isOpen: boolean;
    setIsOpen: Dispatch<SetStateAction<boolean>>;
}

const CategorySelector = memo(
    ({ isOpen, setIsOpen }: CategorySelectorProps) => {
        const intl = useIntl();
        const containerRef = useRef<HTMLDivElement>(null);
        const [hoveredCategoryId, setHoveredCategoryId] = useState('');
        const { categories } = useCategories();
        const tree = buildTree(categories);
        const mainCategories = categories.filter(({ parentId }) => !parentId);
        const firstMainCategory = tree.find(
            category => category.id === mainCategories[0].id
        );
        const selectedCategoryTree =
            tree.find(category => category.id === hoveredCategoryId) ||
            firstMainCategory;

        useOutsideClick(containerRef, () => {
            setIsOpen(false);
        });

        return (
            <TooltipStateLess
                open={isOpen}
                onOpenChange={value => {
                    setIsOpen(value);
                }}
                mode="click"
                theme={styles.tooltipTheme}
                placement={'bottom'}
                content={
                    <div className={styles.container}>
                        <HStack>
                            <div className={styles.mainCategories}>
                                <MainCategoriesList
                                    activeCategoryId={selectedCategoryTree?.id}
                                    categories={mainCategories}
                                    onHover={setHoveredCategoryId}
                                    onClick={() => setIsOpen(false)}
                                />
                            </div>
                            {selectedCategoryTree && (
                                <CategoryColumns
                                    category={selectedCategoryTree}
                                    onClick={() => setIsOpen(false)}
                                />
                            )}
                        </HStack>
                    </div>
                }
                offsetOptions={{
                    mainAxis: 16,
                    crossAxis: 26,
                }}
            >
                <Button
                    outline={!isOpen}
                    white={!isOpen}
                    whiteBg={isOpen}
                    rounded100
                    justify="center"
                    theme={styles.triggerTheme}
                    onClick={() => setIsOpen(value => !value)}
                >
                    <HStack gap="mini" align="center">
                        {isOpen ? (
                            <CloseIcon color="var(--pill-content-color)" />
                        ) : (
                            <ShowCatalogIcon mini />
                        )}
                        <Text
                            hoverBold
                            menuButton
                            lineHeightAsFontSize
                            data-text={intl.formatMessage({
                                id: isOpen ? 'close' : 'all categories',
                            })}
                        >
                            {intl.formatMessage({
                                id: isOpen ? 'close' : 'all categories',
                            })}
                        </Text>
                    </HStack>
                </Button>
            </TooltipStateLess>
        );
    }
);

const MainCategoriesList = memo(
    ({
        categories,
        onHover,
        activeCategoryId,
        onClick,
    }: {
        categories: CategoryWithFilters[];
        onHover: (id: string) => void;
        activeCategoryId?: string;
        onClick: () => void;
    }) => {
        return (
            <VStack>
                {categories.map(category => (
                    <MainCategoryListItem
                        key={category.id}
                        category={category}
                        active={activeCategoryId === category.id}
                        onHover={onHover}
                        onClick={onClick}
                    />
                ))}
            </VStack>
        );
    }
);

const MainCategoryListItem = memo(
    ({
        category,
        active,
        onHover,
        onClick,
    }: {
        category: CategoryWithFilters;
        active: boolean;
        onHover: (id: string) => void;
        onClick: () => void;
    }) => {
        return (
            <CurrentStoreLink
                key={category.id}
                className={clsx(styles.mainCategoryLink, {
                    [styles.mainCategoryLinkActive]: active,
                })}
                to={`/categories/${category.id}/page/1`}
                data-test-class="header-category-links"
                onMouseEnter={() => onHover(category.id)}
                onClick={onClick}
            >
                <HStack align="center" justify="spaceBetween">
                    <Text
                        regular
                        hoverBold
                        menuButton
                        semiBold={active}
                        data-text={category.name}
                    >
                        {category.name}
                    </Text>
                    <ChevronRightIcon />
                </HStack>
            </CurrentStoreLink>
        );
    }
);

const CategoryColumns = memo(
    ({ category, onClick }: { category: Category; onClick: () => void }) => {
        return (
            <div className={styles.categories}>
                <VStack gap="gap22">
                    <HStack gap="mini">
                        <Text menuButton>{category.name}</Text>

                        <CurrentStoreLink
                            to={`/categories/${category.id}/page/1`}
                            className={styles.allProductsLink}
                            onClick={onClick}
                        >
                            <Text semiBold menuButton>
                                <FormattedMessage id="Header View All Products" />
                            </Text>
                        </CurrentStoreLink>
                    </HStack>

                    <div className={styles.columns}>
                        {category.children.map(child => (
                            <CategoryColumn
                                key={child.id}
                                category={child}
                                onClick={onClick}
                            />
                        ))}
                    </div>
                </VStack>
            </div>
        );
    }
);

const CategoryColumn = memo(
    ({ category, onClick }: { category: Category; onClick: () => void }) => {
        return (
            <div key={category.id} className={styles.column}>
                <VStack gap="mini">
                    <CurrentStoreLink
                        className={styles.leaf}
                        to={`/categories/${category.id}/page/1`}
                        data-text={category.name}
                        onClick={onClick}
                    >
                        <Text medium>{category.name}</Text>
                    </CurrentStoreLink>
                    <VStack gap="gap4">
                        {category.children.map(leaf => (
                            <CurrentStoreLink
                                key={leaf.id}
                                to={`/categories/${leaf.id}/page/1`}
                                className={styles.leaf}
                                onClick={onClick}
                            >
                                <Text primary hoverBold data-text={leaf.name}>
                                    {leaf.name}
                                </Text>
                            </CurrentStoreLink>
                        ))}
                    </VStack>
                </VStack>
            </div>
        );
    }
);

export default memo(CategorySelector);
