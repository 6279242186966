import { memo, useState } from 'react';
import Container from '../../core/components/Container';
import SpinnerPage from '../../core/components/pages/SpinnerPage';
import useEventListener from '../../core/hooks/dom/useEventListener';
import isString from '../../core/util/isString';

type JotFormEventData =
    | {
          action: string;
          formID: string;
      }
    | string;

const parseData = (data: JotFormEventData) => {
    if (isString(data)) {
        const args = data.split(':');
        const [action, height, formID] = args;
        return {
            action,
            height,
            formID,
        };
    }
    return { ...data, height: '' };
};

const useJotForm = ({
    src,
    onSubmissionCompleted,
}: {
    src: string;
    onSubmissionCompleted?: () => void;
}) => {
    const [iframe, setIFrame] = useState<HTMLIFrameElement | null>(null);

    const handleJotFormAction = ({
        action,
        height,
        safeIframe,
    }: {
        action: string;
        height: string;
        safeIframe: HTMLIFrameElement;
    }) => {
        switch (action) {
            case 'submission-completed': {
                if (onSubmissionCompleted) {
                    onSubmissionCompleted();
                }
                break;
            }
            case 'scrollIntoView':
                safeIframe.scrollIntoView();
                break;
            case 'setHeight':
                safeIframe.style.height = `${height}px`;
                break;
            case 'collapseErrorPage':
                if (safeIframe.clientHeight > global.innerHeight) {
                    safeIframe.style.height = `${global.innerHeight}px`;
                }
                break;
            case 'reloadPage':
                global.location.reload();
                break;
        }
    };

    useEventListener({
        element: window,
        type: 'message',
        listener: (event: MessageEvent<JotFormEventData | undefined>) => {
            // cspell: words jotfor
            const isJotForm = event.origin.indexOf('jotfor') > -1;
            const eventData = event.data;
            if (!eventData || !isJotForm) {
                return;
            }
            const { action, formID, height } = parseData(eventData);
            if (!!iframe && src.endsWith(formID)) {
                handleJotFormAction({
                    action,
                    height,
                    safeIframe: iframe,
                });
            }
        },
    });
    return {
        ref: (value: HTMLIFrameElement) => {
            setIFrame(value);
        },
        iframe,
    };
};

const JotForm = ({
    src,
    className,
    scrolling,
    onSubmissionCompleted,
}: {
    src: string;
    className?: string;
    scrolling?: boolean;
    onSubmissionCompleted?: () => void;
}) => {
    const [isLoading, setIsLoaded] = useState(true);
    const { ref, iframe } = useJotForm({ src, onSubmissionCompleted });

    useEventListener({
        element: iframe,
        type: 'load',
        listener: () => {
            setIsLoaded(false);
        },
    });

    return (
        <>
            {isLoading && (
                <Container>
                    <SpinnerPage />
                </Container>
            )}
            <iframe
                ref={ref}
                title="JotForm"
                className={className}
                style={{ visibility: isLoading ? 'hidden' : 'visible' }}
                src={src}
                frameBorder={0}
                scrolling={scrolling ? 'yes' : 'no'}
            />
        </>
    );
};

export default memo(JotForm);
