import { Fragment, memo } from 'react';
import type { Components } from '../types/types';
import RenderComponent from './RenderComponent';

const RenderComponents = ({
    components,
}: {
    components?: Components[] | Components;
}) => {
    if (!components) {
        return null;
    }
    return (
        <>
            {Array.isArray(components) ? (
                components
                    .map(component => {
                        return (
                            <RenderComponent
                                component={component}
                                key={component._uid}
                            />
                        );
                    })
                    .map((component, index) => (
                        <Fragment key={String(index)}>{component}</Fragment>
                    ))
            ) : (
                <RenderComponent component={components} />
            )}
        </>
    );
};

export default memo(RenderComponents);
