import { memo } from 'react';

export default memo(() => {
    return (
        <svg
            width="26"
            height="24"
            viewBox="0 0 26 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 5.17067V4C5 3.20435 5.31607 2.44129 5.87868 1.87868C6.44129 1.31607 7.20435 1 8 1H18C18.7956 1 19.5587 1.31607 20.1213 1.87868C20.6839 2.44129 21 3.20435 21 4V5.17067M5 5.17067C5.31333 5.06 5.64933 5 6 5H20C20.3507 5 20.6867 5.06 21 5.17067M5 5.17067C4.41495 5.37751 3.90844 5.76069 3.55025 6.26741C3.19206 6.77413 2.99981 7.37946 3 8V9.17067M21 5.17067C21.585 5.37751 22.0916 5.76069 22.4498 6.26741C22.8079 6.77413 23.0002 7.37946 23 8V9.17067M3 9.17067C3.31333 9.06 3.64933 9 4 9H22C22.3406 8.99961 22.6788 9.05732 23 9.17067M3 9.17067C1.83467 9.58267 1 10.6933 1 12V20C1 20.7957 1.31607 21.5587 1.87868 22.1213C2.44129 22.6839 3.20435 23 4 23H22C22.7957 23 23.5587 22.6839 24.1213 22.1213C24.6839 21.5587 25 20.7957 25 20V12C25.0002 11.3795 24.8079 10.7741 24.4498 10.2674C24.0916 9.76069 23.585 9.37751 23 9.17067"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});
