import { memo } from 'react';
import type { CSSProperties, ReactNode } from 'react';
import styles from './StoryblokGridLayout.module.scss';

export const alignmentValueMap = {
    start: 'flex-start',
    end: 'flex-end',
    center: 'center',
    stretch: 'stretch',
    normal: 'normal',
    baseline: 'baseline',
};

export const justifyValueMap = {
    start: 'flex-start',
    end: 'flex-end',
    spaceBetween: 'space-between',
    center: 'center',
    normal: 'normal',
};

const StoryblokGridLayout = ({
    columnsTemplate,
    gap = 'medium',
    align = 'normal',
    justify = 'normal',
    children,
}: {
    children: ReactNode;
    columnsTemplate: string;
    align?: keyof typeof alignmentValueMap;
    justify?: keyof typeof justifyValueMap;
    gap?: 'none' | 'micro' | 'small' | 'medium' | 'large';
}) => {
    return (
        <div
            style={
                {
                    '--column-template': columnsTemplate,
                    '--gap': `var(--gap-${gap})`,
                    justifyContent: justifyValueMap[justify],
                    alignItems: alignmentValueMap[align],
                } as CSSProperties
            }
            className={styles.grid}
        >
            {children}
        </div>
    );
};

export default memo(StoryblokGridLayout);
