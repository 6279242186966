import { memo } from 'react';
import type { IconProps } from './types';

const ChevronRightIcon = ({ iconLabel, color }: IconProps) => (
    <svg
        width="7"
        height="12"
        viewBox="0 0 9 16"
        fill="none"
        data-icon={iconLabel}
        color={color}
    >
        <path
            d="M7.22656 14.2265L1.07611 8.07611L7.22656 1.92561"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </svg>
);

export default memo(ChevronRightIcon);
