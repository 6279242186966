import { memo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, ContactIcon, HStack, Text } from '@library';
import ContactFormModalWithSuccess from '../../../../user/components/ContactFormModal/ContactFormModalWithSuccess';
import styles from './MenuSidebar.module.css';

export default memo(() => {
    const [showContactForm, setShowContactFrom] = useState(false);
    return (
        <>
            <Button
                inline
                text
                theme={styles.buttonTheme}
                onClick={() => setShowContactFrom(true)}
            >
                <HStack align="center" gap="gap16">
                    <ContactIcon />
                    <Text menuButton>
                        <FormattedMessage id="contact us" />
                    </Text>
                </HStack>
            </Button>
            {showContactForm && (
                <ContactFormModalWithSuccess
                    handleClose={() => {
                        setShowContactFrom(false);
                    }}
                />
            )}
        </>
    );
});
