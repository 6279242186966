import toVimeoEmbedLink from './toVimeoEmbedLink';
import toYoutubeEmbedLink from './toYoutubeEmbedLink';

const getVideoOptions = (
    url?: string,
    storyblokAsset?: { filename?: string }
) => {
    if (url) {
        const parsedUrl = new URL(url);
        if (parsedUrl.host.includes('vimeo.com')) {
            return {
                link: toVimeoEmbedLink(url),
                type: 'iframe',
            };
        }
        if (
            parsedUrl.host === 'www.youtube.com' ||
            parsedUrl.host === 'youtu.be'
        ) {
            return {
                link: toYoutubeEmbedLink(url),
                type: 'iframe',
            };
        }

        return {
            link: url,
            type: 'video',
        };
    }

    return {
        link: storyblokAsset?.filename,
        type: 'video',
    };
};

export default getVideoOptions;
