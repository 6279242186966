import { memo, useContext } from 'react';
import PreorderModeContext from '../../../preorder/PreorderModeContext';
import useActivePreorderSeasons from '../../../preorder/queries/useActivePreorderSeasons';
import SidePaneMenuLink from './SidePaneMenuLink';

const SidePanePreorderItems = ({ onClose }: { onClose(): void }) => {
    const { isInPreorderMode } = useContext(PreorderModeContext);
    const { activePreorderSeasons, loading } = useActivePreorderSeasons();

    if (loading || activePreorderSeasons.length === 0) {
        return null;
    }

    const baseRoute = isInPreorderMode ? '../../' : '/';

    return (
        <>
            {activePreorderSeasons.map(item => (
                <SidePaneMenuLink
                    key={`preorder_${item.id}`}
                    to={`${baseRoute}preorder/${item.id}`}
                    onClick={onClose}
                >
                    {item.preorderName}
                </SidePaneMenuLink>
            ))}
        </>
    );
};

export default memo(SidePanePreorderItems);
