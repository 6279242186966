import { useCallback, useEffect, useRef } from 'react';
import getPlaceByInput from '../../core/util/googleMap/getPlaceByInput';
import type { Point } from '../../core/components/maps/types';

const defaultGlobalPosition = {
    latitude: 47.1761143,
    longitude: 8.1001189,
};

const useGetPositionFromGeoParams = ({
    latitude,
    longitude,
    address,
    onPositionChange,
    skip,
}: {
    latitude?: string;
    longitude?: string;
    address?: string;
    onPositionChange?(newPosition?: Point): void;
    skip: boolean;
}) => {
    const searchDivRef = useRef<HTMLDivElement>(null);

    const autoCompleteServiceRef = useRef(
        new google.maps.places.AutocompleteService()
    );

    const updatePosition = useCallback(
        (newPosition?: Point) => {
            onPositionChange?.(newPosition);
        },
        [onPositionChange]
    );

    // eslint-disable-next-line max-statements
    useEffect(() => {
        if (skip) {
            return;
        }
        const divRefCurrent = searchDivRef.current;
        if (!divRefCurrent) {
            return;
        }

        if (address) {
            const loadByAddress = async (input: string) => {
                const result = await getPlaceByInput(
                    autoCompleteServiceRef.current,
                    input,
                    divRefCurrent
                );
                if (result?.geometry?.location) {
                    const point = {
                        latitude: result.geometry.location.lat(),
                        longitude: result.geometry.location.lng(),
                    };
                    updatePosition(point);
                }
            };
            loadByAddress(address).catch(ex => {
                throw ex;
            });
            return;
        }

        if (latitude && longitude) {
            const latNumber = parseFloat(latitude);
            const lngNumber = parseFloat(longitude);
            if (latNumber && lngNumber) {
                updatePosition({
                    latitude: latNumber,
                    longitude: lngNumber,
                });
                return;
            }
        }

        updatePosition(defaultGlobalPosition);
    }, [latitude, longitude, address, updatePosition, skip]);

    return {
        searchDivRef,
    };
};

export default useGetPositionFromGeoParams;
