import { memo, useMemo } from 'react';
import Carousel from '../../../core/components/Carousel';
import ChevronLeftIcon from '../../../core/components/icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../core/components/icons/ChevronRightIcon';
import Button from '../../../core/components/Button';
import useCarouselActions from '../../../core/hooks/useCarouselActions';
import LazyComponent from '../../../core/components/router/LazyComponent';
import ComponentSuspense from '../../../core/components/router/ComponentSuspense';
import type { ProductItem } from '../../../product/types';
import styles from './ProductShowcaseCarousel.module.scss';

const ProductTile = LazyComponent(
    async () =>
        import(
            /* webpackChunkName: "ProductTileShowcase" */ '../../../product/components/ProductTileView/ProductTile'
        )
);

const ProductShowcaseCarousel = ({ products }: { products: ProductItem[] }) => {
    const { nextItem, previousItem, controlsState, handleChangeActions } =
        useCarouselActions({ watchControlState: true });

    const variantExistsInList = useMemo(
        () => products.some(item => item.variants.length > 0),
        [products]
    );

    return (
        <div className={styles.productCarousel}>
            {products.length > 1 && controlsState.showControls && (
                <div className={styles.previousItemButton}>
                    <Button iconButton onPress={previousItem}>
                        <ChevronLeftIcon
                            iconLabel="carousel-previous-icon"
                            color="var(--default-icon-color-2)"
                        />
                    </Button>
                </div>
            )}

            <ComponentSuspense height="560px" width="100%">
                <Carousel
                    loop
                    buttonWidth={0}
                    centered
                    observeCarouselSize
                    onChangeActions={handleChangeActions}
                >
                    {products.map(product => (
                        <div key={product.id} className={styles.productTile}>
                            <ProductTile
                                fullHeight
                                product={product}
                                variantExistsInList={variantExistsInList}
                            />
                        </div>
                    ))}
                </Carousel>
            </ComponentSuspense>
            {products.length > 1 && controlsState.showControls && (
                <div className={styles.nextItemButton}>
                    <Button iconButton onPress={nextItem}>
                        <ChevronRightIcon
                            iconLabel="carousel-next-icon"
                            color="var(--default-icon-color-2)"
                        />
                    </Button>
                </div>
            )}
        </div>
    );
};

export default memo(ProductShowcaseCarousel);
