import { memo } from 'react';
import { Heading, VStack } from '@library';
import type { DocComponent } from '../../../types/storyblokComponents';
import StoryblokRender from '../../StoryblokRender';
import type { InfoWindowComponentProps } from '../../../../core/components/maps/InfoWindowComponent';
import InfoWindowComponent from '../../../../core/components/maps/InfoWindowComponent';
import styles from './MarkerInfoPopup.module.scss';

const MarkerInfoPopupContent = memo(
    ({ content, title }: { content: DocComponent; title: string }) => {
        return (
            <div className={styles.popup} data-test-class="popup">
                <VStack gap="small">
                    <Heading h4 medium>
                        {title}
                    </Heading>

                    <div>
                        <StoryblokRender components={content} />
                    </div>
                </VStack>
            </div>
        );
    }
);

const MarkerInfoPopup = memo(
    ({
        context,
        onClose,
        anchor,
        content,
        title,
        position,
        zoomToMarker,
    }: Pick<
        InfoWindowComponentProps,
        'context' | 'onClose' | 'anchor' | 'position' | 'zoomToMarker'
    > & {
        content: DocComponent;
        title: string;
    }) => {
        return (
            <InfoWindowComponent
                context={context}
                position={position}
                onClose={onClose}
                anchor={anchor}
                zoomToMarker={zoomToMarker}
            >
                <MarkerInfoPopupContent content={content} title={title} />
            </InfoWindowComponent>
        );
    }
);

export default memo(MarkerInfoPopup);
