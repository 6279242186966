import { gql } from '@apollo/client';
import useStoreAndLanguageParams from '../hooks/useStoreAndLanguageParams';
import Storyblok from '../../content/storyblok/storyblok';
import type { FooterComponent } from '../../content/types/types';
import useContentClient from '../../content/hooks/useContentClient';
import useQueryContent from '../../content/storyblok/useQueryContent';

const footerQuery = gql`
    query footerItem($slug: ID!) {
        FooterItem(id: $slug) {
            content {
                body
                component
                contact_information
                copyright
                email
                social_links
                company_address
                contact_details
                logo {
                    alt
                    copyright
                    filename
                    focus
                    id
                    name
                    title
                }
                under_copyright_body
            }
            name
            id
            lang
        }
    }
`;

const useFooterData = () => {
    const { store, language } = useStoreAndLanguageParams();

    const client = useContentClient();

    const { data, error } = useQueryContent<{
        FooterItem?: {
            content: FooterComponent;
        };
    }>(footerQuery, {
        variables: {
            slug: Storyblok.formatSlug(store, language, 'footer'),
        },
        client,
    });

    return {
        error,
        storyblokFooter: data?.FooterItem?.content,
        isStoryblokLoaded: !!data,
    };
};

export default useFooterData;
