import { memo } from 'react';
import AlertSquareIcon from '../../../core/components/icons/AlertSquareIcon';
import type { AlertBlockComponent } from '../../types/customStoryblokComponents';
import StoryblokRender from '../StoryblokRender';
import styles from './AlertBlock.module.scss';

const AlertBlock = ({ text }: AlertBlockComponent) => {
    return (
        <div className={styles.container}>
            <div className={styles.iconBlock}>
                <AlertSquareIcon color="var(--primary-color-1)" />
            </div>
            <div className={styles.textBlock}>
                <StoryblokRender components={text} />
            </div>
        </div>
    );
};

export default memo(AlertBlock);
