import { memo, useContext } from 'react';
import { clsx } from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Text, TextBlock, VStack } from '@library';
import PreorderModeContext from '../../../../preorder/PreorderModeContext';
import useActivePreorderSeasons from '../../../../preorder/queries/useActivePreorderSeasons';
import CurrentStoreLink from '../../../../core/components/router/CurrentStoreLink';
import styles from './MenuSidebar.module.css';

const PreorderItems = memo(
    ({
        onClose,
        isRouteActive,
    }: {
        isRouteActive?: (path: string) => boolean;
        onClose?: () => void;
    }) => {
        const { isInPreorderMode } = useContext(PreorderModeContext);
        const { activePreorderSeasons, loading } = useActivePreorderSeasons();

        if (loading || activePreorderSeasons.length === 0) {
            return null;
        }

        const baseRoute = isInPreorderMode ? '../../' : '';

        return (
            <div className={styles.section}>
                <VStack gap="gap24">
                    <TextBlock primary medium>
                        <FormattedMessage id="Header Menu Preorder" />
                    </TextBlock>
                    {activePreorderSeasons.map(item => {
                        return (
                            <PreorderItem
                                key={`preorder_${item.id}`}
                                name={item.preorderName}
                                to={`${baseRoute}preorder/${item.id}`}
                                onClick={onClose}
                                active={isRouteActive?.(`preorder/${item.id}`)}
                            />
                        );
                    })}
                </VStack>
            </div>
        );
    }
);

interface PreorderItemProps {
    name: string;
    active?: boolean;
    to: string;
    onClick?: () => void;
}

const PreorderItem = memo(
    ({ active, to, onClick, name }: PreorderItemProps) => {
        return (
            <CurrentStoreLink
                to={`/${to}`}
                className={clsx(styles.preorderItem, {
                    [styles.preorderItemActive]: active,
                })}
                data-test-class="header-link"
                onClick={onClick}
            >
                <Text menuButton>{name}</Text>
            </CurrentStoreLink>
        );
    }
);

export default PreorderItems;
