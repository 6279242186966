import { gql } from '@apollo/client';
import { useContext } from 'react';
import type { SmallOrderSurcharge } from '../../checkout/types';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import useCurrentStore from '../../core/hooks/useCurrentStore';
import { CartContext } from '../../core/cartContext';
import useLoginState from '../../core/hooks/useLoginState';
import usePolledQuery from '../../core/hooks/apollo/usePolledQuery';
import type { PriceResult } from '../../pricing/type';
import handlePriceError from '../../pricing/util/handlePriceError';

export const quantitySurcharge = gql`
    query quantitySurcharge($language: Language!, $store: ID!, $cartId: Int!) {
        checkout(language: $language, store: $store, cartId: $cartId) {
            checkoutPriceSummary {
                result {
                    smallOrderSurcharge {
                        price
                        vatAmount
                    }
                }
                errors {
                    code
                    sku
                }
            }
        }
    }
`;

const useQuantitySurcharge = (skip?: boolean) => {
    const isLoggedIn = useLoginState();
    const { cart } = useContext(CartContext);
    const store = useCurrentStore();
    const { language } = useStoreAndLanguageParams();

    const { data, loading, error } = usePolledQuery<{
        checkout: {
            checkoutPriceSummary: PriceResult<{
                smallOrderSurcharge: SmallOrderSurcharge | null;
            }>;
        };
    }>(quantitySurcharge, {
        variables: {
            store: store.id,
            language,
            cartId: cart.id,
        },
        skip: !isLoggedIn || !cart.id || skip,
        fetchPolicy: 'cache-and-network',
        nextFetchPolicy: 'cache-first',
        pollInterval: 180 * 60_000,
    });

    handlePriceError(data?.checkout.checkoutPriceSummary);

    return {
        smallOrderSurcharge:
            data?.checkout.checkoutPriceSummary.result?.smallOrderSurcharge
                ?.price,
        loading,
        error,
    };
};

export default useQuantitySurcharge;
