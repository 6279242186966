import { memo } from 'react';
import { clsx } from 'clsx';
import type { InputHTMLAttributes, ReactNode } from 'react';
import CheckmarkIcon from '../../../../core/components/icons/CheckmarkIcon';
import { TextBlock } from '../../typography';
import styles from './Checkbox.module.scss';

interface CheckboxProps
    extends Pick<
        InputHTMLAttributes<HTMLInputElement>,
        'name' | 'value' | 'disabled' | 'onBlur' | 'onFocus' | 'required'
    > {
    /**
     * ID for checkbox input
     */
    id?: string;
    /**
     * The state of the checkbox.
     */
    checked?: boolean;
    /**
     * Checkbox is indeterminate.
     */
    indeterminate?: boolean;
    /**
     * The label of the checkbox.
     */
    children?: ReactNode;
    /**
     * Apply styles for one line text tha can be truncated
     */
    truncate?: boolean;
    /**
     * Callback for onChange event handler.
     */
    onChange?: (value: boolean) => void;
}

/**
 * The checkbox component.
 */
const Checkbox = ({
    id,
    checked,
    indeterminate,
    children,
    truncate,
    onChange,
    ...others
}: CheckboxProps) => {
    return (
        <label
            className={clsx(styles.container, {
                [styles.checked]: checked,
                [styles.indeterminate]: indeterminate && !checked,
            })}
        >
            <input
                id={id}
                className={styles.input}
                type="checkbox"
                checked={checked}
                onChange={event => {
                    onChange?.(event.target.checked);
                }}
                {...others}
            />
            <span
                className={clsx(styles.checkbox, {
                    [styles.checkboxWithContent]: !!children,
                })}
            >
                <span className={styles.checkboxMark}>
                    <CheckmarkIcon />
                </span>
                <span className={styles.indeterminateMark} />
            </span>

            {children && (
                <TextBlock primary truncate={truncate}>
                    {children}
                </TextBlock>
            )}
        </label>
    );
};

export default memo(Checkbox);
