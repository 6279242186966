import { memo, useContext } from 'react';
import { Link } from 'react-router-dom';
import { Text } from '@library';
import withErrorBoundary from '../../core/components/errors/withErrorBoundary';
import PreorderModeContext from '../../preorder/PreorderModeContext';
import useActivePreorderSeasons from '../../preorder/queries/useActivePreorderSeasons';

import styles from './AccountDropdownMenu.module.scss';

const AccountMenuPreorderItems = ({ onCloseMenu }: { onCloseMenu(): void }) => {
    const { isInPreorderMode } = useContext(PreorderModeContext);
    const { activePreorderSeasons, loading } = useActivePreorderSeasons();

    if (loading || activePreorderSeasons.length === 0) {
        return null;
    }

    const baseRoute = isInPreorderMode ? '../../' : '../';

    return (
        <>
            {activePreorderSeasons.map(item => (
                <Link
                    key={item.id}
                    to={`${baseRoute}preorder/${item.id}`}
                    onClick={onCloseMenu}
                    className={styles.menuItem}
                    data-test-class="accountMenuDropdownItem"
                    data-test-state="preorder"
                >
                    <Text primary>{item.preorderName}</Text>
                </Link>
            ))}
        </>
    );
};

export default memo(withErrorBoundary(AccountMenuPreorderItems));
