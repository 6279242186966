import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

export default memo((props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 26 26" fill="none" {...props}>
            <path
                d="M9 5.99999V17M17 8.99999V20M17.6707 24.664L24.1707 21.4147C24.6787 21.1613 25 20.6413 25 20.0733V3.42665C25 2.31199 23.8267 1.58665 22.8293 2.08532L17.6707 4.66399C17.248 4.87599 16.7507 4.87599 16.3293 4.66399L9.67067 1.33599C9.46242 1.23189 9.23281 1.1777 9 1.1777C8.76719 1.1777 8.53758 1.23189 8.32933 1.33599L1.82933 4.58532C1.32 4.83999 1 5.35999 1 5.92665V22.5733C1 23.688 2.17333 24.4133 3.17067 23.9147L8.32933 21.336C8.752 21.124 9.24933 21.124 9.67067 21.336L16.3293 24.6653C16.752 24.876 17.2493 24.876 17.6707 24.6653V24.664Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </BaseIcon>
    );
});
