import { memo } from 'react';
import type {
    CustomStoryblokComponents,
    GridComponent,
} from '../../../types/customStoryblokComponents';
import RenderComponents from '../../RenderComponents';
import { HStack } from '../../../../library';
import StoryblokGridColumn from './StoryblokGridColumn';
import StoryblokGridLayout from './StoryblokGridLayout';

const StoryblokGrid = ({
    space,
    align,
    columns,
    wrap,
    justify,
    columns_template,
    custom_columns_template,
}: GridComponent) => {
    if (columns_template || custom_columns_template) {
        return (
            <StoryblokGridLayout
                justify={justify}
                align={align}
                columnsTemplate={custom_columns_template || columns_template}
                gap={space}
            >
                <RenderComponents components={columns} />
            </StoryblokGridLayout>
        );
    }

    return (
        <HStack
            gap={space}
            justify={justify === 'normal' ? undefined : justify}
            align={align == 'normal' ? undefined : align}
            wrap={wrap === 'wrap'}
        >
            {columns.map(item => {
                if (
                    (item as CustomStoryblokComponents).component ===
                    'grid_column'
                ) {
                    return (
                        // eslint-disable-next-line no-underscore-dangle
                        <RenderComponents components={item} key={item._uid} />
                    );
                }

                return (
                    <StoryblokGridColumn
                        // eslint-disable-next-line no-underscore-dangle
                        key={item._uid}
                        body={[item]}
                        space="small"
                        width={justify === 'normal' ? 1 : undefined}
                    />
                );
            })}
        </HStack>
    );
};

export default memo(StoryblokGrid);
