import { gql, useMutation } from '@apollo/client';

const sendContactMessageMutation = gql`
    mutation sendContactMessageMutation(
        $customerNumber: String
        $emailAddress: String!
        $language: String!
        $message: String!
        $name: String!
        $store: String!
        $subject: String!
        $url: String!
        $username: String
    ) {
        sendContactMessage(
            customerNumber: $customerNumber
            emailAddress: $emailAddress
            language: $language
            message: $message
            name: $name
            store: $store
            subject: $subject
            url: $url
            username: $username
        ) {
            success
        }
    }
`;

const useSendContactMessage = () => {
    return useMutation<
        { sendContactMessage: { success: boolean } },
        {
            customerNumber?: string;
            emailAddress: string;
            language: string;
            message: string;
            name: string;
            store: string;
            subject: string;
            url: string;
            username?: string;
        }
    >(sendContactMessageMutation);
};

export default useSendContactMessage;
