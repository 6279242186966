import { memo } from 'react';

import RestrictedContent from '../../../../core/components/router/RestrictedContent';
import LoginMenu from './LoginMenu/LoginMenu';
import UserMenuLoggedIn from './UserMenuLoggedIn';

export default memo(() => {
    return (
        <>
            <RestrictedContent loggedOutOnly>
                <LoginMenu />
            </RestrictedContent>

            <RestrictedContent loggedInOnly>
                <UserMenuLoggedIn />
            </RestrictedContent>
        </>
    );
});
