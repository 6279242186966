import type { ReactNode } from 'react';
import type { LinkMark } from '../../../types/storyblokComponents';
import StoryblokLink from './StoryblokLink';
import styles from './StoryblokLinkMark.module.scss';

const StoryblokLinkMark = ({
    marks,
    children,
    className,
}: {
    marks?: LinkMark[] | unknown[];
    children: ReactNode;
    className?: string;
}) => {
    const linkMark =
        marks &&
        (marks.find(
            mark => (mark as { type: string }).type === 'link'
        ) as LinkMark);

    return linkMark ? (
        <StoryblokLink
            showEmpty
            className={className || styles.link}
            link={{
                id: linkMark.attrs.uuid,
                cached_url: linkMark.attrs.href,
                linktype: linkMark.attrs.linktype,
                url: linkMark.attrs.href,
                target: linkMark.attrs.target,
                email: linkMark.attrs.href,
            }}
        >
            {children}
        </StoryblokLink>
    ) : (
        <>{children}</>
    );
};

export default StoryblokLinkMark;
