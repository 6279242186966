import { gql, useQuery } from '@apollo/client';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import type { PostStory } from '../../content/types/types';
import Storyblok from '../../content/storyblok/storyblok';
import useContentClient from '../../content/hooks/useContentClient';
import getPublishedDate from '../utils/getPublishedDate';

const postsQuery = gql`
    query postsQuery(
        $per_page: Int!
        $page: Int
        $sort_by: String!
        $start_with: String!
        $language: String
        $filter: PostFilterQuery
        $first_published_at_lt: String
    ) {
        PostItems(
            page: $page
            per_page: $per_page
            sort_by: $sort_by
            starts_with: $start_with
            filter_query_v2: $filter
            language: $language
            first_published_at_lt: $first_published_at_lt
        ) {
            items {
                content {
                    attachments
                    body
                    component
                    date
                    category(language: $language) {
                        content
                        uuid
                    }
                    featured_image {
                        alt
                        filename
                        focus
                        title
                    }
                    preview_image {
                        alt
                        filename
                        focus
                        title
                    }
                    summary
                    title
                    meta_fields
                }
                name
                slug
                id
                lang
                first_published_at
            }
            total
        }
    }
`;

const usePostPages = ({
    perPage,
    page = 0,
    categories,
}: {
    perPage: number;
    page?: number;
    categories?: string[];
}) => {
    const { store, language } = useStoreAndLanguageParams();

    const defaultVariables = {
        language,
        sort_by: 'first_published_at:desc',
        start_with: Storyblok.formatSlug(store, language, 'news/'),
    };

    const client = useContentClient();

    const {
        data,
        loading,
        fetchMore: fetchMoreApi,
        error,
    } = useQuery<
        {
            PostItems: {
                items: PostStory[];
                total: number;
            };
        },
        {
            language: string;
            page: number;
            per_page: number;
            sort_by: string;
            start_with: string;
            filter: {
                category?: {
                    in?: string;
                };
            };
            first_published_at_lt: string;
        }
    >(postsQuery, {
        variables: {
            page,
            per_page: perPage,
            filter: {
                category: categories
                    ? {
                          in: categories.join(',') || undefined,
                      }
                    : undefined,
            },
            first_published_at_lt: getPublishedDate(),
            ...defaultVariables,
        },
        client,
    });

    const fetchMore = async ({
        page: pageFetchMore = 0,
        perPage: perPageFetchMore,
    }: {
        page?: number;
        perPage: number;
    }) => {
        return fetchMoreApi({
            variables: {
                page: pageFetchMore,
                per_page: perPageFetchMore,
            },
        });
    };

    return {
        data: data?.PostItems,
        loading,
        fetchMore,
        error,
    };
};

export default usePostPages;
