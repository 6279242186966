const toComparable = (value: unknown) => {
    return JSON.stringify(value === undefined ? null : value);
};

const plainObjectsEqual = (
    a: unknown,
    b: unknown,
    excludeKeys: string[] = []
) => {
    const aObj = a as { [key: string]: unknown };
    const bObj = b as { [key: string]: unknown };
    const allKeys = new Set([...Object.keys(aObj), ...Object.keys(bObj)]);

    for (const excludedKey of excludeKeys) {
        allKeys.delete(excludedKey);
    }

    for (const key of allKeys) {
        if (
            aObj[key] !== bObj[key] &&
            toComparable(aObj[key]) !== toComparable(bObj[key])
        ) {
            return false;
        }
    }

    return true;
};

export default plainObjectsEqual;
