import { memo, useContext, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, HStack, MenuIcon, Text } from '@library';

import { AppContext } from '../../../../core/context';
import MenuSidePane from './MenuSidePane';
import CategoriesSidePane from './CategoriesSidePane';

export default memo(({ openCategories }: { openCategories?: () => void }) => {
    const { isInTabletView, isInMobileView } = useContext(AppContext);
    const isDesktop = !isInTabletView && !isInMobileView;
    const [isOpen, setIsOpen] = useState(false);
    const [isOpenCategories, setIsOpenCategories] = useState(false);

    const handleOpenCategories = () => {
        if (isDesktop) {
            return openCategories?.();
        }
        setIsOpenCategories(true);
    };

    return (
        <>
            <Button inline text white onClick={() => setIsOpen(true)}>
                <HStack gap="mini">
                    <MenuIcon />
                    <Text hoverBold color="white">
                        <FormattedMessage id="Header Menu" />
                    </Text>
                </HStack>
            </Button>
            {isOpen && !isOpenCategories && (
                <MenuSidePane
                    openCategories={handleOpenCategories}
                    isOpen={isOpen}
                    setIsOpen={setIsOpen}
                />
            )}
            {isOpenCategories && (
                <CategoriesSidePane
                    onClose={() => {
                        setIsOpenCategories(false);
                        setIsOpen(false);
                    }}
                    goToMenu={() => {
                        setIsOpenCategories(false);
                        setIsOpen(true);
                    }}
                />
            )}
        </>
    );
});
