import { isString } from 'lodash-es';
import type {
    PredefinedRules,
    PredefinedRulesWithParams,
    CustomValidation,
    ValidationRules,
    LengthValidationRule,
    ValidationSchema,
    FormErrors,
} from '../hooks/form/types';
import notEmpty from './notEmpty';
import { validateEmail } from './validators';

type Entries<T> = [keyof T, NonNullable<T[keyof T]>][];

type ValidationRulesMap = {
    [key in PredefinedRules]: <T>(key: keyof T, values: T) => string | null;
} & {
    [key in PredefinedRulesWithParams['type']]: <T>(
        key: keyof T,
        values: T,
        validation: Extract<PredefinedRulesWithParams, { type: key }>
    ) => string | null;
} & {
    custom: <T>(
        key: keyof T,
        values: T,
        validation: CustomValidation<T>
    ) => string | null;
};

const validationRulesMap: ValidationRulesMap = {
    email: (key, values) => {
        const value = values[key];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        const valid = value && isString(value) ? validateEmail(value) : false;

        return !valid ? 'must be valid email' : null;
    },
    required: (key, values) => {
        const valid = values[key];
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        return !valid ? 'this field is required' : null;
    },
    length: (key, values, validation) => {
        const value = values[key];

        if (isString(value) || Array.isArray(value)) {
            const isValidMax =
                !validation.max || value.length <= validation.max;

            return !isValidMax
                ? `this field should not exceed ${validation.max} characters`
                : null;
        }

        if (notEmpty(value)) {
            throw new Error(
                `invalid type of field ${String(key)} for length rule`
            );
        }

        return null;
    },
    custom: (key, values, validation) => {
        return validation(values);
    },
};

function isPredefinedTypeWithParams<T>(
    rule: ValidationRules<T>
): rule is PredefinedRulesWithParams {
    return (rule as { type: string | undefined }).type !== undefined;
}

const validateRule = <T>(
    key: keyof T,
    validation: ValidationRules<T>,
    values: T
) => {
    if (typeof validation === 'function') {
        return validationRulesMap.custom(key, values, validation);
    }

    const type = isPredefinedTypeWithParams(validation)
        ? validation.type
        : validation;

    switch (type) {
        case 'email': {
            return validationRulesMap[type](key, values);
        }
        case 'required': {
            return validationRulesMap[type](key, values);
        }
        case 'length': {
            return validationRulesMap[type](
                key,
                values,
                validation as LengthValidationRule
            );
        }
        default: {
            return 'wrong validation type';
        }
    }
};

const validate = <T>(values: T, validationSchema: ValidationSchema<T>) => {
    const errors: FormErrors<T> = (
        Object.entries(validationSchema) as Entries<ValidationSchema<T>>
    )
        .map(([key, validation]) => {
            if (Array.isArray(validation)) {
                const validationErrors = validation
                    .map(rule => validateRule(key, rule, values))
                    .filter(value => value);

                return [
                    key,
                    validationErrors.length ? validationErrors[0] : null,
                ] as [keyof T, string | null];
            } else {
                return [key, validateRule(key, validation, values)] as [
                    keyof T,
                    string | null,
                ];
            }
        })
        .reduce((result, [key, value]) => {
            return { ...result, ...{ [key]: value } };
        }, {} as FormErrors<T>);

    return { errors, valid: !Object.values(errors).filter(notEmpty).length };
};

export default validate;
