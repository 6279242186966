import { memo, useState } from 'react';
import { useIntl } from 'react-intl';
import { CloseIcon, SearchIcon } from '@library';
import TextInput from '../../../../core/components/input/TextInput';
import Button from '../../../../core/components/Button';
import styles from './SearchField.module.css';

const SearchField = memo(() => {
    const intl = useIntl();

    const [value, setValue] = useState('');

    const clearSearch = () => {
        setValue('');
    };

    return (
        <div className={styles.search}>
            <SearchIcon size={20} />
            <input style={{ display: 'none' }} autoComplete="off" />
            <TextInput
                hasEndAdornment
                noGreyBorder
                noActiveBorder
                noHoverBorder
                noFilledBorder
                value={value}
                material={false}
                onChange={e => setValue(e.target.value)}
                endAdornment={
                    value.length > 1 && (
                        <Button iconButton onPress={clearSearch}>
                            <CloseIcon />
                        </Button>
                    )
                }
                placeholder={intl.formatMessage({ id: 'find products' })}
                id="search"
                aria-label="search"
                data-test-class="searchPreviewField"
            />
        </div>
    );
});

export default SearchField;
