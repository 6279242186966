import { useParams } from 'react-router-dom';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import useStoreAndLanguageParams from '../hooks/useStoreAndLanguageParams';
import type { CategoryWithFilters } from '../types/category';
import notEmpty from '../util/notEmpty';

const useCategory = (id?: string) => {
    const { language } = useStoreAndLanguageParams();
    const { categoryId } = useParams<{
        categoryId?: string;
    }>();

    const skip = !id && !categoryId;

    const {
        data,
        loading: categoryLoading,
        error,
    } = useQuery<{
        category: CategoryWithFilters;
    }>(
        gql`
            query category($id: ID!, $language: Language!) {
                category(id: $id, language: $language) {
                    parentId
                    key
                    id
                    name
                    hiddenInTree
                    filters {
                        id
                        field
                    }
                    path {
                        key
                        id
                        name
                        hiddenInTree
                    }
                }
            }
        `,
        {
            variables: {
                id: id || categoryId,
                language,
            },
            skip,
            fetchPolicy: 'cache-first',
        }
    );

    if (error) {
        throw error;
    }

    if (skip) {
        return {
            categoryId: '',
            categoryAncestryIds: [] as string[],
            rootCategoryId: '',
            categoryName: '',
            categoryLoading: false,
        };
    }

    const categoryData = data?.category;
    const categoryAncestry = categoryData?.path ?? [];
    const categoryAncestryIds = categoryAncestry.map(item => item.id);
    const rootCategoryId = categoryAncestryIds.slice().reverse().pop() ?? '';
    const categoryName = categoryData?.name;

    return {
        categoryId: id || categoryId,
        categoryData,
        categoryAncestryIds,
        rootCategoryId,
        categoryName,
        categoryLoading,
        notFound: !categoryLoading && !notEmpty(categoryData),
    };
};

export default useCategory;
