import type { ReactElement } from 'react';
import { memo, useContext } from 'react';
import SbEditable from 'storyblok-react';
import type { Components, ComponentsHandler, Styles } from '../types/types';
import {
    isAtomicStoryblokComponents,
    isCustomStoryblokComponents,
} from '../types/types';
import { ComponentMapperContext } from './StoryblokRender';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const EditableComponent: any = SbEditable;

type Handler = <T extends Components>(
    component: T,
    styles: Styles | undefined
) => ReactElement<unknown>;

const getComponentHandler = (
    component: Components,
    componentMapper: ComponentsHandler
): Handler | undefined => {
    if (isAtomicStoryblokComponents(component)) {
        return componentMapper[component.type] as Handler;
    }
    if (isCustomStoryblokComponents(component)) {
        return componentMapper[component.component] as Handler;
    }

    return undefined;
};

const RenderComponent = ({ component }: { component: Components }) => {
    const { componentMapper: currentComponentsMapper, styles } = useContext<{
        componentMapper: ComponentsHandler;
        styles?: Styles;
    }>(ComponentMapperContext);

    const unknownComponent = <div>unknown content type</div>;

    const componentHandler = getComponentHandler(
        component,
        currentComponentsMapper
    );

    if (componentHandler) {
        if (isCustomStoryblokComponents(component)) {
            return (
                <EditableComponent content={component}>
                    {componentHandler(component, styles)}
                </EditableComponent>
            );
        }
        return componentHandler(component, styles);
    }
    return unknownComponent;
};

export default memo(RenderComponent);
