import { memo } from 'react';
const DownloadArrowIcon = () => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12.733"
            height="12.126"
            viewBox="0 0 12.733 11"
        >
            <g transform="translate(-1282.476 -1447.32)">
                <g transform="translate(1284.153 1447.32)">
                    <g transform="translate(4.116)">
                        <rect
                            width="1.146"
                            height="10.883"
                            fill="currentColor"
                        />
                    </g>
                    <g transform="translate(0 5.729)">
                        <path
                            fill="currentColor"
                            d="M1285.4,1458.1l.84-.782,3.848,4.141,3.849-4.141.84.782-4.689,5.042Z"
                            transform="translate(-1285.403 -1457.314)"
                        />
                    </g>
                </g>
                <g transform="translate(1282.476 1458.3)">
                    <rect width="12.733" height="1.146" fill="currentColor" />
                </g>
            </g>
        </svg>
    );
};

export default memo(DownloadArrowIcon);
