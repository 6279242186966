import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

export default memo((props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 26 26" fill="none" {...props}>
            <path
                d="M12 12L12.0547 11.9733C12.2256 11.8879 12.4175 11.8533 12.6076 11.8735C12.7976 11.8938 12.9779 11.9681 13.127 12.0876C13.2761 12.2071 13.3879 12.3669 13.449 12.548C13.5102 12.7291 13.5181 12.9239 13.472 13.1093L12.528 16.8907C12.4815 17.0762 12.4893 17.2712 12.5503 17.4525C12.6113 17.6338 12.723 17.7938 12.8722 17.9136C13.0214 18.0333 13.2018 18.1077 13.392 18.128C13.5822 18.1483 13.7742 18.1136 13.9453 18.028L14 18M25 13C25 14.5759 24.6896 16.1363 24.0866 17.5922C23.4835 19.0481 22.5996 20.371 21.4853 21.4853C20.371 22.5996 19.0481 23.4835 17.5922 24.0866C16.1363 24.6896 14.5759 25 13 25C11.4241 25 9.86371 24.6896 8.4078 24.0866C6.95189 23.4835 5.62902 22.5996 4.51472 21.4853C3.40042 20.371 2.5165 19.0481 1.91345 17.5922C1.31039 16.1363 1 14.5759 1 13C1 9.8174 2.26428 6.76516 4.51472 4.51472C6.76516 2.26428 9.8174 1 13 1C16.1826 1 19.2348 2.26428 21.4853 4.51472C23.7357 6.76516 25 9.8174 25 13ZM13 8H13.0107V8.01067H13V8Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </BaseIcon>
    );
});
