import type { DocumentNode, OperationVariables } from '@apollo/client';
import { useQuery } from '@apollo/client';
import type { QueryHookOptions } from '@apollo/client/react/types/types';
import captureException from '../../core/util/captureException';
import { STORYBLOK_ERROR_MESSAGE } from '../constants';

export default function useQueryContent<
    TData,
    TVariables extends OperationVariables = OperationVariables,
>(query: DocumentNode, options: QueryHookOptions<TData, TVariables> = {}) {
    return useQuery<TData, TVariables>(query, {
        ...options,
        onError: error => {
            captureException(
                new Error(STORYBLOK_ERROR_MESSAGE, { cause: error })
            );
        },
    });
}
