import { memo, useState } from 'react';
import type { ReactNode } from 'react';
import ContactFormModalWithSuccess from '../../../../user/components/ContactFormModal/ContactFormModalWithSuccess';

const ContactUsLink = ({
    children,
    className,
}: {
    children: ReactNode;
    className?: string;
}) => {
    const [showContactForm, setShowContactFrom] = useState(false);

    return (
        <>
            <span
                role="button"
                tabIndex={0}
                className={className}
                onKeyPress={(e: React.KeyboardEvent<HTMLDivElement>) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                        setShowContactFrom(true);
                    }
                }}
                onClick={() => {
                    setShowContactFrom(true);
                }}
            >
                {children}
            </span>
            {showContactForm && (
                <ContactFormModalWithSuccess
                    handleClose={() => {
                        setShowContactFrom(false);
                    }}
                />
            )}
        </>
    );
};

export default memo(ContactUsLink);
