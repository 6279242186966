import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { ResponsiveModal, VStack, ConfirmationMessage } from '@library';

const ContactMessageFormConfirmation = ({
    open,
    onClose,
    isEmailSendingFailure = false,
}: {
    open: boolean;
    onClose: () => void;
    isEmailSendingFailure?: boolean;
}) => {
    return (
        <ResponsiveModal open={open} onOpenChange={onClose}>
            <VStack justify="center" align="center" gap="small">
                <ConfirmationMessage
                    title={
                        <FormattedMessage
                            id={
                                isEmailSendingFailure
                                    ? 'you message could not be sent'
                                    : 'your message was sent'
                            }
                        />
                    }
                    type={isEmailSendingFailure ? 'error' : 'success'}
                    onClose={onClose}
                />
            </VStack>
        </ResponsiveModal>
    );
};

export default memo(ContactMessageFormConfirmation);
