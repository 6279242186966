import { memo, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { FormattedMessage } from 'react-intl';
import {
    Button,
    ChevronDownIcon,
    HStack,
    Text,
    TooltipStateLess,
} from '@library';
import RestrictedContent from '../../../../../core/components/router/RestrictedContent';
import useCustomerPriceVisibility from '../../../../../core/hooks/useCustomerPriceVisibility';
import useOutsideClick from '../../../../../core/hooks/dom/useOutsideClick';
import RadioButton from '../../../RadioButton';
import Switch from '../../../../../core/components/input/Switch';
import styles from './DealerPriceDropdown.module.scss';

enum OnOff {
    on = 'ON',
    off = 'OFF',
}

const DealerPriceDropdown = memo(() => {
    const [showCustomerPrice, setShowCustomerPrice] =
        useCustomerPriceVisibility();

    const containerRef = useRef<HTMLDivElement>(null);

    const [isOpen, setIsOpen] = useState(false);
    useOutsideClick(containerRef, () => {
        setIsOpen(false);
    });

    const items = [
        { value: true, label: OnOff.on, selected: showCustomerPrice },
        { value: false, label: OnOff.off, selected: !showCustomerPrice },
    ];

    return (
        <RestrictedContent permissions="dealerPricesEnabled">
            <TooltipStateLess
                open={isOpen}
                onOpenChange={value => {
                    setIsOpen(value);
                }}
                mode="click"
                theme={styles.tooltipTheme}
                placement={'bottom-end'}
                content={
                    <div className={styles.container}>
                        <div className={styles.wrapper}>
                            {items.map(({ value, label, selected }) => {
                                return (
                                    <div key={label} className={styles.item}>
                                        <Button
                                            text
                                            secondary
                                            fullWidth
                                            theme={styles.buttonTheme}
                                            onClick={() => {
                                                setShowCustomerPrice(value);
                                                setIsOpen(false);
                                            }}
                                            justify="start"
                                            data-test-state={label}
                                            data-test-class={label}
                                        >
                                            <HStack gap="small" align="center">
                                                <RadioButton
                                                    selected={selected}
                                                />
                                                <HStack
                                                    gap="micro"
                                                    justify="start"
                                                >
                                                    <Text
                                                        title={label}
                                                        bold={selected}
                                                    >
                                                        {label}
                                                    </Text>
                                                </HStack>
                                            </HStack>
                                        </Button>
                                    </div>
                                );
                            })}
                            <div className={styles.item}>
                                <Button
                                    text
                                    white
                                    theme={styles.buttonTheme}
                                    onClick={() => setIsOpen(false)}
                                >
                                    <Text color="info" medium primary>
                                        <FormattedMessage id="close" />
                                    </Text>
                                </Button>
                            </div>
                        </div>
                    </div>
                }
            >
                <Button text inline white maxContent>
                    <HStack align="center" gap="mini">
                        <Text uppercase secondary hoverBold data-text={name}>
                            <FormattedMessage id="customer price" />
                            &nbsp;{showCustomerPrice ? OnOff.on : OnOff.off}
                        </Text>
                        <div
                            className={clsx(styles.triggerIcon, {
                                [styles.opened]: isOpen,
                            })}
                        >
                            <ChevronDownIcon />
                        </div>
                    </HStack>
                </Button>
            </TooltipStateLess>
        </RestrictedContent>
    );
});

export default DealerPriceDropdown;

export const DealerPriceSwitch = memo(() => {
    const [showCustomerPrice, setShowCustomerPrice] =
        useCustomerPriceVisibility();
    return (
        <RestrictedContent permissions="dealerPricesEnabled">
            <Switch
                large
                data-test-class="customerPriceToggle"
                name="showCustomerPrice"
                value={showCustomerPrice}
                onChange={setShowCustomerPrice}
            >
                <Text primary uppercase lineHeightAsFontSize>
                    <HStack gap="gap4" align="center">
                        <FormattedMessage id="customer price" />
                        <div className={styles.indicator}>
                            {showCustomerPrice ? OnOff.on : OnOff.off}
                        </div>
                    </HStack>
                </Text>
            </Switch>
        </RestrictedContent>
    );
});
