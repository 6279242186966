import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { Heading, Text } from '@library';
import type { InfoTileComponent } from '../../types/customStoryblokComponents';
import TopRightArrowIcon from '../../../core/components/icons/TopRightArrowIcon';
import DownloadArrowIcon from '../../../core/components/icons/DownloadArrowIcon';
import notEmpty from '../../../core/util/notEmpty';
import StoryblokLink from './Links/StoryblokLink';
import StoryblokResponsiveImage from './StoryblokResponsiveImage';
import styles from './InfoTile.module.scss';

const InfoTile = ({
    title,
    link,
    file,
    files = [],
    images,
    height,
}: InfoTileComponent) => {
    const filesForRender = [file, ...files]
        .filter(notEmpty)
        .filter(fileForRender => !!fileForRender.filename);

    return (
        <div className={styles.tile} style={{ height: height || 'auto' }}>
            <div className={styles.images}>
                {images &&
                    images.map(image => {
                        return (
                            <StoryblokResponsiveImage
                                className={styles.image}
                                image={image}
                                key={image.filename}
                                srcSets={[
                                    {
                                        width: 0,
                                        height: 26,
                                        screenWidth: '1x',
                                    },
                                    {
                                        width: 0,
                                        height: 52,
                                        screenWidth: '2x',
                                    },
                                ]}
                            />
                        );
                    })}
            </div>

            <div className={styles.header}>
                <Heading h5 regular>
                    {title}
                </Heading>
            </div>

            <div className={styles.linkBlock}>
                {link && (
                    <StoryblokLink link={link} className={styles.link}>
                        <div className={styles.openSiteIcon}>
                            <TopRightArrowIcon />
                        </div>

                        <Text tertiary uppercase>
                            <FormattedMessage id="open page" />
                        </Text>
                    </StoryblokLink>
                )}

                {filesForRender.map(fileForRender => {
                    const fileExtension =
                        fileForRender.ext && fileForRender.ext.length
                            ? fileForRender.ext
                            : file?.filename?.split('.').pop()?.toUpperCase();
                    return (
                        <a
                            key={fileForRender.filename}
                            className={styles.link}
                            href={fileForRender.filename}
                            target="_blank"
                            rel="noreferrer"
                            download={fileForRender.filename}
                        >
                            <div className={styles.downloadIcon}>
                                <DownloadArrowIcon />
                            </div>

                            <Text tertiary uppercase>
                                <FormattedMessage
                                    id="download {ext}"
                                    values={{ ext: fileExtension }}
                                />
                            </Text>
                        </a>
                    );
                })}
            </div>
        </div>
    );
};
export default memo(InfoTile);
