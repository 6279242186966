import { memo } from 'react';

export default memo(() => {
    return (
        <svg
            width="28"
            height="26"
            viewBox="0 0 28 26"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M25 8.348C26.1787 8.72667 27 9.852 27 11.144V16.8587C27 18.3733 25.8707 19.6587 24.36 19.7827C23.9067 19.8187 23.4533 19.852 23 19.8787V24L19 20C17.1947 20 15.408 19.9267 13.64 19.7827C13.2551 19.7516 12.8807 19.6417 12.54 19.46M25 8.348C24.794 8.28169 24.581 8.23919 24.3653 8.22134C20.7946 7.92491 17.2054 7.92491 13.6347 8.22134C12.1267 8.34667 11 9.63067 11 11.144V16.8587C11 17.9747 11.6133 18.9653 12.54 19.46M25 8.348V5.84934C25 3.688 23.464 1.81467 21.32 1.536C18.5611 1.17839 15.782 0.999351 13 1C10.18 1 7.40267 1.18267 4.68 1.536C2.536 1.81467 1 3.688 1 5.84934V14.1507C1 16.312 2.536 18.1853 4.68 18.464C5.44933 18.564 6.22267 18.6507 7 18.7227V25L12.54 19.46"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </svg>
    );
});
