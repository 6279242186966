import { memo } from 'react';
import type { IconProps } from './types';

const TopRightArrowIcon = ({
    iconLabel,
    mini,
    color,
}: IconProps & { mini?: boolean }) => {
    return (
        <svg
            width={mini ? '12' : '17.407'}
            height={mini ? '12' : '17.405'}
            viewBox="0 0 17.407 17.405"
            data-icon={iconLabel}
            color={color}
        >
            <g transform="translate(6.634 22.853) rotate(-135)">
                <g transform="translate(1.678)">
                    <g transform="translate(6.025)">
                        <rect
                            fill="currentColor"
                            width="1.678"
                            height="15.931"
                        />
                    </g>
                    <g transform="translate(0 8.387)">
                        <path
                            fill="currentColor"
                            d="M1285.4,1458.459l1.229-1.145,5.633,6.062,5.635-6.062,1.229,1.145-6.864,7.382Z"
                            transform="translate(-1285.403 -1457.314)"
                        />
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default memo(TopRightArrowIcon);
