import { memo } from 'react';
import { Paragraph } from '@library';
import useCurrentStore from '../../../core/hooks/useCurrentStore';
import styles from './ContactInfo.module.scss';

const ContactInfo = () => {
    const { companyInfo } = useCurrentStore();

    return (
        <div>
            <Paragraph primary>{companyInfo.address1}</Paragraph>
            <Paragraph primary>{companyInfo.address2}</Paragraph>
            <Paragraph primary>{companyInfo.address3}</Paragraph>
            <Paragraph primary>{companyInfo.phone}</Paragraph>
            <Paragraph primary medium>
                <a
                    href={`mailto:${companyInfo.email}`}
                    className={styles.email}
                >
                    {companyInfo.email}
                </a>
            </Paragraph>
        </div>
    );
};

export default memo(ContactInfo);
