import { DateTime } from 'luxon';

const getPublishedDate = () => {
    const now = DateTime.utc();
    const roundedToFiveMinutes = now
        .startOf('minute')
        .minus({ minutes: now.minute % 5 });

    return roundedToFiveMinutes.toISO();
};

export default getPublishedDate;
