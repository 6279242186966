import { memo } from 'react';

export default memo(() => {
    return (
        <svg
            width="26"
            height="22"
            viewBox="0 0 26 22"
            fill="currentColor"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="20" height="2" rx="1" fill="white" />
            <rect y="10" width="26" height="2" rx="1" fill="white" />
            <rect y="20" width="13" height="2" rx="1" fill="white" />
        </svg>
    );
});
