import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

export default memo((props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 26 26" fill="none" {...props}>
            <path
                d="M3 13C3 15.6522 4.05357 18.1957 5.92893 20.0711C7.8043 21.9464 10.3478 23 13 23C15.6522 23 18.1957 21.9464 20.0711 20.0711C21.9464 18.1957 23 15.6522 23 13M3 13C3 10.3478 4.05357 7.80431 5.92893 5.92894C7.8043 4.05358 10.3478 3.00001 13 3.00001C15.6522 3.00001 18.1957 4.05358 20.0711 5.92894C21.9464 7.80431 23 10.3478 23 13M3 13H1M23 13H25M23 13H13L7 2.60668M1.724 17.1027L3.604 16.4187M22.3973 9.57868L24.2773 8.89468M3.808 20.7133L5.34133 19.428M20.6613 6.57201L22.1933 5.28668M7.00133 23.3933L8.00133 21.66L13.0027 13M18.0013 4.34001L19.0013 2.60668M10.9173 24.8173L11.264 22.848M14.7373 3.15201L15.084 1.18268M15.084 24.8173L14.7373 22.848M11.264 3.15201L10.9173 1.18268M19 23.392L18 21.66M22.192 20.7133L20.66 19.428M5.34133 6.57068L3.808 5.28535M24.2773 17.104L22.3973 16.42M3.60533 9.58001L1.72533 8.89468"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </BaseIcon>
    );
});
