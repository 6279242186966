import { memo } from 'react';
import type { AriaAttributes, CSSProperties, ReactNode } from 'react';
import { clsx } from 'clsx';
import inputStyles from './TextInput.module.css';
import styles from './TextBox.module.css';
import FormField from './FormField';

export interface TextAreaInputProps
    extends AriaAttributes,
        React.HTMLProps<HTMLTextAreaElement> {
    id?: string;
    value: string;
    infoText?: ReactNode;
    description?: ReactNode;
    placeholder?: string;
    material?: boolean;
    disabled?: boolean;
    required?: boolean;
    rows?: number;
    resizeX?: boolean;
    resizeY?: boolean;
    fullHeight?: boolean;
    lightBorder?: boolean;
    borderRadius?: boolean;
    error?: boolean | string;
    noPadding?: boolean;
    endPadding?: boolean;
    onChange(event: React.ChangeEvent<HTMLTextAreaElement>): void;
}

const TextBox = (props: TextAreaInputProps) => {
    const {
        id,
        value,
        onChange,
        onBlur,
        description,
        placeholder,
        disabled = false,
        required = false,
        error = false,
        rows = 2,
        infoText,
        resizeX = false,
        resizeY = false,
        material = true,
        fullHeight = false,
        borderRadius = false,
        lightBorder = false,
        noPadding = false,
        endPadding = false,
        ...otherProps
    } = props;
    return (
        <div
            className={clsx(inputStyles.textInputContainer, {
                [styles.fullHeight]: fullHeight,
            })}
            style={
                {
                    '--input-height': fullHeight ? '100%' : `${rows * 50}px`,
                } as CSSProperties
            }
        >
            <FormField
                id={id}
                label={description}
                hint={infoText}
                error={typeof error === 'string' ? error : ''}
            >
                <textarea
                    className={clsx(
                        styles.wrap,
                        inputStyles.input,
                        inputStyles.textAreaInput,
                        inputStyles.greyBorder,
                        inputStyles.filledBorder,
                        {
                            [inputStyles.hasLabel]: !!description,
                            [inputStyles.material]: material,
                            [inputStyles.errorInput]: error,
                            [inputStyles.filled]: !!value,
                            [styles.resizeX]: resizeX,
                            [styles.resizeY]: resizeY,
                            [styles.resizeBoth]: resizeX && resizeY,
                            [styles.lightBorder]: lightBorder,
                            [styles.hasBorderRadius]: borderRadius,
                            [inputStyles.noPadding]: noPadding,
                            [inputStyles.endPadding]: endPadding,
                        }
                    )}
                    disabled={disabled}
                    value={value}
                    onChange={onChange}
                    name={id}
                    required={required}
                    id={id}
                    onBlur={onBlur}
                    placeholder={placeholder}
                    rows={rows}
                    {...otherProps}
                />
            </FormField>
        </div>
    );
};
/**
 * @deprecated
 * Please use TextBox from library
 */
export default memo(TextBox);
