import { memo } from 'react';
import type { CSSProperties } from 'react';
import type { SpaceComponent } from '../../../types/customStoryblokComponents';
import styles from './StoryblokSpace.module.scss';

const StoryblokSpace = ({ space }: SpaceComponent) => {
    return (
        <div
            className={styles.space}
            style={
                {
                    '--height': space ? `var(--gap-${space})` : '0',
                } as CSSProperties
            }
        />
    );
};

export default memo(StoryblokSpace);
