import { memo, useState } from 'react';
import { gql, useMutation } from '@apollo/client';
import { FormattedMessage, useIntl } from 'react-intl';
import { Button, Heading, Text, TextField, VStack } from '@library';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import useCurrentStore from '../../core/hooks/useCurrentStore';
import styles from './RequestResetPasswordForm.module.scss';

interface RequestResetPasswordFormProps {
    onSuccess: () => void;
    onBackToLoginPress: () => void;
    isRequired: boolean;
    username: string;
}

const RequiresPasswordChangeMessage = memo(() => {
    return (
        <div className={styles.requiredMessage}>
            <Text secondary light>
                <FormattedMessage id="password change is required" />
            </Text>
        </div>
    );
});

const RequestResetPasswordForm = ({
    onSuccess,
    onBackToLoginPress,
    isRequired,
    username: usernameProp,
}: RequestResetPasswordFormProps) => {
    const intl = useIntl();
    const [error, setError] = useState<string | boolean>(false);
    const [username, setUsername] = useState(usernameProp);
    const { useEmailAddressLabelForUsername } = useCurrentStore();
    const [isLoading, setIsLoading] = useState(false);
    const { store } = useStoreAndLanguageParams();
    const [requestPasswordReset] = useMutation<{
        requestPasswordReset: {
            success: boolean;
        };
    }>(gql`
        mutation requestPasswordReset($username: String!, $store: ID!) {
            requestPasswordReset(username: $username, store: $store) {
                success
            }
        }
    `);

    const onResetFormSubmit = async () => {
        setIsLoading(true);
        const result = await requestPasswordReset({
            variables: {
                username,
                store,
            },
        });

        if (result.data?.requestPasswordReset.success) {
            onSuccess();
            setIsLoading(false);
            setError(false);
        } else {
            setError(
                intl.formatMessage({
                    id: 'an error occurred. please try again later',
                })
            );
            setIsLoading(false);
        }
    };

    return (
        <VStack>
            <div className={styles.title}>
                <Heading h3 center>
                    <FormattedMessage id="reset password" />
                </Heading>
            </div>
            {error && <p className={styles.error}>{error}</p>}
            {isRequired && <RequiresPasswordChangeMessage />}

            <TextField
                id="username"
                placeholder={intl.formatMessage({
                    id: useEmailAddressLabelForUsername
                        ? 'email address'
                        : 'username',
                })}
                value={username}
                onChange={setUsername}
            />
            <div className={styles.inputHelper}>
                <Text secondary light>
                    {useEmailAddressLabelForUsername && (
                        <FormattedMessage id="enter email address and follow instructions" />
                    )}
                    {!useEmailAddressLabelForUsername && (
                        <FormattedMessage id="enter username and follow instructions" />
                    )}
                </Text>
            </div>
            <Button
                onClick={onResetFormSubmit}
                loading={isLoading}
                justify="center"
            >
                <FormattedMessage id="reset to default" />
            </Button>
            <div className={styles.backToLoginText}>
                <Text secondary light center>
                    <FormattedMessage
                        id="back to <link>login</link>"
                        values={{
                            link: chunks => (
                                <Button
                                    secondary
                                    text
                                    inline
                                    onClick={onBackToLoginPress}
                                >
                                    <Text secondary>{chunks}</Text>
                                </Button>
                            ),
                        }}
                    />
                </Text>
            </div>
        </VStack>
    );
};

export default memo(RequestResetPasswordForm);
