import { memo } from 'react';
import type { CSSProperties } from 'react';
import { clsx } from 'clsx';
import styles from './Placeholder.module.scss';

const Placeholder = ({
    width,
    height,
    borderRadius,
    className,
}: Pick<CSSProperties, 'width' | 'height' | 'borderRadius'> & {
    /**
     * Use for sizes
     */
    className?: string;
}) => {
    return (
        <div
            className={clsx(styles.skeletonBox, className)}
            style={{
                width,
                height,
                borderRadius,
            }}
        />
    );
};

export default memo(Placeholder);
