import { memo } from 'react';
const TooltipArrowIcon = () => {
    return (
        <svg
            width="22"
            height="12"
            viewBox="0 0 22 12.45"
            xmlns="http://www.w3.org/2000/svg"
            fill="white"
        >
            <path d="M0 0 12 12 22 0" stroke="currentColor"></path>
        </svg>
    );
};

export default memo(TooltipArrowIcon);
