import { memo } from 'react';
import { clsx } from 'clsx';
import styles from './RadioButton.module.css';

interface RadioButtonProps {
    selected?: boolean;
}

export const RadioButton = memo(({ selected }: RadioButtonProps) => {
    return (
        <div
            className={clsx(styles.container, {
                [styles.selected]: selected,
            })}
        >
            {selected && <div className={styles.inner} />}
        </div>
    );
});
