import { memo } from 'react';
import { Heading } from '@library';
import type { LinkTileComponent } from '../../types/customStoryblokComponents';
import TopRightArrowIcon from '../../../core/components/icons/TopRightArrowIcon';
import styles from './LinkTile.module.scss';
import StoryblokLink from './Links/StoryblokLink';

const LinkTile = ({ title, sub_title, link }: LinkTileComponent) => {
    return (
        <StoryblokLink
            className={styles.linkTile}
            link={link}
            data-test-class="linkTile"
        >
            <div className={styles.arrow}>
                <TopRightArrowIcon color="var(--base-color-1)" />
            </div>
            <div className={styles.subHeader}>
                <Heading h5 bold>
                    {sub_title}
                </Heading>
            </div>
            <div className={styles.header}>
                <Heading h5 medium>
                    {title}
                </Heading>
            </div>
        </StoryblokLink>
    );
};
export default memo(LinkTile);
