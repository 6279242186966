import { useRef, useState } from 'react';
import equal from 'fast-deep-equal';
import type { CarouselActions } from '../components/Carousel';
import useImmutableCallback from './useImmutableCallback';

const useCarouselActions = ({
    watchControlState,
    watchCurrentItem,
}: {
    watchControlState?: boolean;
    watchCurrentItem?: boolean;
}) => {
    const [controlsState, setShowControls] = useState<{
        showControls: boolean;
        showLeft: boolean;
        showRight: boolean;
    }>({ showControls: false, showLeft: false, showRight: false });

    const [currentItem, setCurrentItem] = useState<number>(0);
    const actions = useRef<CarouselActions | null>(null);

    const previousItem = () => {
        if (actions.current) {
            actions.current.previousItem();
        }
    };

    const nextItem = () => {
        if (actions.current) {
            actions.current.nextItem();
        }
    };

    const handleChangeActions = useImmutableCallback(
        (changedActions: CarouselActions) => {
            if (watchControlState) {
                const newControlsState = {
                    showControls: changedActions.showControls,
                    showLeft: changedActions.showLeftControls,
                    showRight: changedActions.showRightControls,
                };
                if (!equal(newControlsState, controlsState)) {
                    setShowControls(newControlsState);
                }
            }
            if (
                watchCurrentItem &&
                changedActions.currentElement !== currentItem
            ) {
                setCurrentItem(changedActions.currentElement);
            }
            actions.current = changedActions;
        }
    );

    return {
        currentItem,
        actions,
        controlsState,
        handleChangeActions,
        previousItem,
        nextItem,
    };
};

export default useCarouselActions;
