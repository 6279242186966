import { memo } from 'react';
import type { ReactNode } from 'react';
import { Heading, VStack } from '@library';
import type { SpaceSizes } from '../../types/customStoryblokComponents';
import styles from './Section.module.scss';

const Section = ({
    children,
    title,
    className,
    size = 'medium',
    space = 'small',
    alignment = 'start',
    id,
}: {
    children: ReactNode;
    title: string;
    className?: string;
    size?: 'small' | 'medium';
    space?: SpaceSizes;
    alignment?: 'center' | 'start';
    id?: string;
}) => {
    return (
        <div className={className} id={id}>
            {title && (
                <div
                    className={
                        size === 'small'
                            ? styles.smallHeader
                            : styles.mediumHeader
                    }
                >
                    <Heading
                        h3={size === 'medium'}
                        h5={size === 'small'}
                        uppercase={size === 'small'}
                        regular={size === 'small'}
                        center={alignment === 'center'}
                    >
                        {title}
                    </Heading>
                </div>
            )}
            <VStack gap={space}>{children}</VStack>
        </div>
    );
};

export default memo(Section);
