import { memo } from 'react';
import type { ReactNode } from 'react';
import type { ButtonBlockComponent } from '../../../types/customStoryblokComponents';
import { HStack } from '../../../../library';

const ButtonBlock = ({
    children,
    alignment,
}: { children: ReactNode } & Pick<ButtonBlockComponent, 'alignment'>) => {
    return (
        <HStack gap="small" justify={alignment}>
            {children}
        </HStack>
    );
};

export default memo(ButtonBlock);
