import { memo, useEffect } from 'react';
import type { CSSProperties } from 'react';
import Carousel from '../../../core/components/Carousel';
import ChevronLeftIcon from '../../../core/components/icons/ChevronLeftIcon';
import ChevronRightIcon from '../../../core/components/icons/ChevronRightIcon';
import useCarouselActions from '../../../core/hooks/useCarouselActions';
import type { SliderComponent } from '../../../content/types/customStoryblokComponents';
import HeroTile from './HeroTile';
import styles from './HeroSection.module.scss';

const HeroSection = ({ slides, height, _uid }: SliderComponent) => {
    const {
        actions,
        nextItem,
        handleChangeActions,
        currentItem,
        previousItem,
    } = useCarouselActions({
        watchCurrentItem: true,
    });
    useEffect(() => {
        const timer = setTimeout(() => {
            if (actions.current?.nextItem) {
                actions.current.nextItem();
            }
        }, 6000);
        return () => {
            clearTimeout(timer);
        };
    }, [currentItem, actions]);

    const sliderHeight = parseFloat(height) || 450;
    return (
        <div id={_uid}>
            <div
                className={styles.container}
                style={
                    {
                        '--hero-section-height': `${sliderHeight}px`,
                    } as CSSProperties
                }
            >
                <Carousel loop onChangeActions={handleChangeActions}>
                    {slides.map(item => (
                        <HeroTile
                            // eslint-disable-next-line no-underscore-dangle
                            key={item._uid}
                            content={item}
                            height={sliderHeight}
                        />
                    ))}
                </Carousel>
                {slides.length > 1 && (
                    <button
                        type="button"
                        className={styles.previousItemButton}
                        onClick={previousItem}
                    >
                        <ChevronLeftIcon
                            emphasized
                            iconLabel="carousel-previous-icon"
                            color="var(--default-icon-color-2)"
                        />
                    </button>
                )}

                {slides.length > 1 && (
                    <button
                        type="button"
                        className={styles.nextItemButton}
                        onClick={nextItem}
                    >
                        <ChevronRightIcon
                            emphasized
                            iconLabel="carousel-next-icon"
                            color="var(--default-icon-color-2)"
                        />
                    </button>
                )}
            </div>
        </div>
    );
};

export default memo(HeroSection);
