import { memo } from 'react';
import type { CSSProperties } from 'react';
import type { ContainerComponent } from '../../../types/customStoryblokComponents';
import RenderComponents from '../../RenderComponents';
import { VStack } from '../../../../library';
import styles from './StoryblokContainer.module.scss';

const StoryblokContainer = ({ body, space, max_width }: ContainerComponent) => {
    return (
        <div
            className={styles.container}
            style={{ '--container-max-width': max_width } as CSSProperties}
        >
            <VStack gap={space}>
                <RenderComponents components={body} />
            </VStack>
        </div>
    );
};

export default memo(StoryblokContainer);
