import { gql } from '@apollo/client';
import { useActiveStoreAndLanguage } from '../../core/contexts/StoreProvider';
import useContentClient from '../hooks/useContentClient';
import Storyblok from '../storyblok/storyblok';
import type { Asset } from '../types/base';
import useQueryContent from '../storyblok/useQueryContent';

const query = gql`
    query storeInformation($slug: ID!) {
        StoreinformationItem(id: $slug) {
            content {
                data_protection {
                    filename
                }
                terms {
                    filename
                }
                info_link
                info_label
                header_logo {
                    filename
                    title
                    alt
                    focus
                }
                seo_config
            }
        }
    }
`;

interface SEOConfig {
    page: string;
    meta_fields: {
        title: string;
        description: string;
    };
}

interface StoreInformation {
    data_protection?: Pick<Asset, 'filename'>;
    terms?: Pick<Asset, 'filename'>;
    info_link: string;
    info_label: string;
    header_logo?: Pick<Asset, 'filename' | 'title' | 'alt' | 'focus'>;
    seo_config?: Array<SEOConfig>;
}

const useStoreInformation = () => {
    const { store, language } = useActiveStoreAndLanguage();
    const client = useContentClient();
    const { data, loading, error } = useQueryContent<{
        // cspell:words Storeinformation
        StoreinformationItem?: {
            content: StoreInformation;
        };
    }>(query, {
        variables: {
            slug: Storyblok.formatSlug(store, language, 'store-information'),
        },
        client,
    });

    return {
        data: data?.StoreinformationItem?.content,
        loading,
        error,
    };
};

export default useStoreInformation;
