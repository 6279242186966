import { memo } from 'react';
const FacebookIcon = () => {
    return (
        <svg
            id="Group_3208"
            data-name="Group 3208"
            xmlns="http://www.w3.org/2000/svg"
            width="22.5"
            height="22"
            viewBox="0 0 26.885 26.721"
        >
            <defs>
                <clipPath id="clip-path-facebook">
                    <rect
                        id="Rectangle_3872"
                        data-name="Rectangle 3872"
                        width="26.885"
                        height="26.721"
                        transform="translate(0 0)"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g
                id="Group_3207"
                data-name="Group 3207"
                transform="translate(0 0)"
                clipPath="url(#clip-path-facebook)"
            >
                <path
                    id="Path_1871"
                    data-name="Path 1871"
                    d="M26.885,13.444A13.442,13.442,0,1,0,11.342,26.723V17.33H7.929V13.444h3.413V10.483c0-3.369,2.007-5.23,5.077-5.23a20.671,20.671,0,0,1,3.009.263V8.823h-1.7a1.943,1.943,0,0,0-2.191,2.1v2.522h3.728l-.6,3.886H15.543v9.393A13.446,13.446,0,0,0,26.885,13.444"
                    transform="translate(0 -0.002)"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
};

export default memo(FacebookIcon);
