import { memo } from 'react';
import type { CSSProperties } from 'react';
import type { InfoGridComponent } from '../../types/customStoryblokComponents';
import RenderComponents from '../RenderComponents';
import styles from './InfoGrid.module.scss';

const sizeMap = {
    small: {
        width: 'var(--info-grid-small-width)',
        height: 'var(--info-grid-small-height)',
    },
    medium: {
        width: 'var(--info-grid-medium-width)',
        height: 'var(--info-grid-medium-height)',
    },
};

const InfoGrid = ({ size, body }: InfoGridComponent) => {
    const sizeStyle = sizeMap[size];
    return (
        <div
            className={styles.grid}
            style={
                {
                    '--grid-column-width': sizeStyle.width,
                    '--info-tile-min-height': sizeStyle.height,
                } as CSSProperties
            }
        >
            <RenderComponents components={body} />
        </div>
    );
};

export default memo(InfoGrid);
