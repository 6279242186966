import { memo } from 'react';
import { clsx } from 'clsx';
import { Heading } from '@library';
import type { CardContent } from '../../types';
import FeatureTile from './FeatureTile';
import styles from './FeatureContentSection.module.scss';

const FeatureContentSection = ({
    title,
    content,
    id,
}: {
    title: string;
    content: CardContent[];
    id: string;
}) => {
    return (
        <div
            className={clsx(styles.feature, {
                [styles.emptyTitle]: !title,
            })}
            id={id}
        >
            {title && (
                <div className={styles.title}>
                    <Heading h3 center bold>
                        {title}
                    </Heading>
                </div>
            )}

            <div className={styles.layout}>
                {content.map(item => (
                    <div className={styles.tile} key={item._uid} id={item._uid}>
                        <FeatureTile content={item} />
                    </div>
                ))}
            </div>
        </div>
    );
};

export default memo(FeatureContentSection);
