import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import type { CSSProperties } from 'react';
import type {
    GoogleMapComponentProps,
    MapRef,
} from '../../../../core/components/maps/GoogleMap';
import GoogleMap from '../../../../core/components/maps/GoogleMap';
import MarkerIcon from '../../../../assets/map_icon.svg';
import RedMarkerIcon from '../../../../assets/map_icon_red.svg';
import { FluidProgressIndicator } from '../../../../core/components/ProgressIndicators';
import type { GoogleMapComponent } from '../../../types/customStoryblokComponents';
import { googleMapHeight } from '../../../types/customStoryblokComponents';
import useGetPositionFromGeoParams from '../../../hooks/useGetPositionFromGeoParams';
import ComponentWithGoogleMap from '../../../../core/components/maps/ComponentWithGoogleMap';
import type { Point } from '../../../../core/components/maps/types';
import type { InfoWindowComponentProps } from '../../../../core/components/maps/InfoWindowComponent';
import type { DocComponent } from '../../../types/storyblokComponents';
import styles from './StoryblokGoogleMap.module.css';
import MarkerInfoPopup from './MarkerInfoPopup';

const StoryblokGoogleMapWithoutApi = memo(
    ({
        latitude,
        longitude,
        address,
        height = '540',
        boundsPadding = '100',
        markers: storyblokMarkers,
        zoomToMarker,
    }: GoogleMapComponent) => {
        const mapRef = useRef<MapRef | null>(null);
        const [loaded, setLoaded] = useState(false);
        const [position, setPosition] = useState<Point | undefined>();

        const onPositionChange = useCallback((newPosition?: Point) => {
            setPosition(newPosition);
        }, []);

        const { searchDivRef } = useGetPositionFromGeoParams({
            address,
            latitude,
            longitude,
            onPositionChange,
            skip: !!storyblokMarkers?.length,
        });

        const markers = useMemo(() => {
            if (storyblokMarkers?.length) {
                return storyblokMarkers.map(marker => {
                    return {
                        value: marker._uid,
                        latitude: parseFloat(marker.latitude),
                        longitude: parseFloat(marker.longitude),
                        icon: MarkerIcon,
                    };
                });
            }
            return position
                ? [
                      {
                          latitude: position.latitude,
                          longitude: position.longitude,
                          icon: MarkerIcon,
                      },
                  ]
                : [];
        }, [position, storyblokMarkers]);

        useEffect(() => {
            if (mapRef.current && markers.length) {
                const bounds = new window.google.maps.LatLngBounds();
                markers.forEach(marker => {
                    bounds.extend(
                        new window.google.maps.LatLng(
                            marker.latitude,
                            marker.longitude
                        )
                    );
                });
                mapRef.current.map?.fitBounds(bounds, parseInt(boundsPadding));
            }
        }, [markers, loaded, boundsPadding]);

        const center = useMemo(() => {
            if (markers.length) {
                return { lat: markers[0].latitude, lng: markers[0].longitude };
            } else {
                return (
                    position && {
                        lat: position.latitude,
                        lng: position.longitude,
                    }
                );
            }
        }, [markers, position]);

        const heightValue = useMemo(() => {
            if (!isNaN(Number(height))) {
                return `${height}px`;
            } else if (height === 'small' || height === 'medium') {
                return googleMapHeight[height];
            }
        }, [height]);

        const [active, setActive] = useState<{
            marker: google.maps.Marker;
            content: DocComponent;
            title: string;
            position: InfoWindowComponentProps['position'];
        }>();

        const onClose = () => {
            active?.marker.setIcon(MarkerIcon);
            setActive(undefined);
        };

        const onMarkerSelect: GoogleMapComponentProps['onMarkerSelect'] = ({
            value,
            marker,
        }) => {
            if (value) {
                active?.marker.setIcon(MarkerIcon);
                const selectedMarker = storyblokMarkers?.find(
                    item => item._uid === value
                );
                marker.setIcon(RedMarkerIcon);
                if (selectedMarker) {
                    setActive({
                        marker,
                        content: selectedMarker.content,
                        title: selectedMarker.title,
                        position: {
                            address: {
                                latitude: selectedMarker.latitude,
                                longitude: selectedMarker.longitude,
                            },
                        },
                    });
                }
            }
        };

        return (
            <div
                className={styles.container}
                style={
                    {
                        '--google-map-height': heightValue,
                    } as CSSProperties
                }
            >
                <div ref={searchDivRef} />
                {center ? (
                    <GoogleMap
                        className={styles.container}
                        ref={ref => {
                            mapRef.current = ref ?? null;
                            if (mapRef.current?.map) {
                                setLoaded(true);
                            }
                        }}
                        options={{
                            zoom: 16,
                            center,
                        }}
                        markers={markers}
                        onMarkerSelect={onMarkerSelect}
                    />
                ) : (
                    <FluidProgressIndicator />
                )}

                {mapRef.current?.map && active && (
                    <MarkerInfoPopup
                        context={mapRef.current.map}
                        content={active.content}
                        title={active.title}
                        anchor={active.marker}
                        position={active.position}
                        onClose={onClose}
                        zoomToMarker={zoomToMarker}
                    />
                )}
            </div>
        );
    }
);

const StoryblokGoogleMap = ComponentWithGoogleMap(StoryblokGoogleMapWithoutApi);

export default StoryblokGoogleMap;
