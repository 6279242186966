import { memo, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useLocation } from 'react-router-dom';
import { clsx } from 'clsx';
import type { Language } from '@apiTypes';
import { Dropdown, HStack, Text } from '@library';
import useStoreAndLanguageParams from '../../../../core/hooks/useStoreAndLanguageParams';
import useCurrentStore from '../../../../core/hooks/useCurrentStore';
import useCustomNavigate from '../../../../core/hooks/router/useCustomNavigate';
import replaceLanguageInPath from '../../../../core/util/replaceLanguageInPath';
import CountryFlag from '../../CountryFlag';
import styles from './LanguageDropdown.module.css';

const LanguageDropdown = memo(
    ({ dark, isFlagVisible }: { dark?: boolean; isFlagVisible?: boolean }) => {
        const intl = useIntl();
        const { language: currentLanguage } = useStoreAndLanguageParams();
        const store = useCurrentStore();
        const navigate = useCustomNavigate();
        const { pathname, search } = useLocation();
        const { languages } = store;

        const items = languages.map(({ language }) => ({
            value: language,
            label: intl.formatMessage({
                id: language,
            }),
        }));

        const handleSelectLanguage = useCallback(
            (language: string) => {
                navigate(
                    {
                        pathname: replaceLanguageInPath({
                            pathname,
                            language: language as Language,
                            isLockedStore: !!APP_ENV.STORE,
                        }),
                        search,
                    },
                    { replace: true }
                );
            },
            [navigate, pathname, search]
        );

        return (
            <Dropdown
                buttonProps={{
                    white: !dark,
                    inline: true,
                    text: true,
                    theme: clsx({
                        [styles.darkTheme]: dark,
                    }),
                }}
                items={items}
                matchWidth={120}
                placement={'bottom-end'}
                onSelect={handleSelectLanguage}
            >
                <HStack gap="mini" align="center">
                    {isFlagVisible && (
                        <CountryFlag language={currentLanguage} />
                    )}
                    <Text
                        uppercase
                        secondary
                        hoverBold
                        data-text={currentLanguage}
                    >
                        {currentLanguage}
                    </Text>
                </HStack>
            </Dropdown>
        );
    }
);

export default LanguageDropdown;
