import { memo } from 'react';
import { Heading, HStack, VStack } from '@library';
import type { DownloadSectionComponent } from '../../types/customStoryblokComponents';
import StoryblokRender from '../StoryblokRender';
import StoryblokResponsiveImage from './StoryblokResponsiveImage';
import styles from './DownloadSection.module.scss';

const DownloadSection = ({
    title,
    image,
    body,
    space,
}: DownloadSectionComponent) => {
    return (
        <div className={styles.section}>
            <HStack wrap gap="small" expanded>
                <div className={styles.header}>
                    <Heading h3>{title}</Heading>
                    {image && (
                        <StoryblokResponsiveImage
                            className={styles.image}
                            image={image}
                            srcSets={[
                                { width: 0, height: 32, screenWidth: '1x' },
                                { width: 0, height: 64, screenWidth: '2x' },
                            ]}
                        />
                    )}
                </div>
                <div className={styles.content}>
                    <VStack gap={space || 'small'}>
                        <StoryblokRender components={body} />
                    </VStack>
                </div>
            </HStack>
        </div>
    );
};

export default memo(DownloadSection);
