import { clsx } from 'clsx';
import { Button, Heading, HStack, Text, VStack } from '@library';
import type { ComponentsHandler } from '../types/types';
import HeroSection from '../../home/components/HeroSection/HeroSection';
import FeatureContentSection from '../../home/components/FeatureContentSection/FeatureContentSection';
import ComponentSuspense from '../../core/components/router/ComponentSuspense';
import NewsFeedSection from '../../home/components/NewsFeed/NewsFeedSection';
import ProductShowcaseSection from '../../home/components/ProductShowcaseSection/ProductShowcaseSection';
import ErrorBoundary from '../../core/components/ErrorBoundary';
import JotForm from '../../externalForms/components/JotForm';
import styles from './ComponentsMapper.module.scss';
import RenderComponents from './RenderComponents';
import LinkTile from './PageComponents/LinkTile';
import TileGrid from './PageComponents/TileGrid';
import Section from './PageComponents/Section';
import InfoGrid from './PageComponents/InfoGrid';
import InfoTile from './PageComponents/InfoTile';
import DownloadSection from './PageComponents/DownloadSection';
import AlertBlock from './PageComponents/AlertBlock';
import ExternalVideo from './PageComponents/ExternalVideo';
import ButtonBlock from './PageComponents/Button/ButtonBlock';
import StoryblokResponsiveImage from './PageComponents/StoryblokResponsiveImage';
import StoryblokGrid from './PageComponents/Layout/StoryblokGrid';
import StoryblokGridColumn from './PageComponents/Layout/StoryblokGridColumn';
import ContactButton from './PageComponents/Button/ContactButton';
import RenderComponent from './RenderComponent';
import StoryblokContainer from './PageComponents/Layout/StoryblokContainer';
import StoryblokSpace from './PageComponents/Layout/StoryblokSpace';
import StoryblokLink from './PageComponents/Links/StoryblokLink';
import StoryblokLinkMark from './PageComponents/Links/StoryblokLinkMark';
import SocialLink from './PageComponents/SocialLink';
import StoryblokGoogleMap from './PageComponents/Map/StoryblokGoogleMap';

const ComponentsMapper: ComponentsHandler = {
    image: ({ attrs: { src, title, alt }, marks }) => {
        const image = { filename: src, alt, title };
        return (
            <StoryblokLinkMark marks={marks}>
                <StoryblokResponsiveImage
                    className={styles.image}
                    image={image}
                    predefinedSet="fullWidth"
                />
            </StoryblokLinkMark>
        );
    },
    text: ({ text, marks }, customStyles) => {
        const stylesMap = {
            bold: styles.boldText,
            italic: styles.italicText,
            strike: styles.strikeText,
            underline: styles.underlineText,
            link: null,
        };

        return (
            <span
                className={clsx(
                    marks &&
                        marks
                            .filter(mark => stylesMap[mark.type])
                            .map(mark => {
                                return stylesMap[mark.type];
                            })
                )}
            >
                <StoryblokLinkMark marks={marks} className={customStyles?.link}>
                    {text}
                </StoryblokLinkMark>
            </span>
        );
    },
    heading: ({ content, attrs: { level } }) => {
        const types: Record<number, string> = {
            1: 'h1',
            2: 'h2',
            3: 'h3',
            4: 'h4',
            5: 'h5',
        };
        const type = types[level];

        return (
            <div className={styles[type]}>
                <Heading {...{ [type]: true }}>
                    <RenderComponents components={content} />
                </Heading>
            </div>
        );
    },
    paragraph: ({ content }, customStyles) => {
        return (
            <div className={clsx(styles.paragraph, customStyles?.paragraph)}>
                <Text light primary>
                    <RenderComponents components={content} />
                </Text>
            </div>
        );
    },
    hard_break: () => {
        return <br />;
    },
    doc: ({ content }) => {
        return <RenderComponents components={content} />;
    },
    ordered_list: ({ content }) => {
        return (
            <ol>
                <RenderComponents components={content} />
            </ol>
        );
    },
    bullet_list: ({ content }) => {
        return (
            <ul>
                <RenderComponents components={content} />
            </ul>
        );
    },
    list_item: ({ content }) => {
        return (
            <li>
                <RenderComponents components={content} />
            </li>
        );
    },
    external_video: params => {
        // eslint-disable-next-line jsx-a11y/media-has-caption
        return <ExternalVideo {...params} />;
    },
    richtext: ({ content, _uid }) => {
        return (
            <VStack gap="small" id={_uid}>
                <RenderComponents components={content} />
            </VStack>
        );
    },
    blok: ({ attrs: { body } }) => {
        return (
            <div>
                <RenderComponents components={body} />
            </div>
        );
    },

    text_grid: ({ columns }) => {
        return (
            <HStack justify="spaceBetween">
                {columns.map(item => (
                    <div key={item._uid}>
                        <RenderComponent component={item} />
                    </div>
                ))}
            </HStack>
        );
    },
    slider: params => {
        return <HeroSection {...params} />;
    },
    feature_grid: ({ title, features, _uid }) => {
        return (
            <FeatureContentSection title={title} content={features} id={_uid} />
        );
    },
    product_list: ({ products, title, _uid }) => {
        return (
            <ProductShowcaseSection
                title={title}
                productIds={products.map(product => product.node_id)}
                id={_uid}
            />
        );
    },
    news_feed: params => {
        return (
            <ComponentSuspense height="200px">
                <NewsFeedSection {...params} />
            </ComponentSuspense>
        );
    },
    feature: () => null,
    product: () => null,
    section: params => {
        const { body } = params;
        return (
            <Section {...params}>
                <RenderComponents components={body} />
            </Section>
        );
    },
    link_tile: params => {
        return <LinkTile {...params} />;
    },
    tile_grid: params => {
        return <TileGrid {...params} />;
    },
    info_grid: params => {
        return <InfoGrid {...params} />;
    },
    info_tile: params => {
        return <InfoTile {...params} />;
    },
    download_section: params => {
        return <DownloadSection {...params} />;
    },
    sub_section: params => {
        const { body } = params;
        return (
            <Section size="small" {...params}>
                <RenderComponents components={body} />
            </Section>
        );
    },
    alert_block: params => <AlertBlock {...params} />,
    button: ({ target, label, button_type }) => {
        return label ? (
            <StoryblokLink link={target}>
                {label && (
                    <Button
                        secondary={button_type === 'secondary'}
                        tertiary={button_type === 'tertiary'}
                    >
                        {label}
                    </Button>
                )}
            </StoryblokLink>
        ) : null;
    },
    button_block: ({ body, alignment }) => {
        return (
            <ButtonBlock alignment={alignment}>
                <RenderComponents components={body} />
            </ButtonBlock>
        );
    },
    contact_button: params => {
        return <ContactButton {...params} />;
    },
    grid: params => {
        return <StoryblokGrid {...params} />;
    },
    grid_column: params => {
        return <StoryblokGridColumn {...params} />;
    },
    container: params => {
        return <StoryblokContainer {...params} />;
    },
    space: params => {
        return <StoryblokSpace {...params} />;
    },
    link: ({ link, label }, customStyles) => {
        const className = customStyles?.link ? customStyles.link : styles.link;
        return (
            <StoryblokLink link={link} className={className}>
                <Text secondary regular>
                    <span className={customStyles?.linkText}>{label}</span>
                </Text>
            </StoryblokLink>
        );
    },
    google_map: params => {
        return (
            <ComponentSuspense height="400px">
                <ErrorBoundary>
                    <StoryblokGoogleMap {...params} />
                </ErrorBoundary>
            </ComponentSuspense>
        );
    },
    social_link: params => {
        return <SocialLink {...params} />;
    },
    jotform: params => {
        return <JotForm src={params.url} />;
    },
};

export default ComponentsMapper;
