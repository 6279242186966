/* eslint-disable jsx-a11y/media-has-caption */
import { memo } from 'react';
import type { CSSProperties } from 'react';
import type { ExternalVideoComponent } from '../../types/customStoryblokComponents';
import getVideoOptions from '../../util/getVideoOptions';
import ConsentRestriction from '../../../cookies/components/ConsentRestriction';
import styles from './ExternalVideo.module.scss';

const ExternalVideo = ({
    url,
    width,
    storyblok_asset: storyblokAsset,
}: ExternalVideoComponent) => {
    if (!url && !storyblokAsset?.filename) {
        return null;
    }
    const baseStyle = { '--width': width } as CSSProperties;
    const parsedURl = getVideoOptions(url, storyblokAsset);

    if (!parsedURl.link) {
        return <>incorrect url</>;
    }
    return (
        <div style={baseStyle} className={styles.videoContainerWrapper}>
            <div className={styles.videoContainer}>
                {parsedURl.type === 'iframe' ? (
                    <ConsentRestriction
                        consent={{ functional: true }}
                        className={styles.videoFrame}
                    >
                        <iframe
                            className={styles.videoFrame}
                            title="youtube"
                            src={parsedURl.link}
                            frameBorder="0"
                            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                            allowFullScreen
                        />
                    </ConsentRestriction>
                ) : (
                    <video
                        className={styles.videoFrame}
                        src={parsedURl.link}
                        controls
                        preload="metadata"
                    />
                )}
            </div>
        </div>
    );
};

export default memo(ExternalVideo);
