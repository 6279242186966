import { memo, useContext, useEffect, useMemo } from 'react';
import type { ReactNode } from 'react';
import useConsistentReference from '../../core/hooks/useConsistentReference';
import useObservableVariableState from '../../core/hooks/useObservableVariableState';
import type { UserAddress } from '../../user/types';
import useCheckoutQuery from '../queries/useCheckoutQuery';
import type { CheckoutErrorType, CheckoutWarningType } from '../types';
import PreorderModeContext from '../../preorder/PreorderModeContext';
import { AppContext } from '../../core/context';
import getCurrentShippingMethods from '../../core/util/getCurrentShippingMethods';
import { useCartFieldsContext } from '../../cart/contexts/CartFields/CartFieldsContext';
import captureException from '../../core/util/captureException';
import useCurrentStockOnlyFlag from '../../availability/hooks/useCurrentStockOnlyFlag';
import type { CheckoutFields } from './CheckoutContext';
import CheckoutContext from './CheckoutContext';

const IMMUTABLE_PAYMENT_TERMS = ['Vorauszahlung', 'NN'];

export enum CheckoutErrors {
    DEFAULT_SHIPPING_METHOD_ERROR = 'DEFAULT_SHIPPING_METHOD_ERROR',
    FAILED_TO_UPDATE_DEFAULT_DELIVERY_DATE = 'FAILED_TO_UPDATE_DEFAULT_DELIVERY_DATE',
    FAILED_TO_UPDATE_DELIVERY_DATES = 'FAILED_TO_UPDATE_DELIVERY_DATES',
    FAILED_TO_UPDATE_ = 'FAILED_TO_UPDATE_',
    FAILED_TO_FETCH_CART_FIELDS = 'FAILED_TO_FETCH_CART_FIELDS',
    FAILED_TO_FETCH_DELIVERY_DATES = 'FAILED_TO_FETCH_DELIVERY_DATES',
}

const sortedShippingAddresses = (addresses: UserAddress[]) =>
    addresses
        .filter(item => item.isShippingAddress)
        .sort((a, b) => {
            if (a.isDefaultShippingAddress && !b.isDefaultShippingAddress) {
                return -1;
            }
            if (!a.isDefaultShippingAddress && b.isDefaultShippingAddress) {
                return 1;
            }

            return 0;
        });

const useResetNotValidOptions = (checkoutFields: CheckoutFields) => {
    const { cartFieldsObservable, saveCartFields } = useCartFieldsContext();

    const [cartFields] = useObservableVariableState(cartFieldsObservable);

    const shippingMethods = useConsistentReference(
        checkoutFields.shippingMethods
    );

    const { isInPreorderMode } = useContext(PreorderModeContext);
    const { isImpersonating } = useContext(AppContext);
    const isUsualUserInPreorderMode = isInPreorderMode && !isImpersonating;

    useEffect(() => {
        if (isUsualUserInPreorderMode) {
            return;
        }
        if (cartFields) {
            const currentAvailableShippingMethod = getCurrentShippingMethods(
                shippingMethods,
                cartFields.shippingMethod
            );

            if (
                currentAvailableShippingMethod &&
                currentAvailableShippingMethod !== cartFields.shippingMethod
            ) {
                saveCartFields({
                    shippingMethod: currentAvailableShippingMethod,
                }).catch(error => {
                    captureException(error);
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        shippingMethods,
        cartFields?.shippingMethod,
        isUsualUserInPreorderMode,
    ]);
};

const CheckoutProvider = ({ children }: { children: ReactNode }) => {
    const { initialized, loading, data, error } = useCheckoutQuery();
    const isPrepaid = useCurrentStockOnlyFlag();

    const checkoutFields = useMemo(() => {
        if (!data) {
            return {
                billingAddress: undefined,
                shippingAddress: [],
                branches: [],
                shippingMethods: [],
                errorCodes: [],
                warnings: [],
                isDropShipmentEnabled: false,
                requiresPrepay: false,
                isPaymentTermsMutable: false,
            };
        }

        const { checkout, currentCustomer } = data;

        const fields = {
            billingAddress: currentCustomer.addresses.find(
                item => item.isDefaultBillingAddress
            ),
            shippingAddress: sortedShippingAddresses(currentCustomer.addresses),
            branches: checkout.branches ?? [],
            shippingMethods: checkout.shippingMethods,
            errorCodes: checkout.errorCodes,
            isDropShipmentEnabled: checkout.isDropShipmentEnabled ?? false,
            requiresPrepay: checkout.requiresPrepay ?? false,
            isPaymentTermsMutable: !!(
                currentCustomer.defaultPaymentTermId &&
                !IMMUTABLE_PAYMENT_TERMS.includes(
                    currentCustomer.defaultPaymentTermId
                )
            ),
            checkoutPriceSummary: checkout.checkoutPriceSummary.result,
            warnings: [] as CheckoutWarningType[],
        };

        if (error) {
            const isAnyError =
                error.graphQLErrors.filter(
                    graphQLError =>
                        graphQLError.message !==
                        'Failed to fetch delivery charges'
                ).length > 0;

            if (isAnyError) {
                return {
                    ...fields,
                    errorCodes: ['serverError' as CheckoutErrorType],
                };
            }
            if (!isPrepaid && error.graphQLErrors.length > 0) {
                return {
                    ...fields,
                    warnings: [
                        'deliveryChargeFetchFailed' as CheckoutWarningType,
                    ],
                };
            }
        }

        return fields;
    }, [data, error, isPrepaid]);

    const contextValue = useMemo(() => {
        return {
            checkoutFields,
            loading,
            initialized,
        };
    }, [checkoutFields, loading, initialized]);

    useResetNotValidOptions(contextValue.checkoutFields);

    return (
        <CheckoutContext.Provider value={contextValue}>
            {children}
        </CheckoutContext.Provider>
    );
};

export default memo(CheckoutProvider);
