import type { JSX } from 'react';
import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';
import { clsx } from 'clsx';
import {
    BrandsIcon,
    Button,
    CloseIcon,
    DealerSearchIcon,
    HStack,
    InformationIcon,
    MotocycleSearchIcon,
    SalesIcon,
    ShowCatalogIcon,
    SidePane,
    SparePartsSearchIcon,
    SupportIcon,
    Text,
    VStack,
} from '@library';
import CompanyLogo from '../../../../core/components/CompanyLogo';
import RestrictedContent from '../../../../core/components/router/RestrictedContent';
import CurrentStoreLink from '../../../../core/components/router/CurrentStoreLink';
import useSalePage from '../../../../core/queries/useSalePage';
import styles from './MenuSidebar.module.css';
import { DealerPriceSwitch } from './DealerPriceDropdown/DealerPriceDropdown';
import LanguageDropdown from './LanguageDropdown';
import PreorderItems from './PreorderItems';
import StoreInfo from './StoreInfo';
import ContactUsMenuItem from './ContactUsMenuItem';

interface MenuSidePaneProps {
    isOpen: boolean;
    setIsOpen: (val: boolean) => void;
    openCategories?: () => void;
}

export default memo(
    ({ isOpen, setIsOpen, openCategories }: MenuSidePaneProps) => {
        const { pathname } = useLocation();
        const isRouteActive = (path: string) => pathname.includes(path);

        return (
            <SidePane
                open={isOpen}
                side="left"
                onOpenChange={setIsOpen}
                hiddenCloseButton
                header={
                    <div className={styles.header}>
                        <HStack justify="spaceBetween" align="center">
                            <div className={styles.logo}>
                                <CompanyLogo />
                            </div>
                            <Button
                                inline
                                text
                                onClick={() => setIsOpen(false)}
                            >
                                <CloseIcon />
                            </Button>
                        </HStack>
                    </div>
                }
            >
                <div className={styles.container}>
                    <div className={styles.section}>
                        <VStack gap="gap24">
                            <Button
                                fullWidth
                                justify="center"
                                onClick={() => {
                                    openCategories?.();
                                    setIsOpen(false);
                                }}
                            >
                                <HStack gap="gap8" align="center">
                                    <ShowCatalogIcon mini dark />
                                    <Text primary semiBold center>
                                        <FormattedMessage id="all categories" />
                                    </Text>
                                </HStack>
                            </Button>
                            <VStack gap="gap26">
                                <LinkItem
                                    to="brands"
                                    active={isRouteActive('/brands')}
                                    messageId="brands"
                                    icon={<BrandsIcon />}
                                    onClick={() => setIsOpen(false)}
                                />

                                <RestrictedContent
                                    storeFlags="sparePartSearchEnabled"
                                    loggedInOnly
                                >
                                    <LinkItem
                                        to="spare-parts"
                                        active={isRouteActive('/spare-parts')}
                                        messageId="spare parts search"
                                        icon={
                                            <SparePartsSearchIcon size={28} />
                                        }
                                        onClick={() => setIsOpen(false)}
                                    />
                                </RestrictedContent>

                                <RestrictedContent stores={['ch']}>
                                    <LinkItem
                                        to="motorcycle-search"
                                        messageId="motorcycle search"
                                        icon={<MotocycleSearchIcon size={26} />}
                                        active={isRouteActive(
                                            '/motorcycle-search'
                                        )}
                                        onClick={() => setIsOpen(false)}
                                    />
                                </RestrictedContent>

                                <RestrictedContent
                                    loggedInOnly
                                    featureFlag="rma"
                                >
                                    <LinkItem
                                        to="service"
                                        messageId="support"
                                        icon={<SupportIcon />}
                                        onClick={() => setIsOpen(false)}
                                        active={isRouteActive('/service')}
                                        data-test-class="accountMenuDropdownItem"
                                    />
                                </RestrictedContent>

                                <RestrictedContent loggedInOnly>
                                    <SalesLinkItem
                                        active={isRouteActive('/sale')}
                                        onClick={() => setIsOpen(false)}
                                    />
                                </RestrictedContent>

                                <RestrictedContent
                                    storeFlags="dealerSearchEnabled"
                                    loggedOutOnly
                                >
                                    <LinkItem
                                        to="dealers"
                                        messageId="dealer locator"
                                        icon={<DealerSearchIcon size={26} />}
                                        active={isRouteActive('/dealers')}
                                        onClick={() => setIsOpen(false)}
                                    />
                                </RestrictedContent>
                            </VStack>
                        </VStack>
                    </div>
                    <RestrictedContent loggedInOnly>
                        <div className={styles.section}>
                            <DealerPriceSwitch />
                        </div>
                    </RestrictedContent>

                    <RestrictedContent loggedOutOnly>
                        <InfoSection
                            hide={() => setIsOpen(false)}
                            isRouteActive={isRouteActive}
                        />
                    </RestrictedContent>

                    <div className={styles.section}>
                        <LanguageDropdown dark isFlagVisible />
                    </div>

                    <RestrictedContent loggedInOnly>
                        <PreorderItems isRouteActive={isRouteActive} />
                    </RestrictedContent>

                    <RestrictedContent loggedInOnly>
                        <InfoSection
                            hide={() => setIsOpen(false)}
                            isRouteActive={isRouteActive}
                        />
                    </RestrictedContent>

                    <div className={styles.section}>
                        <StoreInfo />
                    </div>
                </div>
            </SidePane>
        );
    }
);

interface LinkItemProps {
    active?: boolean;
    to: string;
    icon?: JSX.Element;
    onClick?: () => void;
    messageId: string;
}

const LinkItem = memo(
    ({ active, icon, to, messageId, onClick }: LinkItemProps) => {
        return (
            <CurrentStoreLink
                to={`/${to}`}
                className={clsx(styles.link, {
                    [styles.activeLink]: active,
                })}
                data-test-class="header-link"
                onClick={onClick}
                activeClassName={styles.activeLink}
            >
                <HStack align="center" gap="gap16">
                    {icon}
                    <Text menuButton>
                        <FormattedMessage id={messageId} />
                    </Text>
                </HStack>
            </CurrentStoreLink>
        );
    }
);

const InfoSection = memo(
    ({
        isRouteActive,
        hide,
    }: {
        hide: () => void;
        isRouteActive: (path: string) => boolean;
    }) => {
        return (
            <div className={styles.section}>
                <VStack gap="gap24">
                    <ContactUsMenuItem />

                    <LinkItem
                        to={'pages/ueber-uns'}
                        messageId="information"
                        icon={<InformationIcon size={29} />}
                        active={isRouteActive('pages/ueber-uns')}
                        onClick={hide}
                    />
                </VStack>
            </div>
        );
    }
);

const SalesLinkItem = memo(
    ({ active, onClick }: { active: boolean; onClick?: () => void }) => {
        const { data } = useSalePage();

        if (!data) {
            return null;
        }

        return (
            <LinkItem
                to="sale"
                messageId="sales page header"
                icon={<SalesIcon size={28} />}
                active={active}
                onClick={onClick}
            />
        );
    }
);
