import { memo } from 'react';
import type { BaseStoryblokButton } from '../../../types/customStoryblokComponents';
import Button from '../../../../core/components/Button';

const StoryblokButton = ({
    label,
    button_type,
    onPress,
    loading,
}: BaseStoryblokButton & {
    onPress: () => void;
    loading?: boolean;
}) => {
    if (!label) {
        return null;
    }
    return (
        <Button
            loading={loading}
            onPress={onPress}
            primary={button_type === 'primary'}
            secondary={button_type === 'secondary'}
            tertiary={button_type === 'tertiary'}
        >
            {label}
        </Button>
    );
};

export default memo(StoryblokButton);
