import { memo } from 'react';
import type { ReactNode } from 'react';
import { Text, VStack, Button } from '@library';
import CheckmarkIcon from './icons/CheckmarkIcon';
import styles from './SuccessMessage.module.css';

const SuccessMessage = ({
    message,
    actionText,
    onClose,
    customActions,
}: {
    message: ReactNode;
    actionText?: ReactNode;
    onClose?: () => void;
    customActions?: ReactNode;
}) => {
    return (
        <VStack align="center">
            <CheckmarkIcon circular />
            <div className={styles.successMessage}>
                <Text primary center>
                    {message}
                </Text>
            </div>
            {customActions}
            {!customActions && <Button onClick={onClose}>{actionText}</Button>}
        </VStack>
    );
};

export default memo(SuccessMessage);
