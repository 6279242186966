import type { JSX } from 'react';
import { memo } from 'react';
import { DeIcon, ItIcon, EnIcon, FrIcon } from '@library';
import type { Language } from '../../core/types/language';

const flagMap: Record<Language, JSX.Element> = {
    de: <DeIcon size={20} />,
    it: <ItIcon size={20} />,
    fr: <FrIcon size={20} />,
    en: <EnIcon size={20} />,
};

interface CountryFlagProps {
    language: Language;
}

export default memo(({ language }: CountryFlagProps) => {
    return flagMap[language];
});
