import { memo } from 'react';
const InstagramIcon = () => {
    return (
        <svg
            id="Group_3196"
            data-name="Group 3196"
            xmlns="http://www.w3.org/2000/svg"
            width="22"
            height="22"
            viewBox="0 0 27 27"
        >
            <defs>
                <clipPath id="clip-path-instagram">
                    <rect
                        id="Rectangle_3866"
                        data-name="Rectangle 3866"
                        width="27"
                        height="27"
                        fill="currentColor"
                    />
                </clipPath>
            </defs>
            <g
                id="Group_3195"
                data-name="Group 3195"
                transform="translate(0 0)"
                clipPath="url(#clip-path-instagram)"
            >
                <path
                    id="Path_1854"
                    data-name="Path 1854"
                    d="M18.9,11.9a6.9,6.9,0,1,0,6.9,6.9,6.909,6.909,0,0,0-6.9-6.9m0,11.313A4.418,4.418,0,1,1,23.313,18.8,4.438,4.438,0,0,1,18.9,23.213"
                    transform="translate(-5.535 -5.489)"
                    fill="currentColor"
                />
                <path
                    id="Path_1855"
                    data-name="Path 1855"
                    d="M36.762,8.7A1.562,1.562,0,1,1,35.2,10.262,1.562,1.562,0,0,1,36.762,8.7"
                    transform="translate(-16.237 -4.013)"
                    fill="currentColor"
                />
                <path
                    id="Path_1856"
                    data-name="Path 1856"
                    d="M24.566,2.209A7.673,7.673,0,0,0,18.91,0H7.812A7.38,7.38,0,0,0,0,7.812V18.856A7.752,7.752,0,0,0,2.263,24.62a7.862,7.862,0,0,0,5.6,2.1h10.99a7.955,7.955,0,0,0,5.657-2.1,7.713,7.713,0,0,0,2.209-5.711V7.812a7.723,7.723,0,0,0-2.155-5.6m-.215,16.7a5.264,5.264,0,0,1-1.562,3.933,5.569,5.569,0,0,1-3.933,1.4H7.866a5.569,5.569,0,0,1-3.933-1.4,5.415,5.415,0,0,1-1.455-3.987V7.812A5.371,5.371,0,0,1,3.933,3.879a5.478,5.478,0,0,1,3.933-1.4h11.1A5.371,5.371,0,0,1,22.9,3.933a5.559,5.559,0,0,1,1.455,3.879Z"
                    transform="translate(0 0)"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
};

export default memo(InstagramIcon);
