import {
    MarkerClusterer,
    SuperClusterAlgorithm,
} from '@googlemaps/markerclusterer';
import { throttle } from 'lodash-es';
import {
    memo,
    useEffect,
    useImperativeHandle,
    useMemo,
    useRef,
    useState,
    forwardRef,
} from 'react';
import type { ForwardedRef } from 'react';
import useImmutableCallback from '../../hooks/useImmutableCallback';
import computeMarkerFromOptions from '../../util/googleMap/computeMarkerFromOptions';
import notEmpty from '../../util/notEmpty';
import ComponentWithGoogleMap from './ComponentWithGoogleMap';

const markerClusterIcon = `data:image/svg+xml;base64,${window.btoa(
    '<svg fill="var(--color-primary)" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240"><circle cx="120" cy="120" opacity=".8" r="70" /></svg>'
)}`;

export interface GoogleMapComponentProps {
    className: string;
    options?: google.maps.MapOptions;
    markers?: MapMarker[];
    shouldClusterMarkers?: boolean;
    onBoundsChange?(bounds?: google.maps.LatLngBounds): void;
    onMarkerSelect?(event: {
        value: unknown;
        marker: google.maps.Marker;
    }): void;
}

export interface MapMarker {
    latitude: number;
    longitude: number;
    title?: string;
    icon?: string | google.maps.Icon | google.maps.Symbol;
    value?: unknown;
}

const initMarkers = ({
    map,
    markers,
    onMarkerSelect,
    previousResult,
    shouldClusterMarkers = false,
    clusterer,
}: {
    map: google.maps.Map;
    markers: MapMarker[];
    onMarkerSelect?: (event: {
        value: unknown;
        marker: google.maps.Marker;
    }) => void;
    previousResult: [
        google.maps.Marker,
        google.maps.MapsEventListener | undefined,
        MapMarker,
    ][];
    shouldClusterMarkers?: boolean;
    clusterer?: MarkerClusterer;
}): [
    google.maps.Marker,
    google.maps.MapsEventListener | undefined,
    MapMarker,
][] => {
    const remainingPreviousResults = previousResult.slice();

    const result = markers
        .map(markerOptions =>
            computeMarkerFromOptions({
                map,
                clusterer,
                markerOptions,
                onMarkerSelect,
                remainingPreviousResults,
                shouldClusterMarkers,
            })
        )
        .filter(notEmpty);

    const markersToRemove: google.maps.Marker[] = [];
    for (const [marker, listener] of remainingPreviousResults) {
        if (listener) {
            listener.remove();
        }

        if (shouldClusterMarkers) {
            markersToRemove.push(marker);
        } else {
            marker.setMap(null);
        }
    }

    if (shouldClusterMarkers) {
        if (markersToRemove.length) {
            clusterer?.removeMarkers(markersToRemove);
        }
        clusterer?.addMarkers(result.map(([marker]) => marker));
    }

    return result;
};

export interface MapRef {
    map?: google.maps.Map;
    getMarkerFromValue(
        value: MapMarker['value']
    ): google.maps.Marker | undefined;
}

// https://github.com/jsx-eslint/eslint-plugin-react/issues/3475 – memo also results in the same
// eslint-disable-next-line @hostettler/memoize-component
const GoogleMapWithoutApi = (
    {
        className,
        options = {},
        onBoundsChange,
        onMarkerSelect,
        markers,
        shouldClusterMarkers = false,
    }: GoogleMapComponentProps,
    mapRef: ForwardedRef<MapRef | undefined>
) => {
    const ref = useRef<HTMLDivElement>(null);
    const [map, setMap] = useState<google.maps.Map>();
    const clusterer = useRef<MarkerClusterer | undefined>();

    const onMarkerSelectImmutable = useImmutableCallback(onMarkerSelect);

    const previousMapMarkers = useRef<
        [
            google.maps.Marker,
            google.maps.MapsEventListener | undefined,
            MapMarker,
        ][]
    >([]);

    previousMapMarkers.current = useMemo(() => {
        if (!markers) {
            return [];
        }

        if (!map) {
            return [];
        }

        if (shouldClusterMarkers && !clusterer.current) {
            clusterer.current = new MarkerClusterer({
                algorithm: new SuperClusterAlgorithm({
                    radius: 100,
                }),
                map,
                renderer: {
                    render: ({ count, position }) =>
                        new google.maps.Marker({
                            position,
                            icon: {
                                url: markerClusterIcon,
                                scaledSize: new google.maps.Size(75, 75),
                            },
                            label: {
                                text: String(count),
                                color: 'rgba(255,255,255,0.9)',
                                fontSize: '12px',
                            },
                            // adjust zIndex to be above other markers
                            zIndex:
                                Number(google.maps.Marker.MAX_ZINDEX) + count,
                        }),
                },
            });
        }

        return initMarkers({
            map,
            markers,
            shouldClusterMarkers,
            onMarkerSelect: onMarkerSelectImmutable,
            previousResult: previousMapMarkers.current,
            clusterer: clusterer.current,
        });
    }, [map, markers, onMarkerSelectImmutable, shouldClusterMarkers]);

    useEffect(() => {
        if (ref.current && !map) {
            setMap(
                new google.maps.Map(ref.current, {
                    zoom: 10,
                    ...options,
                })
            );
        }
    }, [ref, map, options]);

    const onBoundsChangeImmutable = useImmutableCallback(onBoundsChange);

    useEffect(() => {
        if (map) {
            const listener = map.addListener(
                'bounds_changed',
                throttle(() => {
                    onBoundsChangeImmutable(map.getBounds());
                }, 500)
            );
            return () => {
                listener.remove();
            };
        }

        return () => {
            // noop
        };
    }, [map, onBoundsChangeImmutable]);

    useImperativeHandle(
        mapRef,
        () => ({
            map,
            getMarkerFromValue: (value: MapMarker['value']) => {
                for (const [
                    marker,
                    ,
                    markerOptions,
                ] of previousMapMarkers.current) {
                    if (markerOptions.value === value) {
                        return marker;
                    }
                }

                return undefined;
            },
        }),
        [map]
    );

    return (
        <div
            data-test-class="googleMapContainer"
            className={className}
            ref={ref}
        />
    );
};

const GoogleMap = ComponentWithGoogleMap(forwardRef(GoogleMapWithoutApi));

export default memo(GoogleMap);
