import { memo, type ReactNode } from 'react';
import { clsx } from 'clsx';
import { FormattedMessage } from 'react-intl';
import { Button, Text, VStack } from '@library';
import {
    useCookieConsent,
    useCookieConsentContext,
} from '../context/CookieConsentContext';
import type { Consent, ConsentCategory } from '../context/CookieConsentService';
import styles from './ConsentRestriction.module.scss';

const ConsentRestriction = ({
    consent: requiredConsent,
    children,
    className,
}: {
    consent: Partial<Consent>;
    children: ReactNode;
    className?: string;
}) => {
    const { consent, initialized } = useCookieConsent();
    const { setSettingsOpened } = useCookieConsentContext();

    if (!initialized) {
        return <div className={className} />;
    }

    if (
        consent &&
        Object.keys(requiredConsent).every(
            key => consent[key as ConsentCategory]
        )
    ) {
        return <>{children}</>;
    }
    return (
        <div className={clsx(className, styles.container)}>
            <VStack gap="mini">
                <Text>
                    <FormattedMessage id="Cookie Restriction Text" />
                </Text>
                {consent !== null && (
                    <Button
                        onClick={event => {
                            setSettingsOpened(true);
                            event.stopPropagation();
                        }}
                        outline
                        justify="center"
                    >
                        <FormattedMessage id="Cookie Restriction OpenSettingsButton" />
                    </Button>
                )}
            </VStack>
        </div>
    );
};

export default memo(ConsentRestriction);
