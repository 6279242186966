import type { CSSProperties } from 'react';
import { memo, forwardRef } from 'react';
import { FormattedMessage } from 'react-intl';
import { clsx } from 'clsx';
import { Heading } from '@library';
import Button from '../../../core/components/Button';
import FeatureBadge from '../FeatureBadge';
import StoryblokResponsiveImage from '../../../content/components/PageComponents/StoryblokResponsiveImage';
import type { FeatureComponent } from '../../../content/types/customStoryblokComponents';
import StoryblokLink from '../../../content/components/PageComponents/Links/StoryblokLink';
import styles from './HeroTile.module.scss';

const HeroTile = forwardRef<
    HTMLDivElement,
    { content: FeatureComponent; height?: number }
>(({ content, height }, ref) => {
    return (
        <div
            ref={ref}
            className={clsx(styles.container, {
                [styles.staticSideBarContainer]: content.sideBarBackground,
            })}
            style={
                {
                    '--card-backdrop': content.sideBarBackground
                        ? `${content.sideBarBackground}`
                        : undefined,
                } as CSSProperties
            }
            id={content._uid}
        >
            <StoryblokLink
                className={clsx(styles.imageLink, {
                    [styles.staticSideBarImageLink]: content.sideBarBackground,
                })}
                link={content.link}
                showEmpty
            >
                <StoryblokResponsiveImage
                    className={styles.image}
                    image={content.image}
                    predefinedSet="fullWidth"
                    predefinedDefaultHeight={height}
                    onlyFocusCrop
                />
            </StoryblokLink>
            <FeatureBadge
                badge_image={content.badge_image}
                badge_position={content.badge_position}
                badge_target={content.badge_target}
            />
            {content.isNew && (
                <div className={styles.newIndicator}>
                    <FormattedMessage id="new" />
                </div>
            )}
            <div
                className={clsx(styles.infoContainer, {
                    [styles.staticSideBarInfoContainer]:
                        content.sideBarBackground,
                })}
            >
                <StoryblokLink
                    className={styles.title}
                    link={content.link}
                    showEmpty
                >
                    <Heading h1 bold hyphenated>
                        {content.title}
                    </Heading>
                </StoryblokLink>

                <div className={styles.description}>
                    <Heading h6 medium>
                        {content.description}
                    </Heading>
                </div>

                {content.button_label && (
                    <StoryblokLink className={styles.link} link={content.link}>
                        <Button primary>{content.button_label}</Button>
                    </StoryblokLink>
                )}
            </div>
        </div>
    );
});

export default memo(HeroTile);
