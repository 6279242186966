const getPlaceByInput = async (
    autoCompleteService: google.maps.places.AutocompleteService,
    search: string,
    container: HTMLDivElement | google.maps.Map
) => {
    const autocompleteResponse = await autoCompleteService.getPlacePredictions({
        input: search,
    });

    if (autocompleteResponse.predictions.length) {
        const placesService = new google.maps.places.PlacesService(container);
        return new Promise<google.maps.places.PlaceResult | null>(resolve => {
            placesService.getDetails(
                {
                    placeId: autocompleteResponse.predictions[0].place_id,
                    fields: ['geometry'],
                },
                result => {
                    resolve(result);
                }
            );
        });
    }
    return null;
};

export default getPlaceByInput;
