import { useContext } from 'react';
import PreorderModeContext from '../../preorder/PreorderModeContext';
import UserContext from '../components/UserContext';

const useUserBranches = () => {
    const { customer } = useContext(UserContext);
    const { isInPreorderMode } = useContext(PreorderModeContext);

    const branches = customer?.branches ?? [];

    return {
        locationCode: customer?.locationCode,
        mainStock: branches.find(item => item.isMainStock),
        branches: branches.filter(
            item => !isInPreorderMode || item.isMainStock
        ),
    };
};

export default useUserBranches;
