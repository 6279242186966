import type { ReactElement, RefObject } from 'react';
import {
    memo,
    cloneElement,
    useEffect,
    useLayoutEffect,
    useMemo,
    useRef,
    useState,
    Children,
} from 'react';
import { clsx } from 'clsx';
import { throttle } from 'lodash-es';
import useResizeObserver from '../hooks/dom/useResizeObserver';
import useImmutableCallback from '../hooks/useImmutableCallback';
import styles from './Carousel.module.scss';

export interface CarouselActions {
    nextItem: () => void;
    previousItem: () => void;
    goToItem: (itemIndex: number) => void;
    currentElement: number;
    showControls: boolean;
    showLeftControls: boolean;
    showRightControls: boolean;
}

interface CarouselProps {
    children: ReactElement | ReactElement[];
    loop?: boolean;
    centered?: boolean;
    buttonWidth?: number;
    observeCarouselSize?: boolean;
    onActiveItemChange?: (newItemIndex: number) => void;
    onChangeActions?: (actions: CarouselActions) => void;
}

const useCarouselActions = ({
    carouselRoot,
    firstChild,
    childrenCount,
    loop,
}: {
    carouselRoot: RefObject<HTMLDivElement>;
    firstChild: RefObject<HTMLElement>;
    childrenCount: number;
    loop: boolean;
}) => {
    const [currentElement, setCurrentElement] = useState(0);
    const nextItem = useImmutableCallback(() => {
        if (carouselRoot.current && firstChild.current) {
            const { scrollLeft, scrollWidth, clientWidth } =
                carouselRoot.current;
            const childClientWidth = firstChild.current.clientWidth;

            let nextElement = currentElement + 1;
            let newScrollPosition = scrollLeft + childClientWidth + 8;

            if (
                newScrollPosition >
                    scrollWidth - clientWidth + childClientWidth &&
                loop
            ) {
                newScrollPosition = 0;
                nextElement = 0;
            }

            if (nextElement === childrenCount) {
                if (!loop) {
                    return;
                }

                nextElement = 0;
            }

            setCurrentElement(nextElement);

            carouselRoot.current.scrollTo({
                left: nextElement === 0 ? 0 : newScrollPosition,
                behavior: 'smooth',
            });
        }
    });

    const previousItem = useImmutableCallback(() => {
        if (carouselRoot.current && firstChild.current) {
            const { scrollLeft, scrollWidth } = carouselRoot.current;
            const childClientWidth = firstChild.current.clientWidth;

            let nextElement = currentElement - 1;
            let newScrollPosition = scrollLeft - childClientWidth - 8;

            if (newScrollPosition < -childClientWidth && loop) {
                newScrollPosition = scrollWidth;
                nextElement = childrenCount - 1;
            }

            if (nextElement < 0) {
                if (!loop) {
                    return;
                }

                nextElement = childrenCount - 1;
            }

            setCurrentElement(nextElement);

            carouselRoot.current.scrollTo({
                left:
                    nextElement === childrenCount - 1
                        ? carouselRoot.current.scrollWidth
                        : newScrollPosition,
                behavior: 'smooth',
            });
        }
    });

    const goToItem = useImmutableCallback((itemIndex: number) => {
        if (carouselRoot.current && firstChild.current) {
            const { clientWidth } = firstChild.current;
            const newScrollPosition = (clientWidth + 8) * itemIndex;
            setCurrentElement(itemIndex);

            carouselRoot.current.scrollTo({
                left: newScrollPosition,
                behavior: 'smooth',
            });
        }
    });

    return {
        nextItem,
        previousItem,
        goToItem,
        currentElement,
    };
};

const Carousel = ({
    children,
    loop = false,
    centered = false,
    onActiveItemChange,
    onChangeActions,
    observeCarouselSize,
    buttonWidth = 0,
}: CarouselProps) => {
    const options = useRef<{
        showControls: boolean;
    }>({
        showControls: false,
    });
    const carouselRoot = useRef<HTMLDivElement>(null);

    const childrenCount = Children.count(children);
    const firstChild = useRef<HTMLElement>(null);

    const { nextItem, previousItem, currentElement, goToItem } =
        useCarouselActions({
            carouselRoot,
            firstChild,
            loop,
            childrenCount,
        });

    const onKeyDown = (event: React.KeyboardEvent) => {
        if (event.key === 'ArrowRight') {
            event.preventDefault();
            nextItem();
        } else if (event.key === 'ArrowLeft') {
            event.preventDefault();
            previousItem();
        }
    };

    useEffect(() => {
        if (onActiveItemChange) {
            onActiveItemChange(currentElement);
        }
    }, [onActiveItemChange, currentElement]);

    const setActions = useImmutableCallback(
        ({
            isRightScrollExisted,
            isLeftScrollExisted,
        }: {
            isLeftScrollExisted: boolean;
            isRightScrollExisted: boolean;
        }) => {
            if (carouselRoot.current) {
                const { scrollWidth, clientWidth } = carouselRoot.current;

                const buttonsWidth = options.current.showControls
                    ? buttonWidth * 2
                    : 0;

                const showControls = scrollWidth > clientWidth + buttonsWidth;

                options.current.showControls = showControls;
                if (onChangeActions) {
                    onChangeActions({
                        currentElement,
                        nextItem,
                        previousItem,
                        goToItem,
                        showControls,
                        showLeftControls: isLeftScrollExisted,
                        showRightControls: isRightScrollExisted,
                    });
                }
            }
        }
    );

    const handleScroll = useImmutableCallback(() => {
        if (carouselRoot.current) {
            const { scrollLeft, clientWidth, scrollWidth } =
                carouselRoot.current;
            const isRight =
                scrollWidth > clientWidth &&
                scrollLeft + clientWidth < scrollWidth;
            const isLeft = scrollWidth > clientWidth && scrollLeft !== 0;
            setActions({
                isRightScrollExisted: isRight,
                isLeftScrollExisted: isLeft,
            });
        }
    });

    useLayoutEffect(() => {
        handleScroll();
    }, [handleScroll, currentElement]);

    useResizeObserver(
        carouselRoot,
        () => {
            handleScroll();
        },
        !!observeCarouselSize
    );

    const handleScrollThrottle = useMemo(() => {
        return throttle(() => {
            handleScroll();
        }, 100);
    }, [handleScroll]);

    return (
        <div
            role="slider"
            aria-valuemin={1}
            aria-valuemax={childrenCount}
            aria-valuenow={currentElement + 1}
            tabIndex={0}
            className={clsx(styles.carousel, {
                [styles.centeredCarousel]: centered,
                [styles.noOutline]: childrenCount <= 1,
            })}
            ref={carouselRoot}
            onKeyDown={onKeyDown}
            onScroll={handleScrollThrottle}
        >
            {Children.map(children, (child, index) =>
                index === 0
                    ? cloneElement(child, { ref: firstChild, key: index })
                    : cloneElement(child, { key: index })
            )}
        </div>
    );
};

export default memo(Carousel);
