const toVimeoEmbedLink = (value: string) => {
    const url = new URL(value);

    if (url.host === 'player.vimeo.com' && url.pathname.startsWith('/video')) {
        return value;
    }
    if (url.host === 'vimeo.com' && url.pathname.length > 1) {
        url.search = '';
        url.pathname = `/video${url.pathname}`;
        url.host = 'player.vimeo.com';
        return url.toString();
    }
    return undefined;
};

export default toVimeoEmbedLink;
