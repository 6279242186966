import { memo, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Modal } from '@library';
import SuccessMessage from '../../../core/components/SuccessMessage';
import { SignInForm } from '../SignInForm';
import RequestResetPasswordForm from '../RequestResetPasswordForm';
import styles from './UserMenuModals.module.scss';

const UserMenuModals = ({
    onClose = () => {},
    onUserLogin = () => {},
}: {
    onClose?: () => void;
    onUserLogin?: () => void;
}) => {
    const [modalState, setModalState] = useState({
        signInModal: true,
        resetPasswordModal: false,
        confirmationModal: false,
    });

    const [username, setUsername] = useState('');

    const { signInModal, resetPasswordModal, confirmationModal } = modalState;

    const onRequestPasswordChange = useCallback(
        (value: string) => {
            setUsername(value);
            setModalState({
                ...modalState,
                signInModal: false,
                resetPasswordModal: true,
            });
        },
        [setUsername, setModalState, modalState]
    );

    if (signInModal) {
        const onSignInModalClose = () => {
            setModalState({ ...modalState, signInModal: false });
            onClose();
        };
        return (
            <Modal
                open
                onOpenChange={onSignInModalClose}
                data-test-class="login-modal"
            >
                <div className={styles.modal}>
                    <SignInForm
                        onResetPasswordPress={() => {
                            setModalState({
                                ...modalState,
                                signInModal: false,
                                resetPasswordModal: true,
                            });
                        }}
                        onSubmit={() => {
                            setModalState({
                                ...modalState,
                                signInModal: false,
                            });
                            onClose();
                            onUserLogin();
                        }}
                        onRequestPasswordChange={onRequestPasswordChange}
                        onClose={onSignInModalClose}
                    />
                </div>
            </Modal>
        );
    }

    if (resetPasswordModal) {
        return (
            <Modal
                open
                onOpenChange={() => {
                    setModalState({
                        ...modalState,
                        resetPasswordModal: false,
                    });
                    onClose();
                }}
            >
                <div className={styles.modal}>
                    <RequestResetPasswordForm
                        onSuccess={() => {
                            setModalState({
                                ...modalState,
                                resetPasswordModal: false,
                                confirmationModal: true,
                            });
                            setUsername('');
                        }}
                        username={username}
                        isRequired={!!username}
                        onBackToLoginPress={() => {
                            setModalState({
                                ...modalState,
                                resetPasswordModal: false,
                                signInModal: true,
                            });
                            setUsername('');
                        }}
                    />
                </div>
            </Modal>
        );
    }

    if (confirmationModal) {
        return (
            <Modal
                open
                onOpenChange={() => {
                    setModalState({
                        ...modalState,
                        confirmationModal: false,
                    });
                    onClose();
                }}
            >
                <div className={styles.modal}>
                    <SuccessMessage
                        onClose={() =>
                            setModalState({
                                ...modalState,
                                confirmationModal: false,
                                signInModal: true,
                            })
                        }
                        message={
                            <FormattedMessage id="the password reset link has been sent to you" />
                        }
                        actionText={
                            <FormattedMessage
                                id="back to <link>login</link>"
                                values={{
                                    link: chunks => chunks,
                                }}
                            />
                        }
                    />
                </div>
            </Modal>
        );
    }

    return null;
};

export default memo(UserMenuModals);
