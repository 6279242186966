import { memo, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { clsx } from 'clsx';
import {
    Text,
    Button,
    HStack,
    ProfileIcon,
    ChevronDownIcon,
    TooltipStateLess,
    VStack,
} from '@library';
import useTabletView from '../../../../core/hooks/dom/useTabletView';
import SidePaneAccountMenu from '../../../../core/components/header/SidePaneAccountMenu';
import useCurrentCustomer from '../../../../core/hooks/useCurrentCustomer';
import useOutsideClick from '../../../../core/hooks/dom/useOutsideClick';
import useCurrentUser from '../../../../core/hooks/useCurrentUser';
import CurrentStoreLink from '../../../../core/components/router/CurrentStoreLink';
import ClickableArea from '../../../../core/components/button/ClickableArea';
import useLogout from '../../../../core/hooks/useLogout';
import styles from './UserMenuLoggedIn.module.css';

const AccountMenuLinks: {
    labelId: string;
    to: string;
    medium?: boolean;
}[] = [
    { to: 'account', labelId: 'overview', medium: true },
    { to: 'account/user-data', labelId: 'your data' },
    { to: 'account/prices', labelId: 'your prices and conditions' },
    {
        to: 'account/back-orders',
        labelId: 'backorder',
    },
    {
        to: 'account/order-history',
        labelId: 'order history',
    },
    {
        to: 'account/track-trace',
        labelId: 'Track & Trace',
    },
];

const UserMenuLoggedIn = memo(() => {
    const [showSidePane, setShowSidePane] = useState(false);
    const isTabletView = useTabletView();

    const { name } = useCurrentCustomer() ?? {};

    return (
        <>
            {isTabletView ? (
                <Button
                    text
                    inline
                    white
                    maxContent
                    onClick={() => setShowSidePane(true)}
                >
                    <HStack gap="micro" align="center">
                        <Text
                            hoverBold
                            primary
                            lineHeightAsFontSize
                            data-text={name}
                        >
                            {name}
                        </Text>
                        <ChevronDownIcon />
                    </HStack>
                </Button>
            ) : (
                <AccountDropdownMenu />
            )}
            {showSidePane && (
                <SidePaneAccountMenu onClose={() => setShowSidePane(false)} />
            )}
        </>
    );
});

const AccountDropdownMenu = memo(() => {
    const containerRef = useRef<HTMLDivElement>(null);
    const [isOpen, setIsOpen] = useState(false);
    const { name } = useCurrentCustomer() ?? {};
    const { username } = useCurrentUser() ?? {};

    const hide = () => setIsOpen(false);

    const logout = useLogout();
    const onLogoutPress = async () => {
        hide();
        logout();
    };

    useOutsideClick(containerRef, () => {
        setIsOpen(false);
    });
    return (
        <TooltipStateLess
            open={isOpen}
            onOpenChange={value => {
                setIsOpen(value);
            }}
            mode="click"
            theme={styles.tooltipTheme}
            placement={'bottom-end'}
            content={
                <div className={styles.container}>
                    <div className={styles.wrapper}>
                        <VStack>
                            <div className={styles.item}>
                                <VStack gap="micro">
                                    <Text menuButton>{name}</Text>
                                    <Text primary>{username}</Text>
                                </VStack>
                            </div>
                            {AccountMenuLinks.map(link => (
                                <CurrentStoreLink
                                    key={link.to}
                                    to={link.to}
                                    onClick={hide}
                                    className={clsx(styles.item, styles.link)}
                                    data-test-class="accountMenuDropdownItem"
                                >
                                    <Text primary>
                                        <FormattedMessage id={link.labelId} />
                                    </Text>
                                </CurrentStoreLink>
                            ))}

                            <ClickableArea
                                onClick={onLogoutPress}
                                className={clsx(styles.item, styles.link)}
                                data-test-class="accountMenuDropdownItem"
                                data-test-id="account-dropdown-menu-logout"
                            >
                                <Text primary>
                                    <FormattedMessage id="sign out" />
                                </Text>
                            </ClickableArea>
                        </VStack>
                    </div>
                </div>
            }
            matchWidth={259}
        >
            <Button white text inline maxContent>
                <HStack gap="mini" align="center">
                    <ProfileIcon size={20} />
                    <Text hoverBold primary data-text={name}>
                        {name}
                    </Text>
                    <ChevronDownIcon />
                </HStack>
            </Button>
        </TooltipStateLess>
    );
});

export default UserMenuLoggedIn;
