import { memo, useCallback } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import type { ApolloError } from '@apollo/client';
import { isApolloError } from '@apollo/client';
import { Link } from 'react-router-dom';
import {
    Button,
    Heading,
    Text,
    Checkbox,
    TextField,
    VStack,
    HStack,
    FormField,
} from '@library';
import useLogin from '../../core/hooks/useLogin';

import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import useForm from '../../core/hooks/form/useForm';
import useCurrentStore from '../../core/hooks/useCurrentStore';
import type { ValidationSchema } from '../../core/hooks/form/types';
import useStoreInformation from '../../content/queries/useStoreInformation';
import styles from './SignInForm.module.scss';

const PolicyMessage = memo(() => {
    const { data } = useStoreInformation();
    return (
        <Text secondary>
            <FormattedMessage
                id="by registering, you agree to the <policy>privacy policy</policy> and the <terms>terms and conditions</terms>"
                values={{
                    policy: chunks => (
                        <a
                            target="_blank"
                            href={data?.data_protection?.filename}
                            className={styles.link}
                            rel="noreferrer"
                        >
                            {chunks}
                        </a>
                    ),
                    terms: chunks => (
                        <a
                            target="_blank"
                            href={data?.terms?.filename}
                            className={styles.link}
                            rel="noreferrer"
                        >
                            {chunks}
                        </a>
                    ),
                }}
            />
        </Text>
    );
});

const RegisterLink = memo(({ onClose }: { onClose(): void }) => {
    const { storeRootPath } = useStoreAndLanguageParams();
    const { customerOnboardingUrl } = useCurrentStore();

    if (!customerOnboardingUrl) {
        return null;
    }

    return (
        <div className={styles.createAccountText}>
            <Text center secondary>
                <FormattedMessage
                    id="click <link>here</link> to create a new account"
                    values={{
                        link: chunks => (
                            <Link
                                to={`${storeRootPath}/onboarding`}
                                className={styles.link}
                                onClick={onClose}
                            >
                                {chunks}
                            </Link>
                        ),
                    }}
                />
            </Text>
        </div>
    );
});

interface SignInFormValues {
    username: string;
    password: string;
    stayLoggedIn: boolean;
}

interface SignInFormProps {
    onSubmit: () => void;
    onResetPasswordPress: () => void;
    onRequestPasswordChange: (value: string) => void;
    onClose(): void;
}

const validationSchema: ValidationSchema<SignInFormValues> = {
    username: 'required',
    password: 'required',
};

const useSignInOnSubmit = (
    login: (
        username: string,
        password: string,
        stayLoggedIn: boolean
    ) => Promise<void>,
    onRequestPasswordChange: (value: string) => void
) =>
    useCallback(
        async (formValues: SignInFormValues) => {
            try {
                await login(
                    formValues.username,
                    formValues.password,
                    formValues.stayLoggedIn
                );
            } catch (ex: unknown) {
                if (isApolloError(ex as Error)) {
                    const apolloError = ex as ApolloError;
                    if (
                        apolloError.graphQLErrors[0]?.extensions?.issue ===
                        'requiresPasswordChange'
                    ) {
                        onRequestPasswordChange(formValues.username);
                    }
                }
                return false;
            }

            return true;
        },
        [login, onRequestPasswordChange]
    );

export const SignInForm = memo(
    ({
        onSubmit,
        onResetPasswordPress,
        onRequestPasswordChange,
        onClose,
    }: SignInFormProps) => {
        const intl = useIntl();
        const { useEmailAddressLabelForUsername } = useCurrentStore();
        const [login] = useLogin();

        const { values, isSubmitting, handleSubmit, handleChange, errors } =
            useForm<SignInFormValues>({
                initialState: {
                    username: '',
                    password: '',
                    stayLoggedIn: false,
                },
                onSuccess: onSubmit,
                onSubmit: useSignInOnSubmit(login, onRequestPasswordChange),
                validationSchema,
            });

        return (
            <form className={styles.content} onSubmit={handleSubmit}>
                <div className={styles.title}>
                    <Heading h3 center>
                        <FormattedMessage id="please sign in" />
                    </Heading>
                </div>
                {errors.submissionError && (
                    <div className={styles.error}>
                        <Text secondary>
                            <FormattedMessage id="login failed" />
                        </Text>
                    </div>
                )}
                <VStack gap="small">
                    <FormField
                        error={
                            errors.username && (
                                <FormattedMessage id={errors.username} />
                            )
                        }
                    >
                        <TextField
                            data-test-class="signInFormUserInput"
                            autoFocus
                            autoComplete="Username"
                            value={values.username}
                            error={!!errors.username}
                            id="username"
                            name="username"
                            onChange={value => {
                                handleChange('username', value);
                            }}
                            placeholder={intl.formatMessage({
                                id: useEmailAddressLabelForUsername
                                    ? 'email address'
                                    : 'username',
                            })}
                        />
                    </FormField>

                    <FormField
                        data-test-class="password-input"
                        error={
                            errors.password && (
                                <FormattedMessage id={errors.password} />
                            )
                        }
                    >
                        <TextField
                            id="password"
                            autoComplete="Password"
                            value={values.password}
                            error={!!errors.password}
                            type="password"
                            onChange={value => {
                                handleChange('password', value);
                            }}
                            name="username"
                            placeholder={intl.formatMessage({
                                id: 'password',
                            })}
                        />
                    </FormField>

                    <HStack align="center" justify="spaceBetween">
                        <Checkbox
                            checked={values.stayLoggedIn}
                            value="stayLoggedIn"
                            onChange={value =>
                                handleChange('stayLoggedIn', value)
                            }
                        >
                            <FormattedMessage id="stay logged in" />
                        </Checkbox>

                        <Button
                            text
                            inline
                            secondary
                            onClick={onResetPasswordPress}
                        >
                            <Text secondary uppercase>
                                <FormattedMessage id="forgot your password?" />
                            </Text>
                        </Button>
                    </HStack>

                    <PolicyMessage />
                </VStack>
                <div className={styles.button}>
                    <Button
                        type="submit"
                        loading={isSubmitting}
                        onClick={handleSubmit}
                    >
                        <FormattedMessage id="sign in" />
                    </Button>
                </div>
                <RegisterLink onClose={onClose} />
            </form>
        );
    }
);
