import type { MarkerClusterer } from '@googlemaps/markerclusterer';
import type { MapMarker } from '../../components/maps/GoogleMap';
import plainObjectsEqual from '../plainObjectsEqual';

const computeMarkerFromOptions = ({
    map,
    markerOptions,
    onMarkerSelect,
    remainingPreviousResults,
    shouldClusterMarkers,
}: {
    map: google.maps.Map;
    clusterer?: MarkerClusterer;
    markerOptions: MapMarker;
    onMarkerSelect?: (event: {
        value: unknown;
        marker: google.maps.Marker;
    }) => void;
    remainingPreviousResults: [
        google.maps.Marker,
        google.maps.MapsEventListener | undefined,
        MapMarker,
    ][];
    shouldClusterMarkers?: boolean;
}) => {
    const previousMarkerIndex = remainingPreviousResults.findIndex(
        ([, , item]) => plainObjectsEqual(item, markerOptions, ['icon'])
    );

    if (previousMarkerIndex !== -1) {
        const entry = remainingPreviousResults.splice(
            previousMarkerIndex,
            1
        )[0] as [google.maps.Marker, undefined, MapMarker];

        /* istanbul ignore next -- @preserve */
        if (entry[0].getIcon() !== markerOptions.icon) {
            entry[0].setIcon(markerOptions.icon);
        }

        return entry;
    }

    const { title, latitude, longitude, icon, value } = markerOptions;

    const marker = new google.maps.Marker({
        title,
        position: {
            lat: latitude,
            lng: longitude,
        },
        icon,
        map: shouldClusterMarkers ? null : map,
    });

    if (onMarkerSelect) {
        /* istanbul ignore next -- @preserve */
        const listener = marker.addListener('click', () =>
            /* istanbul ignore next -- @preserve */
            onMarkerSelect({ value, marker })
        );
        return [marker, listener, markerOptions] as [
            google.maps.Marker,
            google.maps.MapsEventListener,
            MapMarker,
        ];
    }

    return [marker, undefined, markerOptions] as [
        google.maps.Marker,
        undefined,
        MapMarker,
    ];
};

export default computeMarkerFromOptions;
