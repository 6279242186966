import { memo } from 'react';
import ComponentSuspense from '../../../../core/components/router/ComponentSuspense';
import RestrictedContent from '../../../../core/components/router/RestrictedContent';
import useLoginState from '../../../../core/hooks/useLoginState';
import LazyComponent from '../../../../core/components/router/LazyComponent';
import CartLoggedOut from './CartLoggedOut';

const CartLoggedIn = LazyComponent(async () => import('./CartLoggedIn'));

export default memo(() => {
    const isLoggedIn = useLoginState();

    return (
        <RestrictedContent
            storeFlags={isLoggedIn ? undefined : 'cartSharingEnabled'}
        >
            {isLoggedIn ? (
                <ComponentSuspense height="32px">
                    <CartLoggedIn />
                </ComponentSuspense>
            ) : (
                <CartLoggedOut />
            )}
        </RestrictedContent>
    );
});
