import { useCallback, useMemo, useState } from 'react';
import validate from '../../util/validate';
import useImmutableCallback from '../useImmutableCallback';
import type { ValidationSchema, Touched, FormErrors } from './types';

export interface FormFields<T> {
    onChange: (e: { target: { value: T } }) => void;
    onBlur: (e: { target: { id: string } }) => void;
    value: T;
    id: string;
    name: string;
    error?: string;
}

const getHtmlAttributeValue = <T>(values: T, field: keyof T) => {
    const formValue = values[field];
    if (
        typeof formValue !== 'string' &&
        typeof formValue !== 'boolean' &&
        typeof formValue !== 'number'
    ) {
        // eslint-disable-next-line no-console
        console.warn('generation fields possible only for plain values');
        return '';
    }
    return formValue.toString();
};

/* Handle change of single field */
export type HandleChangeField<T> = (field: keyof T, value: T[keyof T]) => void;

/* Handle bulk changes
   @param { string[]} unTouchedFields - if you want to leave some fields untouched by user for better UX.
*/
export type HandleChangeFields<T> = (
    values: Partial<T>,
    unTouchedFields?: (keyof T)[]
) => void;

const useFormFields = <T extends object>({
    validationSchema,
    values: formValues,
    updateFields,
}: {
    initialState: T;
    validationSchema: ValidationSchema<T>;
    values: T;
    updateFields(values: Partial<T>): void;
}) => {
    const [touched, setTouched] = useState<Touched<T>>({});
    const [touchedFieldErrors, setTouchedFieldErrors] = useState<FormErrors<T>>(
        {}
    );

    const currentValidateResult = useMemo(() => {
        return validate(formValues, validationSchema);
    }, [formValues, validationSchema]);

    const handleChange: HandleChangeField<T> = (field, value) => {
        handleChanges({ [field]: value } as Partial<T>);
    };

    const handleChanges: HandleChangeFields<T> = (
        values,
        unTouchedFields = []
    ) => {
        handleTouched(
            (Object.keys(values) as (keyof T)[]).filter(
                key => !unTouchedFields.includes(key)
            )
        );
        const validateResult = validate(
            { ...formValues, ...values },
            validationSchema
        );

        const touchedErrors = (Object.keys(values) as (keyof T)[]).reduce(
            (result, key) => {
                return {
                    ...result,
                    [key]: validateResult.errors[key],
                };
            },
            {}
        );

        setTouchedFieldErrors(prevValues => ({
            ...prevValues,
            ...touchedErrors,
        }));
        updateFields(values);
    };

    const handleTouched = useCallback(
        (fields: (keyof T)[]) => {
            const untouched = fields.filter(field => !touched[field]);
            if (untouched.length) {
                const touchedFields = Object.fromEntries(
                    untouched.map(field => [field, true])
                );

                setTouched(prevValues => ({ ...prevValues, ...touchedFields }));
            }
        },
        [touched, setTouched]
    );

    const setAllTouched = useImmutableCallback(() => {
        setTouched(
            Object.keys(formValues).reduce((result, key) => {
                return { ...result, [key as keyof T]: true };
            }, {} as Touched<T>)
        );

        const validateResult = validate({ ...formValues }, validationSchema);
        setTouchedFieldErrors(validateResult.errors);
    });

    const handleBlur = useCallback(
        (e: { target: { id: string } }) => {
            const name = e.target.id as keyof T;
            const validateResult = validate(
                {
                    ...formValues,
                    ...({ [name]: formValues[name] } as Partial<T>),
                },
                validationSchema
            );
            setTouchedFieldErrors(prevValues => ({
                ...prevValues,
                [name]: validateResult.errors[name],
            }));
            handleTouched([name]);
        },
        [handleTouched, setTouchedFieldErrors, formValues, validationSchema]
    );

    const generateFormFieldProps = (field: keyof T) => {
        const htmlValue = getHtmlAttributeValue(formValues, field);
        return {
            onChange: (e: { target: { value: T[keyof T] } }) =>
                handleChange(field, e.target.value),
            onBlur: handleBlur,
            value: htmlValue,
            id: field,
            name: field,
            error: touchedFieldErrors[field],
        };
    };

    const hasError = Object.keys(touchedFieldErrors).some(
        key => touchedFieldErrors[key as keyof typeof touchedFieldErrors]
    );

    return {
        generateFormFieldProps,
        values: formValues,
        handleBlur,
        errors: touchedFieldErrors,
        setErrors: setTouchedFieldErrors,
        hasError,
        currentErrors: currentValidateResult.errors,
        valid: currentValidateResult.valid,
        touched,
        setAllTouched,
        handleChange,
        handleChanges,
    };
};

export default useFormFields;
