import { memo } from 'react';
import { FormattedMessage } from 'react-intl';

import { HStack, ResponsiveModal, useLayoutContext } from '@library';
import ContactForm from './ContactForm';
import styles from './ContactFormModal.module.scss';
import ContactInfo from './ContactInfo';

const ContactFormModal = ({
    open,
    onClose,
}: {
    open: boolean;
    onClose: (success?: boolean) => void;
}) => {
    const { isInMobileView } = useLayoutContext();

    return (
        <ResponsiveModal
            open={open}
            onOpenChange={onClose}
            header={isInMobileView && <FormattedMessage id="contact us" />}
        >
            <div className={styles.container}>
                <HStack gap="medium">
                    {!isInMobileView && (
                        <>
                            <div className={styles.contactCardColumn}>
                                <div className={styles.title}>
                                    <FormattedMessage id="contact us" />
                                </div>
                                <ContactInfo />
                            </div>
                            <div className={styles.divider} />
                        </>
                    )}
                    <div className={styles.formColumn}>
                        <ContactForm
                            onSuccess={() => {
                                onClose(true);
                            }}
                            onCancel={() => {
                                onClose(false);
                            }}
                        />
                    </div>
                </HStack>
            </div>
        </ResponsiveModal>
    );
};

export default memo(ContactFormModal);
