import { memo } from 'react';
import type { IconProps } from './types';
import BaseIcon from './BaseIcon';

export default memo((props: IconProps) => {
    return (
        <BaseIcon viewBox="0 0 28 28" fill="none" {...props}>
            <path
                d="M13.2267 18.2267L21 26C21.6667 26.6454 22.5603 27.0028 23.4881 26.9953C24.4159 26.9878 25.3036 26.6158 25.9597 25.9597C26.6158 25.3036 26.9877 24.4159 26.9953 23.4881C27.0028 22.5603 26.6454 21.6667 26 21L18.164 13.164M13.2267 18.2267L16.5547 14.1867C16.9773 13.6747 17.5413 13.352 18.1653 13.1653C18.8987 12.9467 19.716 12.9147 20.4893 12.9787C21.5318 13.0682 22.5795 12.8835 23.5285 12.4431C24.4776 12.0026 25.2949 11.3217 25.8995 10.4677C26.5041 9.61383 26.8749 8.61666 26.9751 7.5752C27.0753 6.53373 26.9014 5.48417 26.4707 4.53067L22.1027 8.9C21.3719 8.73102 20.7032 8.36022 20.1728 7.82984C19.6424 7.29945 19.2716 6.63079 19.1027 5.9L23.4707 1.532C22.5172 1.10128 21.4676 0.927392 20.4261 1.02758C19.3847 1.12776 18.3875 1.49855 17.5336 2.10313C16.6797 2.70772 15.9987 3.52508 15.5582 4.47413C15.1178 5.42317 14.9331 6.4709 15.0227 7.51333C15.144 8.948 14.928 10.532 13.8173 11.4467L13.6813 11.56M13.2267 18.2267L7.02 25.764C6.71918 26.1307 6.34493 26.4303 5.92132 26.6436C5.49772 26.8569 5.03414 26.9791 4.56044 27.0025C4.08673 27.0258 3.61339 26.9496 3.17089 26.779C2.72838 26.6083 2.32651 26.3469 1.99115 26.0115C1.65578 25.6761 1.39435 25.2743 1.22368 24.8318C1.05301 24.3893 0.976886 23.9159 1.0002 23.4422C1.02352 22.9685 1.14576 22.5049 1.35906 22.0813C1.57236 21.6577 1.87199 21.2835 2.23866 20.9827L11.3547 13.476L5.87866 8H4L0.999996 3L3 1L8 4V5.87867L13.68 11.5587L11.3533 13.4747M22.5 22.5L19 19M4.48933 23.5H4.5V23.5107H4.48933V23.5Z"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </BaseIcon>
    );
});
