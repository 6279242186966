import { memo } from 'react';
const LinkedinIcon = () => {
    return (
        <svg
            id="Group_3206"
            data-name="Group 3206"
            xmlns="http://www.w3.org/2000/svg"
            width="23"
            height="22"
            viewBox="0 0 27.955 26.721"
        >
            <defs>
                <clipPath id="clip-path-linkedin">
                    <rect
                        id="Rectangle_3871"
                        data-name="Rectangle 3871"
                        width="27.955"
                        height="26.721"
                        fill="none"
                    />
                </clipPath>
            </defs>
            <g
                id="Group_3205"
                data-name="Group 3205"
                clipPath="url(#clip-path-linkedin)"
            >
                <path
                    id="Path_1870"
                    data-name="Path 1870"
                    d="M6.346,26.721V8.691H.354v18.03H6.346Zm-3-20.491A3.125,3.125,0,1,0,3.391,0a3.124,3.124,0,1,0-.079,6.23h.039ZM9.663,26.721h5.992V16.653a4.107,4.107,0,0,1,.2-1.462A3.28,3.28,0,0,1,18.928,13c2.168,0,3.036,1.653,3.036,4.077v9.644h5.992V16.383c0-5.538-2.956-8.115-6.9-8.115a5.97,5.97,0,0,0-5.441,3.037h.04V8.692H9.664c.078,1.691,0,18.03,0,18.03Z"
                    fill="currentColor"
                />
            </g>
        </svg>
    );
};

export default memo(LinkedinIcon);
