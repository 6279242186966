import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { Button, Heading, TextBlock, VStack, NotFoundIcon } from '@library';
import Container from '../Container';
import styles from './PageNotFound.module.css';

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <Container data-test-class="pageNotFound">
            <div className={styles.container}>
                <VStack gap="gap24" align="center" justify="center">
                    <NotFoundIcon />
                    <VStack justify="center" align="center" gap="gap14">
                        <Heading h3 center dataTestClass={'notFoundTitle'}>
                            <FormattedMessage id="PageNotFound Title" />
                        </Heading>
                        <TextBlock
                            center
                            data-test-class={'notFoundDescription'}
                        >
                            <FormattedMessage id="PageNotFound Description" />
                        </TextBlock>
                    </VStack>
                    <Button
                        onClick={() => navigate('/')}
                        data-test-class="backToHomepage"
                    >
                        <FormattedMessage id="back to homepage" />
                    </Button>
                </VStack>
            </div>
        </Container>
    );
};

export default memo(PageNotFound);
