import { memo } from 'react';
import { clsx } from 'clsx';
import type { FeatureComponent } from '../../content/types/customStoryblokComponents';
import StoryblokLink from '../../content/components/PageComponents/Links/StoryblokLink';
import StoryblokResponsiveImage from '../../content/components/PageComponents/StoryblokResponsiveImage';
import styles from './FeatureBadge.module.scss';

const FeatureBadge = ({
    size = 'medium',
    badge_image,
    badge_position,
    badge_target,
}: Pick<FeatureComponent, 'badge_image' | 'badge_position' | 'badge_target'> & {
    size?: 'small' | 'medium';
}) => {
    if (!badge_image) {
        return null;
    }

    const width = size === 'medium' ? 160 : 110;

    const image = (
        <StoryblokResponsiveImage
            className={styles.image}
            image={badge_image}
            srcSets={[
                {
                    width,
                    height: 0,
                    screenWidth: '1x',
                },
                {
                    width: width * 2,
                    height: 0,
                    screenWidth: '2x',
                },
            ]}
        />
    );

    return (
        <div
            className={clsx(styles.badgeImage, {
                [styles.badgeImageBottomLeft]: badge_position === 'bottom-left',
                [styles.badgeImageTopLeft]: badge_position === 'top-left',
                [styles.badgeImageSmall]: size === 'small',
            })}
        >
            <StoryblokLink link={badge_target} showEmpty>
                {image}
            </StoryblokLink>
        </div>
    );
};
export default memo(FeatureBadge);
