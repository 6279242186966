import { memo } from 'react';
const YoutubeIcon = () => {
    return (
        <svg
            id="Group_3200"
            data-name="Group 3200"
            xmlns="http://www.w3.org/2000/svg"
            width="32"
            height="22"
            viewBox="0 0 35.82 25.237"
        >
            <defs>
                <clipPath id="clip-path-youtube">
                    <rect
                        id="Rectangle_3868"
                        data-name="Rectangle 3868"
                        width={35.82}
                        height={25.237}
                        fill="#fff"
                    />
                </clipPath>
            </defs>
            <g
                id="Group_3199"
                data-name="Group 3199"
                clipPath="url(#clip-path-youtube)"
            >
                <path
                    id="Path_1858"
                    data-name="Path 1858"
                    d="M35.071,3.941A4.5,4.5,0,0,0,31.9.753C29.111,0,17.91,0,17.91,0S6.709,0,3.915.753A4.5,4.5,0,0,0,.748,3.941C0,6.752,0,12.618,0,12.618S0,18.484.748,21.3a4.5,4.5,0,0,0,3.167,3.188c2.793.753,13.995.753,13.995.753s11.2,0,13.995-.753A4.5,4.5,0,0,0,35.071,21.3c.749-2.811.749-8.678.749-8.678s0-5.866-.749-8.678"
                    transform="translate(0 0)"
                    fill="currentColor"
                />
                <path
                    id="Path_1859"
                    data-name="Path 1859"
                    d="M70,46.483l9.362-5.326L70,35.831Z"
                    transform="translate(-55.753 -28.538)"
                />
            </g>
        </svg>
    );
};

export default memo(YoutubeIcon);
