import { memo, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import {
    Button,
    ChevronLeftIcon,
    ChevronRightIcon,
    CloseIcon,
    Heading,
    HStack,
    SidePane,
    Text,
    TextBlock,
    VStack,
} from '@library';
import flattenTree from '../../../../brand/util/flattenTree';
import useCategoriesTree from '../../../../brand/queries/useCategoriesTree';
import useCustomNavigate from '../../../../core/hooks/router/useCustomNavigate';
import styles from './CategoriesSidePane.module.css';

export default memo(
    ({ goToMenu, onClose }: { goToMenu: () => void; onClose: () => void }) => {
        const intl = useIntl();
        const { tree } = useCategoriesTree();
        const navigate = useCustomNavigate();

        const categories = useMemo(
            () =>
                flattenTree(tree).map(item => {
                    return {
                        ...item,
                        to: `/categories/${item.id}/page/1`,
                        childrenExist: !!item.children.length,
                    };
                }),
            [tree]
        );

        const mainCategories = categories.filter(({ parentId }) => !parentId);
        const [selectedCategoryId, setCategoryId] = useState<string>();
        const selectedCategory = categories.find(
            category => category.id === selectedCategoryId
        );
        const parentCategory = categories.find(
            category => category.id === selectedCategory?.parentId
        );
        const items = selectedCategory
            ? selectedCategory.children
            : mainCategories;

        return (
            <SidePane
                side="left"
                hiddenCloseButton
                open
                onOpenChange={goToMenu}
            >
                <div className={styles.container}>
                    <VStack>
                        <VStack className={styles.header} gap="small">
                            <HStack align="center" justify="spaceBetween">
                                <Button
                                    inline
                                    text
                                    onClick={() => {
                                        if (selectedCategoryId) {
                                            setCategoryId(
                                                parentCategory?.id || ''
                                            );
                                        } else {
                                            goToMenu();
                                        }
                                    }}
                                >
                                    <HStack align="center" gap="gap16">
                                        <ChevronLeftIcon />
                                        <Text menuButton>
                                            {selectedCategory &&
                                                (selectedCategory.parentId ? (
                                                    <FormattedMessage
                                                        id="Header Menu Back To Category"
                                                        values={{
                                                            categoryName:
                                                                parentCategory?.name,
                                                        }}
                                                    />
                                                ) : (
                                                    <FormattedMessage id="Header Menu Back To All Categories" />
                                                ))}

                                            {!selectedCategoryId && (
                                                <FormattedMessage id="Header Menu Back Menu" />
                                            )}
                                        </Text>
                                    </HStack>
                                </Button>

                                <Button inline text onClick={goToMenu}>
                                    <CloseIcon mini />
                                </Button>
                            </HStack>

                            <Heading h3>
                                {selectedCategory
                                    ? selectedCategory.name
                                    : intl.formatMessage({
                                          id: 'product categories',
                                      })}
                            </Heading>
                        </VStack>
                        <VStack>
                            {selectedCategory && (
                                <MenuItem
                                    name={`All ${selectedCategory.name} Categories`}
                                    onSelect={() => {
                                        navigate(
                                            `./categories/${selectedCategory.id}/page/1`
                                        );
                                        onClose();
                                    }}
                                />
                            )}
                            {items.map(item => (
                                <MenuItem
                                    key={item.id}
                                    name={item.name}
                                    onSelect={() => {
                                        if (item.children.length) {
                                            setCategoryId(item.id);
                                        } else {
                                            navigate(
                                                `./categories/${item.id}/page/1`
                                            );
                                            onClose();
                                        }
                                    }}
                                />
                            ))}
                        </VStack>
                    </VStack>
                </div>
            </SidePane>
        );
    }
);

const MenuItem = memo(
    ({ name, onSelect }: { name: string; onSelect: () => void }) => {
        return (
            <div className={styles.item}>
                <Button
                    key={name}
                    inline
                    text
                    fullWidth
                    onClick={() => onSelect()}
                >
                    <HStack expanded justify="spaceBetween" align="center">
                        <TextBlock
                            menuButton
                            regular
                            truncate
                            className={styles.category}
                        >
                            {name}
                        </TextBlock>

                        <ChevronRightIcon />
                    </HStack>
                </Button>
            </div>
        );
    }
);
