import { gql } from '@apollo/client';
import { userProductPriceFragment } from '../../pricing/queries/useProductPrices';

export const ProductItemFragment = gql`
    fragment ProductItemFragment on Product {
        id
        key
        sku
        name
        isNew: new
        onSale
        featuredAttribute
        manufacturer
        comment
        vehicleComment
        summary
        rebateCode @include(if: $isSalesRepresentative)
        groupId
        tileAttributes {
            name
            value
        }
        siblingAttributes {
            name
            value
        }
        specificationAttributes {
            name
            value
        }
        media {
            type
            url
            renditionUrl
        }
        additionalCharges {
            chargeIncl
        }
        preorder
        variants {
            id
            name
            sku
            variationAttributes {
                name
                value
            }
            key
            specificationAttributes {
                name
                value
            }
            additionalCharges {
                chargeIncl
            }
            rebateCode @include(if: $isSalesRepresentative)
            groupId
        }
    }
`;

const productListQuery = gql`
    query productListQuery(
        $store: ID!
        $language: Language!
        $categoryId: ID
        $pageSize: Int
        $page: Int
        $states: [ProductState!]
        $sortField: ProductSortField
        $sortDirection: SortDirection
        $filters: [ProductListFilter!]
        $minPrice: Float
        $maxPrice: Float
        $vehicleId: String
        $bicycleId: ID
        $search: String
        $preorderSeasonId: Int
        $includePreorderSeasonId: Int
        $isSalesRepresentative: Boolean!
        $includeExcludedCategories: Boolean
        $customerPriceCacheKey: ID
        $cartId: Int
        $locations: [String!]!
        $isLoggedIn: Boolean!
        $ids: [ID!]
        $bestAvailability: ProductBestAvailabilityFilter
        $attributeFilters: JSON
    ) {
        products(
            store: $store
            language: $language
            categoryId: $categoryId
            pageSize: $pageSize
            page: $page
            states: $states
            sortField: $sortField
            sortDirection: $sortDirection
            filters: $filters
            minPrice: $minPrice
            maxPrice: $maxPrice
            vehicleId: $vehicleId
            sparePartParentId: $bicycleId
            search: $search
            ids: $ids
            preorderSeasonId: $preorderSeasonId
            includePreorderSeasonId: $includePreorderSeasonId
            includeExcludedCategories: $includeExcludedCategories
            bestAvailability: $bestAvailability
            attributeFilters: $attributeFilters
        ) {
            items {
                ...ProductItemFragment
            }
            total
            prices(cartId: $cartId, cacheKey: $customerPriceCacheKey) {
                ...UserProductPriceFragment
            }
            stockQuantities(locations: $locations) {
                store
                sku
                location
                quantity
                nextAvailability
            }
            futureStockQuantities(locations: $locations) {
                store
                sku
                location
                quantities {
                    quantity
                    date
                }
            }
            productOrderRestrictions {
                store
                sku
                restrictionId
            }
        }
    }
    ${ProductItemFragment}
    ${userProductPriceFragment}
`;

export const productRootCategoriesQuery = gql`
    query productRootCategoriesQuery(
        $store: ID!
        $language: Language!
        $categoryId: ID
        $states: [ProductState!]
        $filters: [ProductListFilter!]
        $minPrice: Float
        $maxPrice: Float
        $vehicleId: String
        $bicycleId: ID
        $search: String
        $preorderSeasonId: Int
        $includePreorderSeasonId: Int
        $includeExcludedCategories: Boolean
        $bestAvailability: ProductBestAvailabilityFilter
    ) {
        rootCategories: products(
            store: $store
            language: $language
            categoryId: $categoryId
            states: $states
            filters: $filters
            minPrice: $minPrice
            maxPrice: $maxPrice
            vehicleId: $vehicleId
            sparePartParentId: $bicycleId
            search: $search
            preorderSeasonId: $preorderSeasonId
            includePreorderSeasonId: $includePreorderSeasonId
            includeExcludedCategories: $includeExcludedCategories
            bestAvailability: $bestAvailability
        ) {
            categories {
                category {
                    id
                    key
                }
                total
            }
        }
    }
`;

export const totalCountQuery = gql`
    query totalCount(
        $store: ID!
        $language: Language!
        $categoryId: ID
        $preorderSeasonId: Int
        $includePreorderSeasonId: Int
        $includeExcludedCategories: Boolean
    ) {
        products: products(
            store: $store
            language: $language
            categoryId: $categoryId
            preorderSeasonId: $preorderSeasonId
            includePreorderSeasonId: $includePreorderSeasonId
            includeExcludedCategories: $includeExcludedCategories
        ) {
            total
        }
    }
`;

export default productListQuery;
