import { memo, useContext, useMemo } from 'react';
import { clsx } from 'clsx';
import useStoreAndLanguageParams from '../../../core/hooks/useStoreAndLanguageParams';
import { FluidProgressIndicator } from '../../../core/components/ProgressIndicators';
import Section from '../../../content/components/PageComponents/Section';
import withErrorBoundary from '../../../core/components/errors/withErrorBoundary';
import PreorderModeContext from '../../../preorder/PreorderModeContext';
import useProductsList from '../../../product/queries/useProductsList';
import ProductShowcaseCarousel from './ProductShowcaseCarousel';
import styles from './ProductShowcaseSection.module.scss';

interface ProductShowcaseProps {
    productIds: string[];
    title: string;
    id?: string;
}

const ProductShowcaseSection = memo(
    ({ productIds, title, id }: ProductShowcaseProps) => {
        const { store, language } = useStoreAndLanguageParams();
        const { productsFilters } = useContext(PreorderModeContext);

        const { items, loading } = useProductsList({
            store,
            language,
            ids: productIds.filter(str => str),
            pageSize: productIds.length,
            ...productsFilters,
        });

        const orderedData = useMemo(() => {
            const products = Array.from(items);
            products.sort((a, b) => {
                return productIds.indexOf(a.id) - productIds.indexOf(b.id);
            });
            return products;
        }, [items, productIds]);

        return (
            <Section
                title={title}
                alignment="center"
                className={clsx(styles.products, {
                    [styles.emptyTitle]: !title,
                })}
                id={id}
            >
                {loading ? (
                    <div className={styles.progressIndicatorContainer}>
                        <FluidProgressIndicator />
                    </div>
                ) : (
                    <ProductShowcaseCarousel products={orderedData} />
                )}
            </Section>
        );
    }
);

export default memo(
    withErrorBoundary(ProductShowcaseSection, {
        errorComponent: ({ defaultErrorMessage, title }) => {
            return (
                <Section
                    title={title}
                    alignment="center"
                    className={clsx(styles.products, {
                        [styles.emptyTitle]: !title,
                    })}
                >
                    {defaultErrorMessage}
                </Section>
            );
        },
    })
);
