import { useMemo } from 'react';
import { isApolloError } from '@apollo/client';
import useCancelableQuery from '../../core/hooks/apollo/useCancelableQuery';
import type {
    VariantWithRelatedData,
    ProductWithRelatedData,
    ProductCustomerPrice,
    ProductItem,
} from '../types';
import usePriceQueryOptions from '../../pricing/hooks/usePriceQueryOptions';
import useUserBranches from '../../core/queries/useUserBranches';
import type { Language } from '../../core/types';
import type { StoreId } from '../../core/types/stores';
import { findBySku } from '../util/findBySku';
import type {
    FutureStockQuantities,
    ProductOrderRestriction,
    StockQuantity,
} from '../../availability/types';
import type { ProductBestAvailabilityFilter } from '../components/ProductList/context/ProductListOptionsContext';
import productListQuery from './productListQuery';
import type { FilterList, ProductListQueryResult, ProductState } from './types';

const defaultProductsQueryResult: ProductListQueryResult = {
    products: {
        items: [],
        total: 0,
        prices: [],
        stockQuantities: [],
        futureStockQuantities: [],
        productOrderRestrictions: null,
    },
    defaultValue: true,
};

const checkErrors = (error: Error) => {
    if (!isApolloError(error)) {
        throw error;
    }
    if (
        error.graphQLErrors.some(graphQLError => {
            const rootPath = graphQLError.path?.[1];
            return rootPath === 'items' || rootPath === 'total';
        })
    ) {
        throw error;
    }
};

interface UseProductsListParameters {
    store: StoreId;
    language: Language;
    pageSize?: number;
    page?: number;
    states?: ProductState[];
    categoryId?: string;
    sortField?: string;
    sortDirection?: string;
    filters?: FilterList[];
    minPrice?: number;
    maxPrice?: number;
    vehicleId?: string;
    bicycleId?: string;
    search?: string;
    preorderSeasonId?: number;
    includePreorderSeasonId?: number;
    rootCategoryId?: string;
    skip?: boolean;
    includeExcludedCategories?: boolean;
    isSalesRepresentative?: boolean;
    ids?: string[];
    bestAvailability?: ProductBestAvailabilityFilter;
}

const addRelatedData = (
    products: ProductItem[],
    {
        prices,
        stockQuantities,
        futureStockQuantities,
        productOrderRestrictions,
    }: {
        prices: ProductCustomerPrice[];
        stockQuantities: StockQuantity[];
        futureStockQuantities: FutureStockQuantities[];
        productOrderRestrictions: ProductOrderRestriction[] | null;
    }
) => {
    return products.map(item => {
        return {
            ...item,
            stock: item.sku && {
                singleStockQuantity: findBySku(stockQuantities, item.sku),
                productFutureStock: findBySku(futureStockQuantities, item.sku),
                productOrderRestriction:
                    productOrderRestrictions &&
                    findBySku(productOrderRestrictions, item.sku),
            },
            customerPrice: item.sku && findBySku(prices, item.sku),
            variants: item.variants.map(variant => {
                return {
                    ...variant,
                    stock: {
                        singleStockQuantity: findBySku(
                            stockQuantities,
                            variant.sku
                        ),
                        productFutureStock: findBySku(
                            futureStockQuantities,
                            variant.sku
                        ),
                        productOrderRestriction:
                            productOrderRestrictions &&
                            findBySku(productOrderRestrictions, variant.sku),
                    },
                    customerPrice: findBySku(prices, variant.sku),
                    parent: item,
                };
            }) as VariantWithRelatedData[],
        } as ProductWithRelatedData;
    });
};

const useProductsList = ({ skip, ...variables }: UseProductsListParameters) => {
    const {
        variables: { customerPriceCacheKey, isLoggedIn, cartId },
        loadingPriceQueryOptions,
    } = usePriceQueryOptions();
    const { branches } = useUserBranches();
    const { loading, error, data } = useCancelableQuery<ProductListQueryResult>(
        productListQuery,
        {
            variables: {
                ...variables,
                customerPriceCacheKey,
                isLoggedIn,
                cartId,
                locations: branches.map(branch => branch.id),
            },
            skip: skip || loadingPriceQueryOptions,
            fetchPolicy: 'cache-first',
            errorPolicy: 'all',
        },
        true
    );

    if (error) {
        checkErrors(error);
    }

    const {
        products: {
            items: apiItems,
            total: filteredTotal,
            prices,
            stockQuantities,
            futureStockQuantities,
            productOrderRestrictions,
        },
        defaultValue,
    } = data || defaultProductsQueryResult;

    const items = useMemo(() => {
        return addRelatedData(apiItems, {
            prices,
            stockQuantities,
            futureStockQuantities,
            productOrderRestrictions,
        });
    }, [
        apiItems,
        prices,
        stockQuantities,
        futureStockQuantities,
        productOrderRestrictions,
    ]);

    return {
        items,
        filteredTotal,
        defaultValue,
        loading: loading || skip || loadingPriceQueryOptions,
    };
};

export default useProductsList;
