import { memo } from 'react';
import { FormattedMessage } from 'react-intl';
import usePostPages from '../../queries/usePostPages';
import { FluidProgressIndicator } from '../../../core/components/ProgressIndicators';
import Section from '../../../content/components/PageComponents/Section';
import type { NewsFeedComponent } from '../../../content/types/customStoryblokComponents';
import Button from '../../../core/components/Button';
import CurrentStoreLink from '../../../core/components/router/CurrentStoreLink';
import withErrorBoundary from '../../../core/components/errors/withErrorBoundary';
import styles from './NewsFeedSection.module.scss';
import NewsFeedTile from './NewsFeedTile';

const NewsFeedSection = memo(
    ({ limit, title, hide_preview_text }: NewsFeedComponent) => {
        const { data, loading } = usePostPages({
            perPage: parseInt(limit, 10) || 6,
        });

        return (
            <Section title={title} alignment="center">
                <div className={styles.newsTileContainer}>
                    {loading || !data?.items ? (
                        <div className={styles.progressIndicatorContainer}>
                            <FluidProgressIndicator />
                        </div>
                    ) : (
                        data.items.map(item => (
                            <div
                                className={styles.tile}
                                key={item.slug}
                                id={item.id}
                            >
                                <NewsFeedTile
                                    date={item.first_published_at}
                                    hidePreviewText={hide_preview_text}
                                    content={item.content}
                                    slug={item.slug}
                                />
                            </div>
                        ))
                    )}
                </div>
                <div>
                    {data && data.total > 0 && (
                        <CurrentStoreLink to="news">
                            <Button noPadding textOnlySecondary>
                                <FormattedMessage id="view all news posts" />
                            </Button>
                        </CurrentStoreLink>
                    )}
                </div>
            </Section>
        );
    }
);

export default memo(
    withErrorBoundary(NewsFeedSection, {
        errorComponent: ({ defaultErrorMessage, title }) => (
            <Section title={title} alignment="center">
                {defaultErrorMessage}
            </Section>
        ),
    })
);
