import { memo } from 'react';
import { Text, Heading } from '@library';
import Button from '../../../core/components/Button';
import FeatureBadge from '../FeatureBadge';
import type { CardContent } from '../../types';
import StoryblokLink from '../../../content/components/PageComponents/Links/StoryblokLink';
import styles from './FeatureTile.module.scss';
import StoryblokImageSlideShow from './StoryblokImageSlideShow';

const FeatureTile = ({ content }: { content: CardContent }) => {
    return (
        <div className={styles.container}>
            <StoryblokLink
                className={styles.imageLink}
                link={content.link}
                showEmpty
            >
                <StoryblokImageSlideShow
                    loading="lazy"
                    className={styles.image}
                    srcSets={[
                        {
                            width: 1650,
                            height: 600,
                            screenWidth: '1800w',
                        },
                        {
                            width: 3300,
                            height: 1200,
                            screenWidth: '3600w',
                        },
                    ]}
                    onlyFocusCrop
                    images={content.images}
                    image={content.image}
                    images_timeout={content.images_timeout}
                />
            </StoryblokLink>
            <FeatureBadge
                badge_image={content.badge_image}
                badge_position={content.badge_position}
                badge_target={content.badge_target}
                size="small"
            />

            <div className={styles.infoContainer}>
                <StoryblokLink
                    className={styles.title}
                    link={content.link}
                    showEmpty
                >
                    <Heading h3 bold hyphenated>
                        {content.title}
                    </Heading>
                </StoryblokLink>
                <div className={styles.description}>
                    <Text tertiary medium>
                        {content.description}
                    </Text>
                </div>

                {content.button_label && (
                    <StoryblokLink className={styles.link} link={content.link}>
                        <Button secondary>{content.button_label}</Button>
                    </StoryblokLink>
                )}
            </div>
        </div>
    );
};

export default memo(FeatureTile);
