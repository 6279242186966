import { memo } from 'react';
import { clsx } from 'clsx';
import { Text, TextBlock, VStack } from '@library';
import useFooterData from '../../../../core/queries/useFooterData';
import StoryblokRender from '../../../../content/components/StoryblokRender';
import ComponentsMapper from '../../../../content/components/ComponentsMapper';
import RenderComponents from '../../../../content/components/RenderComponents';
import type { ComponentsHandler } from '../../../../content/types/types';
import styles from './StoryInfo.module.css';

const footerParagraphMapper = {
    ...ComponentsMapper,
    paragraph: ({ content }, customStyles) => {
        return (
            <div className={clsx(styles.paragraph, customStyles?.paragraph)}>
                <Text regular primary>
                    <RenderComponents components={content} />
                </Text>
            </div>
        );
    },
} as ComponentsHandler;

export default memo(() => {
    const { storyblokFooter, isStoryblokLoaded } = useFooterData();

    if (!isStoryblokLoaded) {
        return null;
    }

    const email = storyblokFooter?.email;
    const companyAddress = storyblokFooter?.company_address;
    const contactDetails = storyblokFooter?.contact_details;

    return (
        storyblokFooter && (
            <VStack gap="gap24">
                <div>
                    <StoryblokRender
                        componentMapper={footerParagraphMapper}
                        components={companyAddress}
                    />
                </div>
                <TextBlock primary bold>
                    <a href={`mailto:${email}`} className={styles.email}>
                        {email}
                    </a>
                </TextBlock>

                <StoryblokRender
                    componentMapper={footerParagraphMapper}
                    components={contactDetails}
                    styles={{
                        paragraph: styles.contactDetails,
                    }}
                />
            </VStack>
        )
    );
});
