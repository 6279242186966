import { memo } from 'react';
import { useLayoutContext } from '../../contexts';
import SidePane from './SidePane';
import type { ModalProp } from './Modal';
import Modal from './Modal';

const ResponsiveModal = ({
    children,
    header,
    fullScreenOnMobile,
    ...props
}: ModalProp & {
    /**
     * The full screen mode will be enabled on mobile view.
     */
    fullScreenOnMobile?: boolean;
}) => {
    const { isInMobileView } = useLayoutContext();

    return isInMobileView ? (
        <SidePane
            open={props.open}
            onOpenChange={props.onOpenChange}
            header={header}
            side="bottom"
            fullScreen={fullScreenOnMobile}
        >
            {children}
        </SidePane>
    ) : (
        <Modal header={header} {...props}>
            {children}
        </Modal>
    );
};

export default memo(ResponsiveModal);
