import { memo } from 'react';
import { DateTime } from 'luxon';

import { HStack, Text } from '@library';
import Button from '../../../../core/components/Button';
import CustomLink from '../../../../core/components/CustomLink';
import styles from './PostSubInfo.module.scss';

const PostSubInfo = ({
    date,
    categoryUUID,
    categoryName,
}: {
    date: string;
    categoryUUID: string | undefined;
    categoryName: string | undefined;
}) => {
    const dateFormatted = DateTime.fromISO(date).toFormat('dd.MM.yyyy');

    return (
        <HStack wrap gap="small" align="center">
            <div className={styles.subInfo}>
                <Text secondary medium>
                    {dateFormatted}
                </Text>
            </div>
            {categoryName && (
                <>
                    <div className={styles.subInfo}>
                        <Text primary regular>
                            |
                        </Text>
                    </div>
                    <div>
                        <CustomLink
                            to={{ search: `?categories=${categoryUUID}` }}
                        >
                            <Button textOnlySecondary noPadding autoSize>
                                {categoryName}
                            </Button>
                        </CustomLink>
                    </div>
                </>
            )}
        </HStack>
    );
};

export default memo(PostSubInfo);
