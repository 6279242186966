import {
    arrow,
    FloatingFocusManager,
    FloatingPortal,
    offset,
    shift,
    useClick,
    useDismiss,
    useFloating,
    useHover,
    useInteractions,
    useRole,
    flip,
    autoUpdate,
    safePolygon,
    size,
} from '@floating-ui/react';
import type { Placement, OffsetOptions } from '@floating-ui/react';
import { clsx } from 'clsx';
import type { ReactElement, ReactNode } from 'react';
import { useEffect, useState, cloneElement, memo, useRef } from 'react';
import notEmpty from '../../../core/util/notEmpty';
import useDropdownAnimation from '../inputs/Select/hooks/useDropdownAnimation';
import styles from './Tooltip.module.scss';
import TooltipArrowIcon from './TooltipArrowIcon';

export interface TooltipStateLessProps {
    /**
     * The content of tooltip
     */
    content: ReactNode;
    /**
     * The placement of tooltip.
     */
    placement?: Placement;
    /**
     * The fallback placements of tooltip.
     */
    fallbackPlacements?: Placement[];
    /**
     * Disable the tooltip.
     */
    disabled?: boolean;
    children: ReactElement;
    /**
     * Show the arrow
     */
    showArrow?: boolean;
    /**
     * The rounded version of tooltip
     */
    rounded?: boolean;
    /**
     * Show tooltip in modal with correct z-index
     */
    modal?: boolean;
    /**
     * Control how tooltip is open
     */
    mode?: 'hover' | 'click';
    /**
     * Control show state outside the component
     */
    open?: boolean;
    /**
     * Open state changed
     */
    onOpenChange?: (open: boolean) => void;
    /**
     * Close on outside click and esc button
     */
    dismiss?: boolean;
    /**
     * Provide specific css variables:
     * --tooltip-padding
     */
    theme?: string;
    /**
     * Tooltip would take that same width as reference
     */
    matchReferenceWidth?: boolean;
    /**
     * Tooltip would take that same width as prop
     */
    matchWidth?: number;
    /**
     * The offset of tooltip.
     */
    offsetOptions?: OffsetOptions;
}

/*
The tooltip with state outside
*/
export const TooltipStateLess = memo(
    ({
        content,
        children,
        placement,
        fallbackPlacements,
        modal,
        showArrow,
        disabled,
        open,
        mode = 'hover',
        onOpenChange,
        rounded,
        dismiss = true,
        theme,
        matchReferenceWidth,
        matchWidth,
        offsetOptions,
    }: TooltipStateLessProps) => {
        const arrowRef = useRef<HTMLDivElement | null>(null);

        const widthRef = useRef(matchWidth);

        const {
            x,
            y,
            context,
            placement: activePlacement,
            strategy,
            refs: { setReference, setFloating },
            middlewareData: { arrow: { x: arrowX, y: arrowY } = {} },
        } = useFloating({
            placement,
            open,
            onOpenChange: value => {
                if (!disabled) {
                    onOpenChange?.(value);
                }
            },
            middleware: [
                shift({
                    boundary: document.querySelector('#root') || undefined,
                    padding: 20,
                }),
                offset(offsetOptions || 16),
                flip({
                    fallbackPlacements: fallbackPlacements ?? ['bottom'],
                    padding: 20,
                }),
                showArrow ? arrow({ element: arrowRef }) : null,
                matchReferenceWidth
                    ? size({
                          apply({ rects, elements }) {
                              Object.assign(elements.floating.style, {
                                  width: `${rects.reference.width}px`,
                              });
                          },
                      })
                    : null,
                matchWidth
                    ? size({
                          apply({ elements }) {
                              Object.assign(elements.floating.style, {
                                  width: `${widthRef.current}px`,
                              });
                          },
                      })
                    : null,
            ].filter(notEmpty),
            whileElementsMounted: mode === 'click' ? autoUpdate : undefined,
        });

        const [arrowSide] = activePlacement.split('-');

        useEffect(() => {
            widthRef.current = matchWidth;
        }, [matchWidth]);

        const { getReferenceProps, getFloatingProps } = useInteractions([
            useHover(context, {
                restMs: 300,
                enabled: mode === 'hover',
                handleClose: safePolygon(),
            }),
            useClick(context, { enabled: mode === 'click', toggle: true }),
            useDismiss(context, {
                enabled: dismiss,
                escapeKey: true,
                referencePress: false,
                outsidePress: true,
            }),
            useRole(context, {
                role: 'tooltip',
            }),
        ]);

        const { isMounted, styles: animationStyles } =
            useDropdownAnimation(context);

        return (
            <>
                {cloneElement(children, {
                    ...getReferenceProps({
                        onClick: e => {
                            e.stopPropagation();
                        },
                    }),
                    ref: setReference,
                })}
                {isMounted && (
                    <FloatingPortal>
                        <FloatingFocusManager
                            context={context}
                            modal={false}
                            order={['reference', 'content']}
                            closeOnFocusOut={false}
                        >
                            <div
                                {...getFloatingProps()}
                                ref={setFloating}
                                className={clsx(styles.tooltip, theme, {
                                    [styles.modal]: modal,
                                    [styles.rounded]: rounded,
                                })}
                                style={{
                                    position: strategy,
                                    top: y,
                                    left: x,
                                    ...animationStyles,
                                }}
                            >
                                {content}
                                {showArrow && (
                                    <div
                                        ref={arrowRef}
                                        className={clsx(
                                            styles.arrow,
                                            styles[`${arrowSide}Arrow`]
                                        )}
                                        style={{
                                            left: notEmpty(arrowX)
                                                ? `${arrowX}px`
                                                : '',
                                            top: notEmpty(arrowY)
                                                ? `${arrowY}px`
                                                : '',
                                            right: '',
                                            bottom: '',
                                        }}
                                    >
                                        <TooltipArrowIcon />
                                    </div>
                                )}
                            </div>
                        </FloatingFocusManager>
                    </FloatingPortal>
                )}
            </>
        );
    }
);

/**
 * The Tooltip Component.
 */
const Tooltip = (
    props: Omit<TooltipStateLessProps, 'open' | 'onOpenChange'> & {
        /**
         * Auto closing tooltip after delay
         */
        autoCloseDelay?: number;
    }
) => {
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (open && props.autoCloseDelay && props.autoCloseDelay > 0) {
            const timeout = setTimeout(() => {
                setOpen(false);
            }, props.autoCloseDelay);
            return () => {
                clearTimeout(timeout);
            };
        }
    }, [open, props.autoCloseDelay]);
    if (props.disabled) {
        return props.children;
    }
    return (
        <TooltipStateLess
            open={open}
            onOpenChange={value => {
                setOpen(value);
            }}
            {...props}
        />
    );
};

export default memo(Tooltip);
