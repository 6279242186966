import type { ImgHTMLAttributes } from 'react';
import { useState, memo } from 'react';
import { clsx } from 'clsx';
import { Placeholder } from '@library';
import toStoryblokImageServiceLink from '../../util/toStoryblokImageServiceLink';
import type { Asset } from '../../types/base';
import styles from './StoryblokResponsiveImage.module.css';

const predefinedSets = {
    fullWidth: (predefinedDefaultHeight = 0) => {
        return {
            srcSet: [
                {
                    width: 500,
                    height: predefinedDefaultHeight,
                    screenWidth: '500w',
                },
                {
                    width: 1000,
                    height: predefinedDefaultHeight,
                    screenWidth: '1000w',
                },
                {
                    width: 1360,
                    height: predefinedDefaultHeight,
                    screenWidth: '1360w',
                },
                {
                    width: 1650,
                    height: predefinedDefaultHeight * 1.5,
                    screenWidth: '1650w',
                },
                {
                    width: 2000,
                    height: predefinedDefaultHeight * 2,
                    screenWidth: '2000w',
                },
                {
                    width: 2720,
                    height: predefinedDefaultHeight * 2,
                    screenWidth: '2720w',
                },
                {
                    width: 3300,
                    height: predefinedDefaultHeight * 2,
                    screenWidth: '3300w',
                },
            ],
            sizes: `(min-width: 1920px) 1650px,
                    100vw`,
        };
    },
};

interface StoryblokImageProps {
    predefinedSet?: 'fullWidth';
    srcSets?: { width: number; height: number; screenWidth: string }[];
    predefinedDefaultHeight?: number;
    image?: Pick<Asset, 'filename' | 'title' | 'alt' | 'focus'>;
    onlyFocusCrop?: boolean;
}

const StoryblokSourceForType = memo(
    ({
        type,
        predefinedSet,
        filename,
        focus,
        srcSets,
        predefinedDefaultHeight,
        onlyFocusCrop,
        sizes,
    }: Pick<
        StoryblokImageProps,
        | 'predefinedSet'
        | 'predefinedDefaultHeight'
        | 'onlyFocusCrop'
        | 'srcSets'
    > & {
        type: 'jpeg' | 'png' | 'webp';
        filename: string;
        focus: string | undefined;
        sizes: string | undefined;
    }) => {
        const predefinedSetProps = predefinedSet
            ? predefinedSets[predefinedSet](predefinedDefaultHeight)
            : null;

        const currentSrcSets = predefinedSetProps
            ? predefinedSetProps.srcSet
            : srcSets;
        const srcSetProp = currentSrcSets
            ?.map(({ width, height, screenWidth }) => {
                return `${toStoryblokImageServiceLink(filename, {
                    width,
                    height,
                    focus,
                    onlyFocusCrop,
                    format: type,
                })} ${screenWidth}`;
            })
            .join(', ');

        const currentSizes = predefinedSetProps
            ? predefinedSetProps.sizes
            : sizes;

        return (
            <source
                type={`image/${type}`}
                srcSet={srcSetProp}
                sizes={currentSizes}
            />
        );
    }
);

const ignoreImageServiceFormats = ['svg', 'gif'];
const isIgnoreImageService = (fileName: string) => {
    return ignoreImageServiceFormats.includes(fileName.split('.').pop() || '');
};

export type StoryblokResponsiveImageProps = Partial<StoryblokImageProps> &
    Omit<ImgHTMLAttributes<HTMLImageElement>, 'src'>;

const StoryblokResponsiveImage = ({
    predefinedSet,
    image,
    srcSets,
    predefinedDefaultHeight,
    onlyFocusCrop,
    sizes,
    className,
    ...props
}: StoryblokResponsiveImageProps) => {
    const [isLoading, setLoading] = useState(true);

    if (!image || !image.filename) {
        return null;
    }
    const onLoad = () => {
        setLoading(false);
    };

    const { filename, focus } = image;
    const isIgnore = isIgnoreImageService(filename);
    return (
        <div className={clsx(className, styles.container)}>
            <picture onLoad={onLoad}>
                {!isIgnore && (
                    <>
                        <StoryblokSourceForType
                            type="webp"
                            filename={filename}
                            focus={focus}
                            predefinedSet={predefinedSet}
                            srcSets={srcSets}
                            predefinedDefaultHeight={predefinedDefaultHeight}
                            onlyFocusCrop={onlyFocusCrop}
                            sizes={sizes}
                        />
                        <StoryblokSourceForType
                            type="jpeg"
                            filename={filename}
                            focus={focus}
                            predefinedSet={predefinedSet}
                            srcSets={srcSets}
                            predefinedDefaultHeight={predefinedDefaultHeight}
                            onlyFocusCrop={onlyFocusCrop}
                            sizes={sizes}
                        />
                    </>
                )}
                <img
                    className={styles.image}
                    {...props}
                    src={filename}
                    alt={image.alt}
                    title={image.title}
                />
            </picture>
            {isLoading && (
                <div className={styles.skeletonContainer}>
                    <Placeholder width="100%" height="100%" />
                </div>
            )}
        </div>
    );
};

export default memo(StoryblokResponsiveImage);
