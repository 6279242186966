import type { WatchQueryFetchPolicy } from '@apollo/client';
import { gql, useApolloClient, useLazyQuery } from '@apollo/client';
import useBatchedQuery from '../../core/hooks/apollo/useBatchedQuery';
import useStoreAndLanguageParams from '../../core/hooks/useStoreAndLanguageParams';
import assert from '../../core/util/assert';
import notEmpty from '../../core/util/notEmpty';
import type { ProductCustomerPrice } from '../../product/types';
import usePriceQueryOptions from '../hooks/usePriceQueryOptions';
import notFalsy from '../../core/util/notFalsy';

export const userProductPriceFragment = gql`
    fragment UserProductPriceFragment on UserProductPrice {
        store
        sku
        retailPrice
        cartId @include(if: $isLoggedIn)
        cacheKey @include(if: $isLoggedIn)
        cartType @include(if: $isLoggedIn)
        finalPrice @include(if: $isLoggedIn)
        generalDealerPrice @include(if: $isLoggedIn)
        additionalDiscountPercentage @include(if: $isLoggedIn)
        dealerDiscountPercentage @include(if: $isLoggedIn)
        appliedDiscountRule @include(if: $isLoggedIn)
    }
`;

const query = gql`
    query customerPricesQuery(
        $customerPriceCacheKey: ID
        $cartType: CartType
        $cartId: Int
        $store: ID!
        $skuList: [String!]!
        $isLoggedIn: Boolean!
    ) {
        customerPrices(
            cacheKey: $customerPriceCacheKey
            cartType: $cartType
            cartId: $cartId
            store: $store
            skuList: $skuList
        ) {
            ...UserProductPriceFragment
        }
    }
    ${userProductPriceFragment}
`;

const singlePriceQuery = gql`
    query customerPriceQuery(
        $customerPriceCacheKey: ID
        $cartType: CartType
        $cartId: Int
        $store: ID!
        $sku: String!
        $isLoggedIn: Boolean!
    ) {
        customerPrice(
            cacheKey: $customerPriceCacheKey
            cartType: $cartType
            cartId: $cartId
            store: $store
            sku: $sku
        ) {
            ...UserProductPriceFragment
        }
    }
    ${userProductPriceFragment}
`;

interface UseProductPricesOptions {
    skuList: string[];
    skip?: boolean;
}

export const useProductPrices = (opts: UseProductPricesOptions) => {
    const { store } = useStoreAndLanguageParams();
    const { variables, loadingPriceQueryOptions } = usePriceQueryOptions();
    assert(opts.skuList.every(notFalsy), 'Sku list contains empty values');

    const { data, error, loading } = useBatchedQuery<
        ProductCustomerPrice,
        {
            customerPrices: ProductCustomerPrice[];
        }
    >({
        items: opts.skuList,
        variables: {
            store,
            ...variables,
        },
        options: {
            skip: opts.skip || loadingPriceQueryOptions,
            fetchPolicy: 'cache-first' as WatchQueryFetchPolicy,
        },
        query: singlePriceQuery,
        batchQuery: query,
        itemParameterName: 'sku',
        itemListParameterName: 'skuList',
        batchToResult: batch => batch.customerPrices,
    });

    if (error) {
        throw error;
    }

    return {
        loading: loading || loadingPriceQueryOptions,
        customerPrices: data,
    };
};

export const useProductPricesLazy = () => {
    const { store } = useStoreAndLanguageParams();
    const { variables } = usePriceQueryOptions();
    const client = useApolloClient();

    const baseVariables = {
        store,
        ...variables,
    };

    const [load, { error }] = useLazyQuery<{
        customerPrices: ProductCustomerPrice[];
    }>(query, {
        variables: baseVariables,
    });

    if (error) {
        throw error;
    }

    const loadPrices = async (skuList: string[]) => {
        assert(skuList.every(notFalsy), 'Sku list contains empty values');

        const existedInCache = skuList
            .map(sku => {
                const result = client.readQuery<{
                    customerPrice: ProductCustomerPrice;
                }>({
                    query: singlePriceQuery,
                    variables: {
                        ...baseVariables,
                        sku,
                    },
                });

                return result?.customerPrice;
            })
            .filter(notEmpty);

        const notExisted = skuList.filter(
            sku => !existedInCache.find(price => price.sku === sku)
        );

        if (notExisted.length) {
            const { data } = await load({
                variables: {
                    ...baseVariables,
                    skuList: notExisted,
                },
            });
            assert(data, 'data is required.');
            return [...existedInCache, ...data.customerPrices];
        }

        return existedInCache;
    };

    return {
        loadPrices,
    };
};
