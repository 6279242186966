import { memo, forwardRef, cloneElement } from 'react';
import type {
    AriaAttributes,
    InputHTMLAttributes,
    ReactElement,
    ReactNode,
    Ref,
} from 'react';
import { clsx } from 'clsx';
import styles from './BaseInput.module.scss';

export interface BaseInputProps
    extends Omit<
        AriaAttributes &
            Pick<
                InputHTMLAttributes<HTMLInputElement>,
                | 'onClick'
                | 'onBlur'
                | 'onFocus'
                | 'onKeyDown'
                | 'value'
                | 'placeholder'
                | 'disabled'
                | 'required'
                | 'name'
                | 'id'
                | 'autoFocus'
                | 'autoComplete'
            >,
        'size'
    > {
    /**
     * The error state of the input.
     */
    error?: boolean;
    /**
     * The success state of the input.
     */
    success?: boolean;
    /**
     * The start adornment of the input.
     */
    startAdornment?: ReactNode;
    /**
     * The end adornment of the input.
     */
    endAdornment?: ReactNode;
    /**
     * Enables the small size of the input.
     */
    small?: boolean;
    /**
     * Enables the mini size of the input.
     */
    mini?: boolean;
    /**
     * The theme for the input. Use for providing a custom css variables.
     */
    theme?: string;
    /**
     * Rounded trigger for the input
     */
    rounded?: boolean;
    /**
     * Callback for onChange event handler.
     */
    onChange?: (value: string) => void;
    /**
     * The custom input element.
     */
    children?: ReactElement;
    /**
     * Type of the input. Can be used to create Email/Password input fields.
     */
    type?: 'email' | 'password';
}

export interface BaseInputReference {
    focus(): void;
    blur(): void;
}

const BaseInput = forwardRef(
    (
        {
            id,
            value,
            success = false,
            error = false,
            startAdornment,
            endAdornment,
            small,
            mini,
            theme,
            children,
            onChange,
            type,
            rounded,
            disabled,
            ...otherProps
        }: BaseInputProps,
        ref: Ref<BaseInputReference>
    ) => {
        const className = clsx(styles.input, {
            [styles.success]: success,
            [styles.error]: error,
            [styles.filled]: !!value,
            [styles.withStartAdornment]: startAdornment,
            [styles.withEndAdornment]: endAdornment,
            [styles.mini]: mini,
            [styles.rounded]: rounded,
            [styles.disabled]: disabled,
        });
        return (
            <div
                className={clsx(styles.container, theme, {
                    [styles.small]: small,
                    [styles.mini]: mini,
                    [styles.error]: error,
                })}
            >
                {startAdornment && (
                    <div className={clsx(styles.startAdornment)}>
                        {startAdornment}
                    </div>
                )}
                {!children ? (
                    <input
                        {...otherProps}
                        id={id}
                        ref={ref as Ref<HTMLInputElement>}
                        className={className}
                        value={value}
                        type={type}
                        disabled={disabled}
                        onChange={e => {
                            onChange?.(e.target.value);
                        }}
                    />
                ) : (
                    cloneElement(children, {
                        ...otherProps,
                        id,
                        ref,
                        className,
                        value,
                    })
                )}
                {endAdornment && (
                    <div className={clsx(styles.endAdornment)}>
                        {endAdornment}
                    </div>
                )}
            </div>
        );
    }
);

export default memo(BaseInput);
