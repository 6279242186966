import { memo, useRef, useState } from 'react';
import type { ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';
import { clsx } from 'clsx';
import { Text } from '@library';
import CurrentStoreLink from '../../core/components/router/CurrentStoreLink';
import useLogout from '../../core/hooks/useLogout';
import ChevronDownIcon from '../../core/components/icons/ChevronDownIcon';
import RestrictedContent from '../../core/components/router/RestrictedContent';
import ClickableArea from '../../core/components/button/ClickableArea';
import useStoreInformation from '../../content/queries/useStoreInformation';
import styles from './AccountDropdownMenu.module.scss';
import AccountMenuPreorderItems from './AccountMenuPreorderItems';

export const AccountMenuLinks: {
    labelId: string;
    to: string;
    medium?: boolean;
}[] = [
    { to: 'account', labelId: 'overview', medium: true },
    { to: 'account/user-data', labelId: 'your data' },
    ...[
        {
            to: 'account/back-orders',
            labelId: 'backorder',
        },
        {
            to: 'account/order-history',
            labelId: 'order history',
        },
        {
            to: 'account/track-trace',
            labelId: 'Track & Trace',
        },
    ],
];

const CompanyInfoLink = memo(({ hide }: { hide(): void }) => {
    const { data } = useStoreInformation();

    if (!data) {
        return <></>;
    }
    return (
        <CurrentStoreLink
            to={data.info_link}
            onClick={hide}
            className={styles.menuItem}
            data-test-class="accountMenuDropdownItem"
        >
            <Text primary>{data.info_label}</Text>
        </CurrentStoreLink>
    );
});

const AccountDropdownMenu = ({ children }: { children: ReactNode }) => {
    const [isOpen, setOpen] = useState(false);

    const menuRef = useRef<HTMLDivElement>(null);

    const logout = useLogout();
    const onLogoutPress = async () => {
        hide();
        logout();
    };

    const hide = () => setOpen(false);
    const show = () => setOpen(true);

    return (
        <div
            role="menu"
            className={styles.container}
            onMouseEnter={show}
            onMouseLeave={hide}
            data-test-id="account-dropdown-menu"
            ref={menuRef}
        >
            <div className={styles.link} data-test-id="account-dropdown-label">
                {children}

                <ChevronDownIcon
                    className={clsx(styles.icon, {
                        [styles.iconOpened]: isOpen,
                    })}
                />
            </div>
            <div
                className={clsx(styles.menu, {
                    [styles.menuOpened]: isOpen,
                })}
            >
                {AccountMenuLinks.map(link => (
                    <CurrentStoreLink
                        key={link.to}
                        to={link.to}
                        onClick={hide}
                        className={styles.menuItem}
                        data-test-class="accountMenuDropdownItem"
                    >
                        <Text primary>
                            <FormattedMessage id={link.labelId} />
                        </Text>
                    </CurrentStoreLink>
                ))}
                <AccountMenuPreorderItems onCloseMenu={hide} />

                <RestrictedContent featureFlag="rma">
                    <CurrentStoreLink
                        to={'service'}
                        onClick={hide}
                        className={styles.menuItem}
                        data-test-class="accountMenuDropdownItem"
                    >
                        <Text primary>
                            <FormattedMessage id="support" />
                        </Text>
                    </CurrentStoreLink>
                </RestrictedContent>
                <CompanyInfoLink hide={hide} />
                <ClickableArea
                    onClick={onLogoutPress}
                    className={styles.menuItem}
                    data-test-class="accountMenuDropdownItem"
                    data-test-id="account-dropdown-menu-logout"
                >
                    <Text primary>
                        <FormattedMessage id="sign out" />
                    </Text>
                </ClickableArea>
            </div>
        </div>
    );
};

export default memo(AccountDropdownMenu);
